import React from 'react';
import styled from 'styled-components';
import { media } from '@payright/web-components';
import AuLicenceDetails from './au-licence-details';
import NzLicenceDetails from './nz-licence-details';
import { useIsNz } from 'util/hooks';

export interface LicenceDetailsProps {
  existingCustomer: boolean;
  editing: boolean;
}

const SCLicenceDetails = styled.div`
  ${media.min.medium} {
    .state-expiry-wrapper {
      width: 100%;
      display: flex;
      margin-top: 1em;

      .input-field {
        flex: calc(50% - 0.75em);
        margin-right: 0.75em;
      }

      & > .dropdown {
        width: calc(50% - 0.75em);
        margin-right: 0.75em;

        .error {
          text-align: left;
        }
      }
    }
  }

  .issueDate {
    ${media.min.large} {
      max-width: 400px;
      width: 400px;
    }
  }

  .date-picker {
    flex: 1;
  }
`;

const LicenceDetails = ({ existingCustomer, editing }: LicenceDetailsProps) => {
  const isNz = useIsNz();

  let RegionLicenceDetails = (
    <AuLicenceDetails existingCustomer={existingCustomer} editing={editing} />
  );

  if (isNz) {
    RegionLicenceDetails = (
      <NzLicenceDetails existingCustomer={existingCustomer} editing={editing} />
    );
  }

  return <SCLicenceDetails id="LicenceSection">{RegionLicenceDetails}</SCLicenceDetails>;
};

export default LicenceDetails;
