import moment from 'moment';
import React from 'react';

export const REGION_STATES = [
  { name: 'Australian Capital Territory', value: 'ACT' },
  { name: 'New South Wales', value: 'NSW' },
  { name: 'Northern Territory', value: 'NT' },
  { name: 'Queensland', value: 'QLD' },
  { name: 'South Australia', value: 'SA' },
  { name: 'Tasmania', value: 'TAS' },
  { name: 'Victoria', value: 'VIC' },
  { name: 'Western Australia', value: 'WA' },
];

export const RESIDENTIAL_STATUS = [
  { name: 'Boarding', value: 'Boarding' },
  { name: 'Employer Provided', value: 'Employer Provided' },
  { name: 'Living with Children/Other Family', value: 'Living with Children' },
  { name: 'Living with Parents(Rent Free)', value: 'Living with Family' },
  { name: 'Own with Mortgage', value: 'Own with Mortgage' },
  { name: 'Own without Mortgage', value: 'Own without Mortgage' },
  { name: 'Renting', value: 'Renting' },
  { name: 'Other', value: 'Other' },
];

export const CUSTOMER_TITLES = [
  { name: 'Mr.', value: 'Mr.' },
  { name: 'Ms.', value: 'Ms.' },
  { name: 'Mrs.', value: 'Mrs.' },
  { name: 'Miss', value: 'Miss' },
];

export const TIMEFRAMES = [
  { name: 'This month', value: 30 },
  { name: 'Last month', value: 60 },
  { name: '3 months', value: 90 },
  { name: '6 months', value: 180 },
];

export const TIME_IN_JOB = [
  { name: 'Less than 6 months', value: 'Less than 6 mnths' },
  { name: '6 - 12 months', value: '6 12 mnths' },
  { name: '1 - 2 years', value: '1 2 years' },
  { name: '2 - 3 years', value: '2 3 years' },
  { name: '3 - 4 years', value: '3 4 years' },
  { name: '4 - 5 years', value: '4 5 years' },
  { name: 'Greater than 5 years', value: 'Greater than 5 years' },
];

export const EMPLOYMENT_TYPES = [
  { name: 'Full Time Employment', value: 'Full time' },
  { name: 'Part Time Employment', value: 'Part Time' },
  { name: 'Casual Employment', value: 'Casual' },
  { name: 'Self Employed', value: 'Self Employed' },
  { name: 'Self Funded Retiree', value: 'Self Funded Retiree' },
  { name: 'Age Pension', value: 'Aged Pension' },
  { name: 'Carers Payment', value: 'Carers Pension' },
  { name: 'Disability Support Pension', value: 'Disability Pension' },
  { name: 'Student', value: 'Student' },
  { name: 'Other', value: 'Other' },
  { name: 'Unemployed', value: 'Unemployed' },
];

export const INCOME_FREQUENCIES = [
  { name: 'Weekly', value: 'weekly' },
  { name: 'Fortnightly', value: 'fortnightly' },
  { name: 'Monthly', value: 'monthly' },
];

export const PAYMENT_FREQUENCIES = [
  { name: 'Fortnightly', value: 'Fortnightly' },
  { name: 'Monthly', value: 'Monthly' },
];

export const PAYMENT_PERIODS = [
  { name: '1 month', value: 1 },
  { name: '3 months', value: 3 },
  { name: '6 months', value: 6 },
  { name: '12 months', value: 12 },
];

export const DEPOSIT_PERCENTAGES = [
  { name: '10%', value: 10 },
  { name: '15%', value: 15 },
  { name: '20%', value: 20 },
  { name: '25%', value: 25 },
  { name: '30%', value: 30 },
];

export const IDENTIFICATION_MAX_YEARS_EXPIRY_FROM_TODAY = 10;

export const TIME_IN_ADDRESS = [
  { name: 'Less than 6 months', value: 'Less than 6 mnths' },
  { name: '6 - 12 months', value: '6 12 mnths' },
  { name: '1 - 2 years', value: '1 2 years' },
  { name: '2 - 3 years', value: '2 3 years' },
  { name: '3 - 4 years', value: '3 4 years' },
  { name: '4 - 5 years', value: '4 5 years' },
  { name: 'Greater than 5 years', value: 'Greater than 5 years' },
];

export const MONTHLY_PAYMENT_DATE_SELECTION = [
  { value: `${moment().format('YYYY-MM-')}01`, name: '1st' },
  { value: `${moment().format('YYYY-MM-')}02`, name: '2nd' },
  { value: `${moment().format('YYYY-MM-')}03`, name: '3rd' },
  { value: `${moment().format('YYYY-MM-')}04`, name: '4th' },
  { value: `${moment().format('YYYY-MM-')}05`, name: '5th' },
  { value: `${moment().format('YYYY-MM-')}06`, name: '6th' },
  { value: `${moment().format('YYYY-MM-')}07`, name: '7th' },
  { value: `${moment().format('YYYY-MM-')}08`, name: '8th' },
  { value: `${moment().format('YYYY-MM-')}09`, name: '9th' },
  { value: `${moment().format('YYYY-MM-')}10`, name: '10th' },
  { value: `${moment().format('YYYY-MM-')}11`, name: '11th' },
  { value: `${moment().format('YYYY-MM-')}12`, name: '12th' },
  { value: `${moment().format('YYYY-MM-')}13`, name: '13th' },
  { value: `${moment().format('YYYY-MM-')}14`, name: '14th' },
  { value: `${moment().format('YYYY-MM-')}15`, name: '15th' },
  { value: `${moment().format('YYYY-MM-')}16`, name: '16th' },
  { value: `${moment().format('YYYY-MM-')}17`, name: '17th' },
  { value: `${moment().format('YYYY-MM-')}18`, name: '18th' },
  { value: `${moment().format('YYYY-MM-')}19`, name: '19th' },
  { value: `${moment().format('YYYY-MM-')}20`, name: '20th' },
  { value: `${moment().format('YYYY-MM-')}21`, name: '21st' },
  { value: `${moment().format('YYYY-MM-')}22`, name: '22nd' },
  { value: `${moment().format('YYYY-MM-')}23`, name: '23rd' },
  { value: `${moment().format('YYYY-MM-')}24`, name: '24th' },
  { value: `${moment().format('YYYY-MM-')}25`, name: '25th' },
  { value: `${moment().format('YYYY-MM-')}26`, name: '26th' },
  { value: `${moment().format('YYYY-MM-')}27`, name: '27th' },
  { value: `${moment().format('YYYY-MM-')}28`, name: '28th' },
];

export const DOB_YEARS = 100;
export const BUSINESS_NUMBER = 'ABN';
export const LABEL_STATE = 'State / Territory';
export const LABEL_SUBURB = 'Suburb / Town';
export const CUSTOMER_HUB_URL = 'https://customerhub.payright.com.au';
export const FAQS_URL = 'https://payright.com.au/faqs';
export const REDIRECT_URL = 'https://payright.com.au';

// Address Details
export const ADDRESS_DETAILS = 'addressDetails';
export const SHIPPING_DETAILS_TITLE = 'Shipping Details';
// Contact
export const CONTACT_US_PHONE_NUMBER = '1300 338 496';
export const HELPDESK_EMAIL = 'admin@payright.com.au';
export const APPLICATIONS_EMAIL = 'applications@payright.com.au';
export const CARE_EMAIL = 'care@payright.com.au';
export const TELL_YOUR_CUSTOMER_LINK = 'https://payright.com.au';

// Verification
export const DEFAULT_MARKETING_CHECKBOX = true;

// Validation
export const PHONE_VALIDATION_REGEX = /^\(?(?:\+?61|0)4\)?(?:[ -]?\d){7}[0-9]$|^[01][1-35-9](?:[ -]?\d){7}[0-9]$|^[2-9](?:[ -]?\d){6}[0-9]$|^13(?:[ -]?\d){4}$|^1300(?:[ -]?\d){6}$|^180[1-9](?:[ -]?\d){4}$|^1800(?:[ -]?\d){6}$/;
export const MOBILE_VALIDATION_REGEX = /^\(?(?:\+?61|0)4\)?(?:[ -]?[0-9]){7}[0-9]$/;
export const LICENCE_VALIDATION_REGEX = /^[0-9a-zA-Z]{4,9}$/;
export const LICENCE_CARD_NUMBER_VALIDATION_REGEX = /^[0-9a-zA-Z]{0,10}$/;

// Phone no validation text
export const MOBILE_NUMBER_FORMAT_VALIDATION = 'Please use format 0412 345 678';
export const PHONE_NUMBER_FORMAT_VALIDATION = 'Please enter a valid phone number';

// Messages
export const MARKETING_CHECKBOX_TEXT =
  'Please untick if the customer does NOT wish to receive promotional emails and other electronic communications from Payright';
export const CUSTOMER_DETAILS_CUSTOMER_INSTRUCTIONS = (
  <>
    Please ensure your <u>name & date of birth</u> are entered exactly as they appear on the ID
    you're supplying as part of your application. Any errors such as typos or missing middle name(s)
    may cause a delay in processing your application.
  </>
);
export const SUCCESSFULL_NEW_PLAN_APPROVED_TITLE = 'You’re almost there!';
export const SUCCESSFULL_NEW_PLAN_APPROVED_BODY =
  'Set up payment details below to finalise the payment plan';
export const SUCCESSFULL_PRE_APPROVED_PLAN_TITLE = 'Good News! The plan has been pre-approved';
export const SUCCESSFULL_PRE_APPROVED_PLAN_BODY =
  'Return here at any time to finalise your plan setup and payment details';
export const SUCCESSFULL_PRE_APPROVED_PAYMENT_PLAN_TITLE =
  'Good News! The plan is ready for payment';
export const SUCCESSFULL_PRE_APPROVED_PAYMENT_PLAN_BODY = 'Please finalise the payment plan now';

// CANCEL POPUP
export const CANCEL_POPUP_TITLE = 'Cancel the Application';
export const CANCEL_POPUP_TEXT = 'Are you sure you want to cancel this application ?';
export const CANCEL_POPUP_CANCEL_PLAN = 'Yes, I want to';
export const CANCEL_POPUP_ABORT_CANCEL = "No. I don't want to";
export const ADDRESS_DETAIL_TEXT_NEW_CUSTOMER = 'Please enter your current residential address.';
export const ADDRESS_DETAIL_TEXT_EXISTING_CUSTOMER =
  'Please check the information populated below is correct.';
export const CUSTOMER_IDENTIFICATION = (
  <>
    <p>
      The ID supplied must be a current Australian Passport or an Australian Driver's Licence. The
      ID must not be expired.
    </p>
    <p>
      Recently-issued Australian Driver's Licences now have an additional 'Card Number' for security
      purposes.
    </p>
    <p>
      The 'Card Number' on your Driver's Licence must be entered as well as your Licence Number.
    </p>
    <p>
      Unsure?{' '}
      <a href="/images/au-driver-licence-user-guide.png" target="_blank">
        Click here for images.
      </a>
    </p>
  </>
);

// Living Expenses category
export const LIVING_EXPENSES_CATEGORIES = [
  { value: 'groceriesMonthly', name: 'Groceries', hint: '' },
  {
    value: 'utility',
    name: 'Utilities & Insurance',
    hint:
      'Include all electricity, gas, water, phone and internet charges as well as any ongoing insurance costs.',
  },
  {
    value: 'accommodation',
    name: 'Accommodation',
    hint: 'Include rent, board, rates, body corporate and other government and council services.',
  },
  { value: 'transportMonthlyCost', name: 'Automotive & Transport', hint: '' },
  {
    value: 'personalExpense',
    name: 'Personal Expenses',
    hint:
      'Include clothing, personal care, medical costs and retail costs for you and any dependants.',
  },
  {
    value: 'leisureEntertainmentMonthly',
    name: 'Leisure & Entertainment',
    hint:
      'Include dining out, entertainment, travel and any subscriptions such as TV and gyms (Dining Out, Entertainment, Gambling, Travel, Subscription TV, Online Retail and Subscription Services, Gyms and other memberships).',
  },
  {
    value: 'education',
    name: 'Education',
    hint: 'Include school fees, childcare and other educational costs.',
  },
  {
    value: 'otherExpense',
    name: 'Other',
    hint:
      'Include any other expenses such as child support, pet care, tax etc. that you are either unable or unwilling to stop after entering into a loan agreement with Payright.',
  },
];

export const ALL_EXPENSES_CONFIRMATION_TEXT =
  'I confirm that all my bank accounts have been provided and the monthly income, living and financial expenses outlined above are reflective of my actual financial commitments.';
export const TOTAL_MONTHLY_LOAN_REPAYMENTS_TEXT =
  "According to your bank transactions the total monthly loan repayments for these financial expenses is shown below. If that's not the case, simply adjust to what it should be.";

//Error Message
export const ONE_MONTHLY_INCOME_MUST_SELECTED =
  'Please ensure at least one income source is selected';
export const INCOME_MUST_BE_GREATER_THAN_ZERO =
  'Please ensure the part of this income that is yours, is greater than 0%.';
export const MONTHLY_EXPENSE_MAX_VALIDATION = 'Only amounts under $100,000 are accepted.';
export const MONTHLY_EXPENSE_TOTAL_VALIDATION =
  'We are expecting an overall Living Expense amount greater than $0. Please review and make adjustments.';
export const MONTHLY_TOTAL_REPAYMENT_VALIDATION =
  'We are expecting a Financial Expense (excluding Credit cards) repayment amount greater than $0. Please review and make adjustments.';

export const DEFAULT_INCOME_PERCENTAGE = 100;

export const EXPENSE_MUST_BE_NOT_NULL = 'Amounts have to be $0 or above';
export const OTHER_INCOME_LABEL = 'Other Income';
export const DECIMAL_NUMBERS_VALIDATION = 'Please ensure a number is entered in this field';

export const HEADER = {
  CUSTOMER_DETAILS: {
    PAGE_NAME: '/payment-plan/customer-details',
    TITLE: 'Set up payment plan',
    SUBTITLE: 'Complete your details below',
  },
  SUMMARY: {
    PAGE_NAME: '/payment-plan/summary',
    TITLE: 'Review payment plan',
    SUBTITLE_STANDARD: 'Confirm the plan details below',
    SUBTITLE_PRE_APPROVAL: 'See suggested deposit, repayment amount and frequency',
  },
  INCOME_AND_EXPENSES: {
    PAGE_NAME: '/payment-plan/income-and-expenses',
    TITLE: 'Your Income and Expenses',
    SUBTITLE: '',
  },
  PAYMENT: {
    PAGE_NAME: '/payment-plan/payment',
    TITLE_STANDARD: 'Complete payment plan',
    SUBTITLE_STANDARD: 'Enter your payment details below',
    TITLE_PRE_APPROVAL: 'Pre-Approval Complete',
    SUBTITLE_PRE_APPROVAL: 'Finalise your payment details or save for later',
  },
};

export const LESS_THAN_6_MONTHS = 'Less than 6 mnths';

export const HEADER_MESSAGE_REVIEW = 'Your application is being reviewed.';
export const REVIEW_MESSAGE = (
  <>
    <p>
      We're reviewing your application we may need you to confirm some details. If required, you'll
      receive an SMS with next steps.
    </p>
    <p>
      This process usually only takes a few minutes but may take up to 24 hours. There is no need to
      call us, rather we'll be in touch with you via SMS (or email if your application is declined).
    </p>
  </>
);

export const PRODUCT_LINE = 'BNPL';

export const NONEDITABLE_FIELD_MESSAGE = "This field can't be edited";
