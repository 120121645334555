import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import LandingPage from './pages/landing';
import LoginPage from './pages/login';
import LoginApplicantPage from './pages/login/applicant';
import ForgotPassword from './pages/login/forgot-password';
import ChangePassword from './pages/login/change-password';
import Step1 from './pages/step-1';
import Step2 from './pages/step-2';
import Step2_1 from 'pages/step-2_1';
import Step3 from './pages/step-3';
import CloudPayments from './pages/step-3/cloud-payments';
import PlanResult from './pages/plan-result';
import Logout from './pages/logout';
import MaintenancePage from 'pages/maintenance';

const App: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={LandingPage}></Route>
      <Route path="/login" exact component={LoginPage} />
      <Route path="/login-applicant" exact component={LoginApplicantPage} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/change-password" component={ChangePassword} />
      <Route path="/payment-plan" exact>
        <Redirect to="/payment-plan/customer-details" />
      </Route>
      <Route path="/payment-plan/customer-details" component={Step1} />
      <Route path="/payment-plan/summary" component={Step2} />
      <Route path="/payment-plan/income-and-expenses" exact component={Step2_1} />
      <Route path="/payment-plan/payment" component={Step3} />
      <Route path="/payment-plan/plan-result" render={props => <PlanResult {...props} />} />
      <Route path="/logout" exact component={Logout} />
      <Route path="/cloud_payments/:checkoutId" exact component={CloudPayments} />
      <Route path="/maintenance" component={MaintenancePage} />
      <Route path="*">Page not found</Route>
    </Switch>
  );
};

export default App;
