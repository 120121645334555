import axios, { AxiosInstance } from 'axios';
import { PaymentCalculatorData } from '@payright/web-components';

import {
  CustomerResource,
  GetCustomerResource,
  CustomerDetailsData,
  Employed,
  Others,
  AddressDetailsData,
  ShippingDetailsData,
} from '../types/customer';

import { ApplicationCompletedBy } from '../types/plan';

import { NextStep, CheckoutType, CheckoutStep } from '../types/checkout';
import { PRODUCT_LINE } from './constants/defaults';

export type LoginSuccessResponse = {
  auth_token: string;
  customer_id: string;
};

export type FetchCustomerSuccessResponse = {
  auth_token: string;
  customer_id: string;
};

export type LoggedInStaffPermissionSuccessResponse = {
  permissions: Array<any>;
  id: string;
};

// VERIFICATION CODE RESPONSE TYPE
export type VerifyPlanResponse = {
  planId: string;
  status: boolean;
};

// BSO Status
type UpdateBsoOptionsData = {
  status: string;
  nextSteps: Array<NextStep>;
};

export enum PlanStatus {
  DECLINED = 'Declined',
  APPROVED = 'Approved',
  APPROVED_PENDING_ID = 'Approved_Pending_ID',
  APPROVED_STATEMENT_REQUESTED = 'Approved_Statement_Requested',
  REVIEW = 'Review',
  PENDING = 'Pending',
  ACTIVE = 'Active',
  CANCELLED = 'Cancelled',
  UNDEFINED = '',
}

export enum PlanApprovalCheckOutcome {
  APPROVED = 'Approved',
  REVIEW = 'Review',
  DECLINED = 'Declined',
  CANCELLED = 'Cancelled',
}

export enum PlanApprovalCheckMessageCode {
  BSO_VALIDITY_REVIEW = 'bso_not_valid',
}

export type PlanApprovalCheckResponse = {
  outcome: PlanApprovalCheckOutcome;
  messageCode: PlanApprovalCheckMessageCode;
};

export type PlanAction =
  | 'activate'
  | 'resend-details'
  | 'deposit-taker'
  | 'zero-deposit'
  | 'application-completer'
  | 'send-link-to-customer';

// PLAN APPROVAL RESPONSE TYPE
export type ApprovalResponse = {
  approvalStatus: PlanStatus;
};

export type CancelledResponse = {
  cancelledStatus: PlanStatus;
};

export type CreateCheckoutParams = {
  attributes: {
    saleAmount: number;
    terms: number | string;
    depositPaid?: number | string | null;
    depositPaidPercentage: number | string;
    paymentPeriod: number | string;
    minimumDepositPercentage?: number | null;
    type: string;
    redirectUrl: string;
    expiresAt?: string;
  };
  merchantReference?: string;
};

type CheckOutAttributes = {
  depositPaidPercentage: number;
  depositPayable: number;
  paymentPeriod: number;
  saleAmount: number;
  terms: string;
  paymentDetails: PaymentCalculatorData;
  type: CheckoutType;
  redirectUrl: string;
  expiresAt?: string;
  step?: CheckoutStep;
  platform?: string | null;
  showAllAvailableTerms?: boolean;
  planPreApprovalDetails?: PlanPreApprovalDetailsData;
};

export type PlanPreApprovalDetailsData = {
  status: string;
  nextSteps: Array<NextStep>;
  preApprovalOutCome: number;
  approvedDepositAmount: 0;
  preApprovalTermOutcome: string;
  approvedDepositPercentage: number;
};

export enum CheckoutStatus {
  APPROVED = 'approved',
  PENDING = 'pending',
  ACTIVE = 'active',
  REVIEW = 'review',
  DECLINED = 'declined',
  COMPLETE = 'complete',
  CANCELLED = 'cancelled',
  APPROVED_PRE_APPROVAL_CARD_DETAILS_TAKEN = 'approved_pre_approval_card_details_taken',
  UNDEFINED = '',
}

export type CheckOutRequest = {
  attributes: {
    saleAmount: number;
    terms: number | string;
    depositPaid?: number | string | null;
    depositPaidPercentage: number | string;
    paymentPeriod: number | string;
    minimumDepositPercentage?: number | null;
  };
  merchantReference?: string;
};

export type CheckoutSuccessResponse = {
  id?: string;
  merchantReference?: string;
  status?: CheckoutStatus;
  createdAt?: string;
  expiresAt?: string | null;
  link?: string;
  customerId?: string | null;
  customerNumber?: string | null;
  planId?: string | null;
  planNumber?: string | null;
  nextSteps?: Array<NextStep>;
  attributes?: CheckOutAttributes;
  /**
   * Todo:
   * Change approvalStatus to planStatus?
   * May require a bit of thinking/investigation on the best way
   * to represent the 2 statuses in the FE
   */
  approvalStatus?: PlanStatus;
  applicationCompletedBy?: string;
  preApprovalDetails?: PreApprovalStatus;
  redirectUrl?: string;
  redirectEndpoint?: string;
  contactId?: string;
};

export type CheckoutItems = Array<{
  originalPrice: number | '-';
  discountedPrice: number | '-';
  productDescription: string;
  productImage: string;
  reference: string;
  productTitle: string;
  orderReceiptNumber: string;
  orderDate: string;
  quantity: Number;
}>;

type PreApprovalStatus = {
  status: string;
};

// TODO: Merge CheckoutSuccessResponse with CheckoutResource. They are essentially the same thing
export type CheckoutResource = {
  id: string;
  merchantReference: string;
  applicationCompletedBy: string;
  status?: CheckoutStatus;
  attributes: CheckOutAttributes;
  nextSteps?: Array<NextStep>;
  planId: string | null;
  planNumber?: string | null;
  customerId: string | null;
  customerNumber?: string | null;
  createdAt: string; // Fix this
  expiresAt: string | null;
  link?: string;
  approvalStatus?: PlanStatus;
};

//export type CheckoutSuccessResponse = CheckoutResource;
export type CheckoutUpdateSuccessResponse = CheckoutResource;

// CURRENT CHECKOUT RESPONSE TYPE
export type CurrentCheckoutResponse = CheckoutResource;

export type CustomerAttachSuccessResponse = {
  id: string;
  name: string;
  contactId: string;
  customerId: string;
  customerName: string;
  saleAmount: string;
  status: string;
  depositPaymentStatus: string;
  dateCreated: string;
  merchantReference: string;
};

export type RatesSuccessResponse = {
  rates: Array<{
    minimumSaleAmount: number;
    minimumDepositPercentage: number;
    term: number;
    minimumPurchase: number;
    maximumPurchase: number;
    rate00: number | null;
    rate05: number | null;
    rate10: number | null;
    rate15: number | null;
    rate20: number | null;
    rate25: number | null;
    rate30: number | null;
    rate35: number | null;
    rate40: number | null;
    rate45: number | null;
    rate50: number | null;
  }>;
  establishmentFees: Array<{
    initialEstFee: number;
    repeatEstFee: number;
    term: number;
  }>;
  otherFees: {
    monthlyAccountKeepingFee: number;
    paymentProcessingFee: number;
  };
};

export type StoreSuccessResponse = {
  id: string;
  name: string;
  merchantId: string;
  merchantName: string;
  email: string | null;
  defaultTerm: number;
  depositTaker: boolean;
  fundingSource: string;
  applicationCompleter: boolean;
  storeNumber: string;
  storeStatus: string;
  capacityOffsetOn: boolean;
  customerLedOnly: boolean;
  bsoOffsetValue: number;
  storeDescription: string;
  paymentsProcessedBySugar?: boolean;
  merchantIndustry: string;
  showMobileCheckout: boolean;
};

export type PreApprovalData = {
  status: string;
  approvedDepositAmount: number;
  approvedDepositPercentage: number;
  nextSteps: Array<NextStep>;
};

export type AcceptDepositBumpingSuccessResponse = {
  nextSteps: Array<NextStep>;
  depositPaid: number;
  depositPaidPercentage: number;
};

export type PlansSuccessResponse = Array<{
  id: string;
  name: string;
  customerName: string;
  status: string;
  dateCreated: Date;
  merchantReference: string;
  staffName: string;
  depositPaymentStatus: string;
  saleAmount: string;
  depositPaid: string;
  amountSettledToDate: string;
  totalCreditAmount: string;
  msf: string;
  depositPaidPercentage: string;
  terms: string;
  contactId: string;
  finalSettlementDate: string;
  planBlockActivation: boolean;
  merchantSettled: string;
  creditAmount: string;
}>;

export type ExportPlanSuccessResponse = {
  exportCSVURL: string;
};

export type PlansDetailSuccessResponse = {
  depositPaymentStatus: string;
  numberOfRepayments: string;
  establishmentFees: string;
  repaymentFrequency: string;
  loanAmountPerPayment: string;
  repaymentAmount: string;
  depositPaid: string;
  totalCreditAmount: string;
  terms: string;
  saleAmount: string;
  customerId: string;
  customerNumber?: string | null;
  name?: string;
  status: PlanStatus;
  planBlockActivation: boolean;
};

export type CustomerDetailSuccessResponse = {
  salutation: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneMobile: string;
  customerNumber: string;
};

export type StaffSuccessResponse = Array<{
  id: string;
  fullName: string;
  phoneMobile: string;
  phoneWork: string;
  email: string;
  contactStatus: string;
}>;

export type StaffCreationSuccessResponse = {
  salutation?: string;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  phoneMobile?: string;
  phoneWork?: string;
  email?: string;
  userName?: string;
  password?: string;
  id?: string;
};

export type StaffDetailSuccessResponse = {
  salutation: string;
  firstName: string;
  lastName?: string;
  jobTitle?: string;
  phoneMobile?: string;
  phoneWork?: string;
  userName?: string;
  email?: string;
  permissions: Array<any>;
  contactStatus: string;
};

export type StaffUpdateSuccessResponse = {
  salutation?: string;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  phoneMobile?: string;
  phoneWork?: string;
  email?: string;
  permissions?: Array<any>;
  id?: string;
  contactStatus?: string;
};

export type ProfileUpdateSuccessResponse = {
  salutation?: string;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  phoneMobile?: string;
  phoneWork?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
};

export type ProfileDetailSuccessResponse = {
  salutation?: string;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  phoneMobile?: string;
  phoneWork?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
};

export type CustomerCreationSuccessResponse = {
  id: string | null;
  name?: string;
  salutation?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  email?: string;
  phoneMobile?: string;
  primaryAddressStreet?: string;
  primaryAddressStreet2?: string;
  primaryAddressStreet3?: string;
  primaryAddressCity?: string;
  primaryAddressState?: string;
  primaryAddressPostalCode?: string;
  primaryAddressCountry?: string;
  driverLicenceNumber?: string;
  medicareNumber?: string;
  medicareRowNumber?: string;
  passportNumber?: string;
  birthCertificateNumber?: string;
  passwordStatus?: string;
  fundingSource?: string;
};

export type GetCustomerResponse = {
  id: string;
  name: string;
  fullName: string;
  salutation: string;
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  phoneMobile: string;
  dateOfBirth: string; // 1991-03-04
  residentialStatus: string;
  employerName: string;
  employmentType: Employed['employmentType'] | Others['employmentType'];
  timeInJob: string;
  primaryAddressStreet: string;
  primaryAddressStreet2: string;
  primaryAddressStreet3: string;
  primaryAddressCity: string;
  primaryAddressState: string;
  primaryAddressPostalCode: string;
  primaryAddressCountry: string;
  driverLicenceNumber: string;
  driverLicenceState: string;
  driverLicenceExpiry: string;
  passportNumber: string;
  passportExpiry: string;
  otherId: string;
  otherIdType: string;
  username: string;
  fundingSource: string;
  allowFurtherBusiness: string;
  repaymentSuccessCount: number;
  approvedLimit: number;
  yearsAtCurrentAddress: string;
  employerPhone: string;
  tradingName: string;
  tradingPhone: string;
  abn: string;
  incomeAmount: number;
  incomeFrequency: string;
  otherIncomeAmount: number;
  otherIncomeFrequency: string;
  availableCredit: number;
  bsoStatus: string;
  bsoLastUpdated: string;
  isBsoRequired: boolean;
  paymentProcessingFee: number;
  paymentStartDate: string;
  customerNumber: string;
};

export type CustomerSearchResponse = {
  customerPartial: { id: string; phoneMobile: string } | null;
  errors: Array<{ code: number; message: string }>;
  isExistingCustomer: boolean;
  canCustomerProceed: boolean;
};

export type CustomerSearchParams = Required<
  Pick<CustomerDetailsData, 'firstName' | 'lastName' | 'middleName' | 'dateOfBirth'>
> &
  Partial<Pick<CustomerDetailsData, 'email' | 'phone'>>;

export type PaymentMethodsResponse = Array<{
  id: string;
  name: string;
  paymentMethodType: string;
  cardProvider: string;
  cardNumber: string;
  fundingSource: string;
  cardExpiry: string;
}>;

export type MakePaymentResponse = {
  status: string;
  message: string;
  responseCode: string;
  transactionId: number;
};

type MakePaymentData = {
  customerId: string;
  paymentType: string;
  planId: string;
};

export type AddCardData = {
  card_holder: string;
  customer_id: string;
  card_type: string;
  payment_provider: string;
  number: string;
  card_expiry: string;
  cvn: string;
  customer_number: string;
  payment_collected_by_sugar: boolean;
  customer_first_name: string;
  customer_last_name: string;
};

export type UpdateCustomerData = {
  checkoutId: string;
  paymentDetails: {
    paymentStartDate: string;
  };
};

export type PaymentGatewayDetails = {
  serviceProvider: 'Eway' | 'Cloud_Payments';
  apiUrl: string;
  paymentCurrency: string;
  iframeUrl: string;
};

export type CloudCheckRequest = {
  payment_provider: string;
  checkoutId: string;
  token: string | null;
  card_holder: string | null;
  country?: string | null;
  cardNumber: string | null;
  card_expiry: string | null;
  card_type: string | null;
};

export type CustomerLivingExpeseIncomeData = {
  id: string;
  originalTotalMonthlyIncome: number;
  appliedLivingExpensePercentage: number;
  appliedFinancialExpensePercentage: number;
  groceriesMonthly: number;
  utility: number;
  accommodation: number;
  transportMonthlyCost: number;
  personalExpense: number;
  leisureEntertainmentMonthly: number;
  education: number;
  otherExpense: number;
  otherIncome?: number;
  noIfJointAccount: number;
  wages?: Array<{ key: string; value: string }>;
  creditCardRepayment: number;
  totalCreditLimit: number;
};

export type CustomerCreditAccountsData = Array<{
  id: string;
  creditor: string;
  name: string;
  productType: string;
}>;

export type CustomerIncomeExpenseData = {
  approval_data?: CustomerLivingExpeseIncomeData;
  credit_accounts?: CustomerCreditAccountsData;
};

export type CustomerDeclaredIncomeExpenseData = {
  declaredTotalMonthlyLivingExpenses: number;
  declaredTotalMonthlyFinancialExpenses: number;
  declaredTotalMonthlyIncome: number;
  /** Optional: only set this value if the customer has joint accounts */
  appliedLivingExpensePercentage?: number;
  /** Optional: only set this value if the customer has joint accounts */
  appliedFinancialExpensePercentage?: number;
};

class ByronBayApi {
  axiosInstance: AxiosInstance;

  constructor() {
    const url = process.env.REACT_APP_BYRON_BAY_URL || process.env.REACT_APP_ELIXIR_URL;
    this.axiosInstance = axios.create({
      baseURL: url,
      timeout: 80000,
      headers: {
        common: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    });

    this.axiosInstance.interceptors.response.use(response => {
      return response.data;
    });
  }

  signUp = async (
    password: string,
    customerDetailsData: CustomerDetailsData,
    addressDetailsData: AddressDetailsData,
    shippingDetailsData: ShippingDetailsData
  ): Promise<FetchCustomerSuccessResponse> => {
    try {
      // Exact path TBC
      const url = `/api/v1/customer/signup`;
      const response = await this.axiosInstance.post<FetchCustomerSuccessResponse>(url, {
        password,
        customerDetailsData,
        addressDetailsData,
        shippingDetailsData,
      });
      if (response.data.auth_token && response.data.customer_id) {
        return {
          auth_token: response.data.auth_token,
          customer_id: response.data.customer_id,
        };
      } else {
        throw new Error('Unexpected server response. Please try again.');
      }
    } catch (error) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  login = async (username: string, password: string): Promise<FetchCustomerSuccessResponse> => {
    try {
      const url = `/api/v1/customer/login`;
      const response = await this.axiosInstance.post<FetchCustomerSuccessResponse>(
        url,
        {},
        {
          auth: {
            username,
            password,
          },
        }
      );
      if (response.data.auth_token && response.data.customer_id) {
        return {
          auth_token: response.data.auth_token,
          customer_id: response.data.customer_id,
        };
      } else {
        throw new Error('Unexpected server response. Please try again.');
      }
    } catch (error) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  loginApplicant = async (username: string, password: string): Promise<any> => {
    try {
      const url = `/api/v1/customer/applicant`;
      const response = await this.axiosInstance.post<any>(
        url,
        { username, password },
        {
          auth: {
            username,
            password,
          },
        }
      );
      if (response.data.auth_token && response.data.entity_id) {
        return {
          auth_token: response.data.auth_token,
          customer_id: response.data.entity_id,
        };
      } else {
        throw new Error('Unexpected server response. Please try again.');
      }
    } catch (error) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  forgotPassword = async (userName: string): Promise<any> => {
    try {
      const url = `/api/v1/customer/forgot-password`;
      const response = await this.axiosInstance.post<any>(url, {
        userName,
      });
      if (response.data.requestId) {
        return {
          message: 'Password has been reset.',
        };
      } else {
        throw new Error('Unexpected server response. Please try again.');
      }
    } catch (error) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  changePassword = async (
    authToken: string,
    customerId: string,
    tempPassword: string,
    newPassword: string,
    confirmPassword: string
  ): Promise<any> => {
    try {
      const url = `/api/v1/customer/change-password`;
      const response = await this.axiosInstance.put<any>(
        url,
        {
          customerId,
          tempPassword,
          newPassword,
          confirmPassword,
        },
        {
          headers: {
            'auth-token': authToken,
          },
        }
      );
      if (response.data) {
        return {
          message: 'Password has been changed.',
        };
      } else {
        throw new Error('Unexpected server response. Please try again.');
      }
    } catch (error) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  rates = async (checkoutId: string): Promise<RatesSuccessResponse> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/rates`;
      const response = await this.axiosInstance.get<RatesSuccessResponse>(url);
      if (response.data) {
        return {
          ...response.data,
        };
      } else {
        throw new Error('Unable to fetch the rates.');
      }
    } catch (error) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  store = async (checkoutId: string): Promise<StoreSuccessResponse> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/store`;
      const response = await this.axiosInstance.get<StoreSuccessResponse>(url);
      if (response.data) {
        return {
          ...response.data,
        };
      } else {
        throw new Error('Unable to fetch store.');
      }
    } catch (error) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  customerSearch = async (
    checkoutId: string,
    customerDetails: Required<
      Pick<CustomerDetailsData, 'firstName' | 'lastName' | 'middleName' | 'dateOfBirth'>
    > &
      Partial<Pick<CustomerDetailsData, 'email' | 'phone'>>,
    loanAmount: number
  ): Promise<CustomerSearchResponse> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/customer`;
      const response = await this.axiosInstance.get<CustomerSearchResponse>(url, {
        params: {
          firstName: customerDetails.firstName,
          lastName: customerDetails.lastName,
          middleName: customerDetails.middleName,
          dateOfBirth: customerDetails.dateOfBirth,
          email: customerDetails.email,
          phoneMobile: customerDetails.phone,
          productLine: PRODUCT_LINE,
          loanAmount,
        },
      });
      return response.data;
    } catch (error) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  getCurrentCheckout = async (checkoutId: string): Promise<any> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}`;
      const response = await this.axiosInstance.get(url);

      let data = { ...response.data };

      // In Update checkout, payment details does not have the depositPercentage value at step 1 for the return customer
      // We are storing that depositPercenatge into the DepositPaidPercentage for the merchant-api
      // For the temporary fix we are reverting the below object and sending it to merchant-api
      // We need to fix this mismatching variable
      data.attributes.paymentDetails = {
        saleAmount: data.attributes.saleAmount,
        depositAmount: data.attributes.depositPayable,
        depositPercent: data.attributes.depositPaidPercentage,
        paymentFrequency: data.attributes.terms,
        paymentPeriod: data.attributes.paymentPeriod,
        establishmentFee: data.attributes.paymentDetails.establishmentFee,
        showAllAvailableTerms: data.attributes.showAllAvailableTerms,
      };

      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getCurrentCheckoutItems = async (checkoutId: string): Promise<any> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/items`;
      const response = await this.axiosInstance.get(url);

      let data = { ...response.data };

      // Return as converted JSON object to Array
      return Object.keys(data).map(key => data[key]);
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getCustomerById = async (
    checkoutId: string,
    customerId: string
  ): Promise<GetCustomerResource> => {
    const url = `/api/v1/checkouts/${checkoutId}/customer/${customerId}`;
    try {
      const response = await this.axiosInstance.get<GetCustomerResource>(url);
      return response.data;
    } catch (error) {
      if (error.isAxiosError) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  };

  createCheckout = async (checkoutParams: CreateCheckoutParams): Promise<CheckoutResource> => {
    try {
      const url = `/api/v1/checkouts`;
      const response = await this.axiosInstance.post<CheckoutResource>(url, checkoutParams);
      if (response.data) {
        return response.data;
      } else {
        throw new Error('Unable to create new sale.');
      }
    } catch (error) {
      throw error;
    }
  };

  updateCheckout = async (
    checkoutId: string,
    checkoutParams: CreateCheckoutParams
  ): Promise<CheckoutUpdateSuccessResponse> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}`;
      const response = await this.axiosInstance.put<CheckoutUpdateSuccessResponse>(
        url,
        checkoutParams
      );

      if (response.data) {
        return response.data;
      } else {
        throw new Error('Unable to update the checkout.');
      }
    } catch (error) {
      throw error;
    }
  };

  customerCreation = async (checkoutId: string, customerDetailsDataSet: any): Promise<any> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/customer`;
      const response = await this.axiosInstance.post(url, customerDetailsDataSet);
      if (response.data.id) {
        return {
          id: response.data.id,
          fundingSource: response.data.fundingSource,
        };
      } else {
        throw new Error('Unable to create customer record  .');
      }
    } catch (error) {
      throw error;
    }
  };

  updateCustomer = async (
    checkoutId: string,
    customerId: string,
    // ToDo: Add type
    customerResource: CustomerResource
  ): Promise<CustomerResource> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/customer/${customerId}`;
      const response = await this.axiosInstance.put(url, customerResource);
      if (response.data) {
        return response.data;
      } else {
        throw new Error('Unable to update customer');
      }
    } catch (error) {
      throw error;
    }
  };

  customerToCheckout = async (
    customerId: string,
    checkoutId: string,
    applicationCompletedBy: ApplicationCompletedBy
  ): Promise<CheckoutResource> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/customer/${customerId} `;
      const applicationProcessedBy = {
        applicationCompletedBy: applicationCompletedBy,
      };
      const response = await this.axiosInstance.post<CheckoutResource>(url, applicationProcessedBy);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(
          `${error.response?.status} - ${error.response.statusText}: ${error.response.data.error.message}`
        );
      }

      throw new Error(error.message);
    }
  };

  getCheckoutCustomer = async (checkoutId: string): Promise<CustomerResource> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/customer`;
      const response = await this.axiosInstance.get<CustomerResource>(url);
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  runPlanPreApprovalCheck = async (
    checkoutId: string,
    isCheckoutUpdated: boolean,
    isExistingCustomer: boolean
  ): Promise<PreApprovalData> => {
    try {
      const url = `api/v1/checkouts/${checkoutId}/pre-approval-check?isCheckoutUpdated=${isCheckoutUpdated}&isExistingCustomer=${isExistingCustomer}`;
      const response = await this.axiosInstance.get(url);
      return response.data;
    } catch (error) {
      if (error.isAxiosError) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  };

  runAcceptPreApprovalCheck = async (
    checkoutId: string,
    depositAmount: number,
    depositPercentage: number
  ): Promise<AcceptDepositBumpingSuccessResponse> => {
    try {
      const url = `api/v1/checkouts/${checkoutId}/accept-pre-approval`;
      const response = await this.axiosInstance.patch(url, {
        depositAmount,
        depositPercentage,
      });
      return response.data;
    } catch (error) {
      if (error.isAxiosError) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  };

  updatedPlans = async (
    action: PlanAction,
    checkoutId: string,
    planId: string
  ): Promise<PlansSuccessResponse> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/plans/${planId}`;
      const response = await this.axiosInstance.put<PlansSuccessResponse>(url, {
        action,
      });
      if (response.data) {
        return {
          ...response.data,
        };
      } else {
        throw new Error('Unable to activate the plans');
      }
    } catch (error) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  verifyPlanSubmission = async (
    planId: string,
    checkoutId: string,
    verificationCode: string
  ): Promise<VerifyPlanResponse> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/plans/${planId}/verify/${verificationCode}`;
      const response = await this.axiosInstance.get(url);
      return response.data;
    } catch (error) {
      if (error.isAxiosError) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  };

  planApproval = async (checkoutId: string): Promise<ApprovalResponse> => {
    try {
      const url = `api/v1/checkouts/${checkoutId}/plan/approve`;
      const response = await this.axiosInstance.post(url);
      return {
        approvalStatus: response.data.planStatus,
      };
    } catch (error) {
      if (error.isAxiosError) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  };

  planApprovalCheck = async (checkoutId: string): Promise<PlanApprovalCheckResponse> => {
    try {
      const url = `api/v1/checkouts/${checkoutId}/plan/approve/check`;
      const response = await this.axiosInstance.get(url);
      return {
        outcome: response.data.outcome,
        messageCode: response.data.messageCode,
      };
    } catch (error) {
      if (error.isAxiosError) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  };

  getPlanStatus = async (checkoutId: string, planId: string): Promise<PlanStatus> => {
    try {
      const url = `api/v1/checkouts/${checkoutId}/plans/${planId}`;
      const response = await this.axiosInstance.get(url);
      return response.data.status;
    } catch (error) {
      if (error.isAxiosError) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  };

  planCancellation = async (checkoutId: string): Promise<CancelledResponse> => {
    try {
      const url = `api/v1/checkouts/${checkoutId}/cancel`;
      const response = await this.axiosInstance.put(url);

      return {
        cancelledStatus: response.data.status,
      };
    } catch (error) {
      if (error.isAxiosError) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  };

  getPaymentMethodsList = async (
    checkoutId: string,
    customerId: string
  ): Promise<PaymentMethodsResponse> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/customer/${customerId}/payment-methods`;
      const response = await this.axiosInstance.get<PaymentMethodsResponse>(url);

      return response.data;
    } catch (error) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  getPaymentGatewayDetails = async (
    customerId: string,
    checkoutId: string
  ): Promise<PaymentGatewayDetails> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/customer/${customerId}/payment-gateway/${checkoutId}`;
      const response = await this.axiosInstance.get<PaymentGatewayDetails>(url);
      return response.data;
    } catch (error) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  addCardCloudPayments = async (
    checkoutId: string,
    customerId: string,
    cloudCheckRequest: CloudCheckRequest
  ): Promise<any> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/customer/${customerId}/payment-methods/Cloud_Payment`;
      const response = await this.axiosInstance.post(url, cloudCheckRequest);
      if (response.data) {
        return response.data;
      } else {
        throw new Error('Unable to add card');
      }
    } catch (error) {
      if (error.isAxiosError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  addCard = async (
    checkoutId: string,
    customerId: string,
    paymentMethodData: AddCardData
  ): Promise<PaymentMethodsResponse> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/customer/${customerId}/payment-methods/Eway`;

      const response = await this.axiosInstance.post(url, paymentMethodData);
      if (response.data) {
        return response.data;
      } else {
        throw new Error('Unable to add card');
      }
    } catch (error) {
      if (error.isAxiosError) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  };

  updateCustomerPaymentDetails = async (
    checkoutId: string,
    customerId: string,
    updateCustomerDataSet: UpdateCustomerData
  ): Promise<CustomerDetailSuccessResponse> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/customer/${customerId}`;
      const response = await this.axiosInstance.put(url, updateCustomerDataSet);
      if (response.data) {
        return response.data;
      } else {
        throw new Error('Unable to update customer');
      }
    } catch (error) {
      throw error;
    }
  };

  makeCardActive = async (
    checkoutId: string,
    customerId: string,
    paymentMethodId: string
  ): Promise<PaymentMethodsResponse> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/payment-methods/${paymentMethodId}`;
      const paymentMethodData = {
        customerId: customerId,
      };

      const response = await this.axiosInstance.put(url, paymentMethodData);
      if (response.data) {
        return response.data;
      } else {
        throw new Error('Unable to update card');
      }
    } catch (error) {
      throw error;
    }
  };

  makePayment = async (
    checkoutId: string,
    paymentDataSet: MakePaymentData
  ): Promise<MakePaymentResponse> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/payment`;

      const response = await this.axiosInstance.post(url, paymentDataSet);
      if (response.data.status) {
        return response.data;
      } else {
        throw new Error('Unable to make payment');
      }
    } catch (error) {
      throw error;
    }
  };

  updateBsoOptions = async (
    checkoutId: string,
    provideBso: string
  ): Promise<UpdateBsoOptionsData> => {
    try {
      const url = `api/v1/checkouts/${checkoutId}/bso-options`;
      const response = await this.axiosInstance.patch(url, {
        provideBso,
      });

      return response.data;
    } catch (error) {
      if (error.isAxiosError) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  };

  sendVerificationCode = async (checkoutId: string): Promise<void> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/send-verification-code`;
      await this.axiosInstance.post(url);
    } catch (error) {
      if (error.response) {
        throw new Error(
          `${error.response?.status} - ${error.response.statusText}: ${error.response.data.error.message}`
        );
      }

      throw new Error(error.message);
    }
  };

  getCustomerIncomeExpense = async (
    checkoutId: string,
    customerId: string
  ): Promise<CustomerIncomeExpenseData> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/customer/${customerId}/monthly-income-expenses`;
      const response = await this.axiosInstance.get(url);

      return response.data;
    } catch (error) {
      if (error.isAxiosError) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  };

  updateCustomerIncomeExpenses = async (
    checkoutId: string,
    customerId: string,
    customerDeclaredIncomeExpense: CustomerDeclaredIncomeExpenseData
  ): Promise<CustomerDeclaredIncomeExpenseData> => {
    try {
      const url = `/api/v1/checkouts/${checkoutId}/customer/${customerId}/monthly-income-expenses`;
      const response = await this.axiosInstance.put(url, customerDeclaredIncomeExpense);
      return response.data;
    } catch (error) {
      if (error.isAxiosError) {
        throw new Error(error.response.data.message);
      }
      throw error;
    }
  };
}

export default ByronBayApi;
