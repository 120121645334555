import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Region } from '../types/region';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppDispatch } from 'util/store';
import { RootState } from '../reducers';

import { fetchCheckoutAttributes } from 'reducers/checkout-slice';
import { fetchMerchantData } from 'reducers/auth-slice';
import { fetchExistingData } from 'reducers/customer-slice';
import { fetchCustomerPaymentMethods } from 'reducers/payment-methods-slice';
import { useConfig } from 'providers/config-provider';

// A custom hook that builds on useLocation to parse
// the query string for you.
// See: https://reactrouter.com/web/example/query-parameters
export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useRegion = (): Region => {
  return process.env.REACT_APP_REGION
    ? (process.env.REACT_APP_REGION.toLowerCase() as Region)
    : 'au';
};

export const useIsNz = (): boolean => {
  return useRegion() === 'nz';
};

export const useIsAu = (): boolean => {
  return useRegion() === 'au';
};

export const useIsCustomerLed = (): boolean => {
  const CheckoutState = useSelector((state: RootState) => state.checkout);
  return CheckoutState.checkout?.applicationCompletedBy === 'Customer';
};

export const useIsEcommerce = (): boolean => {
  const CheckoutState = useSelector((state: RootState) => state.checkout);
  return CheckoutState.checkout?.applicationCompletedBy === 'Ecommerce';
};

/**
 * Pre-loads existing data if it doesn't belong in the state i.e. a customer refreshes the page
 *
 * @param checkoutId
 * @param customerId
 */
export const useFetchStateDataIfNull = (checkoutId: string | null, customerId: string | null) => {
  const dispatch: AppDispatch = useDispatch();

  const globalAuthState = useSelector((state: RootState) => state.auth);
  const globalCheckoutState = useSelector((state: RootState) => state.checkout);
  const globalCustomerState = useSelector((state: RootState) => state.customer);
  const globalPaymentMethodsState = useSelector((state: RootState) => state.paymentMethods);

  useEffect(() => {
    if (checkoutId !== null && globalCheckoutState.checkout === null) {
      // Load checkout details
      dispatch(fetchCheckoutAttributes(checkoutId));
    }
  }, [checkoutId, customerId, globalCheckoutState.checkout, dispatch]);

  useEffect(() => {
    if (checkoutId !== null && globalAuthState.storeConfig === null) {
      // Load merchant rates and store config
      dispatch(fetchMerchantData(checkoutId));
    }
  }, [checkoutId, customerId, globalAuthState.storeConfig, dispatch]);

  useEffect(() => {
    if (checkoutId !== null && customerId !== null && globalCustomerState.customerId === null) {
      // Load customer details
      dispatch(fetchExistingData(checkoutId, customerId));
    }
  }, [checkoutId, customerId, globalCustomerState.customerId, dispatch]);

  useEffect(() => {
    if (
      checkoutId !== null &&
      customerId !== null &&
      globalPaymentMethodsState.paymentMethods.length === 0
    ) {
      // Load customer's payment details
      dispatch(fetchCustomerPaymentMethods(checkoutId, customerId));
    }
  }, [checkoutId, customerId, globalPaymentMethodsState.paymentMethods.length, dispatch]);
};

/**
 * Returns true if any slice of the Redux state is loading
 */
export const useIsLoading = () => {
  const loadingStates: Array<boolean> = useSelector((state: RootState) => {
    return [
      state.auth.loading,
      state.customer.loading,
      state.checkout.loading,
      state.paymentMethods.loading,
      state.payment.loading,
    ];
  });

  return loadingStates.includes(true);
};

/**
 * Redirects the user to the landing page if checkout is not valid
 */
export const useRedirectIfNotValid = () => {
  const query = useQuery();
  const history = useHistory();
  const queryParamCheckoutId = query.get('checkoutId');

  // REDIRECT TO LANDING PAGE IF NO CHECKOUT ID IN URL (DOES NOT CHECK IF VALID ID)
  if (!queryParamCheckoutId) {
    history.push('/');
  }

  const checkout = useSelector((state: RootState) => state.checkout.checkout);

  // REDIRECT TO LANDING PAGE IF CHECKOUT EXPIRED
  useEffect(() => {
    if (checkout && checkout.attributes && checkout.attributes.expiresAt !== undefined) {
      if (checkout.attributes?.expiresAt < new Date().toISOString()) {
        history.push('/');
      }
    }
  }, [history, checkout]);
};

/**
 * Redirects the user to the landing page if the user is not logged in
 */
export const useRedirectIfNotLoggedIn = () => {
  const history = useHistory();
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

  useEffect(() => {
    if (!isLoggedIn) {
      history.push('/');
    }
  }, [history, isLoggedIn]);
};

/**
 * Hook for getting previous useState value
 * ref: https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/
 */
export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useQueryCheckoutId = () => {
  const query = useQuery();
  return query.get('checkoutId');
};

export const useTotalSteps = () => {
  const config = useConfig();

  if (config.cccfaEnabled) {
    return 4;
  } else {
    return 3;
  }
};
