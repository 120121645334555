import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'util/store';
import ByronBayApi, {
  MakePaymentResponse,
  AddCardData,
  PaymentMethodsResponse,
  CloudCheckRequest,
  PaymentGatewayDetails,
  PlanAction,
} from '../util/byronbay-api';

type InitialState = {
  loading: boolean;
  hasErrors: boolean;
  errorMessage: string;
  addCardStatus: boolean;
  addCardError: boolean;
  submitSucceeded: boolean;
  siftDecision?: string;
  payment: {
    status: string;
    transaction_id: string;
  };
  paymentGateway: PaymentGatewayDetails | null;
};

const initialState: InitialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  addCardStatus: false,
  addCardError: false,
  submitSucceeded: false,
  siftDecision: '',
  payment: {
    status: '',
    transaction_id: '',
  },
  paymentGateway: null,
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    resetPayment: () => initialState,
    clearErrors: state => {
      return {
        ...state,
        hasErrors: false,
        addCardError: false,
        errorMessage: '',
      };
    },
    startMakePayment: (state, { payload }) => {
      return {
        ...state,
        loading: true,
        hasErrors: false,
      };
    },
    makePaymentSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        hasErrors: false,
        submitSucceeded: true,
        payment: payload,
      };
    },
    makePaymentFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        loading: false,
        hasErrors: true,
        submitSucceeded: false,
        errorMessage: action.payload.errorMessage,
      };
    },
    addCardBegin: state => {
      return {
        ...state,
        loading: true,
        hasErrors: false,
        addCardError: false,
        errorMessage: '',
      };
    },
    addCardSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        paymentMethods: payload,
        addCardStatus: true,
        siftDecision: payload.siftDecision,
      };
    },
    addCardFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        loading: false,
        hasErrors: true,
        addCardError: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    updatePlanStatusBegin: state => {
      return {
        ...state,
        loading: true,
        hasErrors: false,
      };
    },
    updatePlanStatusSuccess: state => {
      return {
        ...state,
        loading: false,
        hasErrors: false,
        submitSucceeded: true,
      };
    },
    updatePlansFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        hasErrors: true,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    },
    getPaymentGatewayDetailsBegin: state => {
      return {
        ...state,
        loading: true,
      };
    },
    getPaymentGatewayDetailsSuccess: (
      state,
      action: PayloadAction<{ paymentGateway: PaymentGatewayDetails }>
    ) => {
      return {
        ...state,
        loading: false,
        paymentGateway: action.payload.paymentGateway,
      };
    },
    getPaymentGatewayDetailsFailed: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    addCloudCardBegin: state => {
      return {
        ...state,
        loading: true,
        hasErrors: false,
        errorMessage: '',
        addCardError: false,
      };
    },
    addCloudCardSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        hasErrors: false,
        paymentMethods: payload,
        addCardStatus: true,
        addCardError: false,
        siftDecision: payload.siftDecision,
      };
    },
    addCloudCardFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        loading: false,
        hasErrors: true,
        submitSucceeded: false,
        errorMessage: action.payload.errorMessage,
        addCardError: true,
      };
    },
  },
});

export const {
  resetPayment,
  clearErrors,
  startMakePayment,
  makePaymentSuccess,
  makePaymentFailure,
  addCardBegin,
  addCardSuccess,
  addCardFailure,
  addCloudCardBegin,
  addCloudCardSuccess,
  addCloudCardFailure,
  getPaymentGatewayDetailsBegin,
  getPaymentGatewayDetailsSuccess,
  getPaymentGatewayDetailsFailed,
  updatePlanStatusBegin,
  updatePlanStatusSuccess,
  updatePlansFailure,
} = paymentSlice.actions;

export default paymentSlice.reducer;

// ----------------------------------------------
// - Thunks - HANDLING THE FETCH PAYMENT METHODS
// ----------------------------------------------
export const getPaymentGatewayDetails = (
  customerId: string,
  checkoutId: string
): AppThunk => async dispatch => {
  try {
    dispatch(getPaymentGatewayDetailsBegin());
    const byronBayApi = new ByronBayApi();
    const paymentGatewayDetails = await byronBayApi.getPaymentGatewayDetails(
      customerId,
      checkoutId
    );
    dispatch(getPaymentGatewayDetailsSuccess({ paymentGateway: paymentGatewayDetails }));
  } catch (error) {
    dispatch(getPaymentGatewayDetailsFailed(error.message));
  }
};

export const doPayment = (
  checkoutId: string,
  customerId: string,
  planId: string
): AppThunk<Promise<MakePaymentResponse>> => async dispatch => {
  dispatch(startMakePayment({}));
  const byronBayApi = new ByronBayApi();

  try {
    const postData = {
      customerId: customerId,
      paymentType: 'deposit',
      planId: planId,
    };

    const response: MakePaymentResponse = await byronBayApi.makePayment(checkoutId, postData);
    dispatch(makePaymentSuccess(response));
    return response;
  } catch (err) {
    const errorMessage =
      err?.response?.data?.error?.message || 'Something went wrong when paying the deposit';
    dispatch(
      makePaymentFailure({
        errorMessage: errorMessage,
      })
    );
    throw err;
  }
};

export const doAddCard = (
  checkoutId: string,
  customerId: string,
  cardDetails: AddCardData
): AppThunk<Promise<PaymentMethodsResponse>> => async dispatch => {
  dispatch(addCardBegin());
  const byronBayApi = new ByronBayApi();
  try {
    const response: PaymentMethodsResponse = await byronBayApi.addCard(
      checkoutId,
      customerId,
      cardDetails
    );
    dispatch(addCardSuccess(response));
    return response;
  } catch (err) {
    const errorMessage =
      typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined'
        ? err.response.data.message
        : 'Something went wrong when adding a card';
    dispatch(
      addCardFailure({
        errorMessage: errorMessage,
      })
    );
    throw err;
  }
};

export const doUpdatePaymentStatus = (
  planId: string,
  checkoutId: string,
  depositAmount: number,
  depositTaker?: boolean
): AppThunk => async dispatch => {
  try {
    dispatch(updatePlanStatusBegin());
    let paymentStatus: PlanAction;
    if (depositTaker && depositAmount > 0) {
      // has to be a deposit taker
      paymentStatus = 'deposit-taker';
    } else if (!depositTaker && depositAmount > 0) {
      // Only Application completer
      paymentStatus = 'application-completer';
    } else {
      // Zero deposit
      paymentStatus = 'zero-deposit';
    }

    const byronBayApi = new ByronBayApi();
    await byronBayApi.updatedPlans(paymentStatus, checkoutId, planId);
    dispatch(updatePlanStatusSuccess());
  } catch (e) {
    dispatch(updatePlansFailure({ errorMessage: e.message }));
  }
};

export const cardCardCloudPayments = (
  checkoutId: string,
  customerId: string,
  cloudCheckRequest: CloudCheckRequest
): AppThunk<Promise<any>> => async dispatch => {
  try {
    dispatch(addCloudCardBegin());
    const byronBayApi = new ByronBayApi();
    const response = await byronBayApi.addCardCloudPayments(
      checkoutId,
      customerId,
      cloudCheckRequest
    );
    dispatch(addCloudCardSuccess(response));
  } catch (error) {
    dispatch(addCloudCardFailure({ errorMessage: error.message }));
  }
};
