import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { AppDispatch } from '../../util/store';
import { useHistory } from 'react-router-dom';
import StepHeader from '../../components/step-header';
// LAYOUT COMPONENTS
import LargerHeaderWithNavLayout from '../../layouts/large-header-with-nav-layout';
// STYLES AND STYLED COMPONENTS
import SCIncomeAndExpenseVerification from './css';
import LivingExpenses from 'components/customer-data/income-details/living-expenses';
import TotalMonthlyIncome from 'components/customer-data/income-details/total-monthly-income';
import MonthlyFinancialExpenses from 'components/customer-data/income-details/monthly-financial-expenses';
import {
  fetchCustomerIncomeExpense,
  updateCustomerDeclaredIncomeExpenses,
} from '../../reducers/customer-slice';
import { checkoutApproval } from 'reducers/checkout-slice';
import { Checkbox, Button } from '@payright/web-components';
import { RootState } from '../../reducers';
import getConstants from 'util/constants';
import {
  CustomerLivingExpeseIncomeData,
  CustomerDeclaredIncomeExpenseData,
} from '../../util/byronbay-api';
import { approvalResultHandler } from 'util/handlers';
import {
  calculateDeclaredTotalMonthlyIncome,
  calculateAppliedLivingExpensePercentage,
  calculateAppliedFinancialExpensePercentage,
} from './util';

// UTILS
import {
  useQuery,
  useTotalSteps,
  useFetchStateDataIfNull,
  useIsLoading,
  useRedirectIfNotLoggedIn,
  useRedirectIfNotValid,
} from '../../util/hooks';
import { clearNumberInputs } from 'util/helpers';
import Loader from 'components/loader';

const { ALL_EXPENSES_CONFIRMATION_TEXT } = getConstants();
const Step2_1 = () => {
  const dispatch: AppDispatch = useDispatch();

  const history = useHistory();
  // URL QUERY PARAMS
  const query = useQuery();
  const queryParamCheckoutId = query.get('checkoutId');

  const globalAuthState = useSelector((state: RootState) => state.auth);
  const globalCheckoutState = useSelector((state: RootState) => state.checkout);
  const globalCustomerState = useSelector((state: RootState) => state.customer);
  const globalPaymentMethodsState = useSelector((state: RootState) => state.paymentMethods);

  const loading = useIsLoading();

  const customerId = globalCheckoutState.checkout?.customerId || globalAuthState.customerId;
  const checkoutId = globalCheckoutState.checkout?.id || queryParamCheckoutId || '';
  const planId = globalCheckoutState.checkout?.planId;
  const checkout = globalCheckoutState.checkout;
  const paymentMethods = globalPaymentMethodsState.paymentMethods;
  const { livingExpenses, creditAccounts } = globalCustomerState;
  const hasJointAccounts =
    livingExpenses?.noIfJointAccount !== undefined && livingExpenses.noIfJointAccount > 0;

  const [expensesConfirmation, setExpensesConfirmation] = useState(false);
  const [expenseSectionVisible, setExpenseSectionVisible] = useState(false);
  const [confiramtionSectionVisible, setConfiramtionSectionVisible] = useState(false);
  const [incomeSelected, setIncomeSelected] = useState(false);
  const [noMonthlyIncomeSelectedErrorMessage, setNoMonthlyIncomeSelectedErrorMessage] = useState<
    boolean
  >(false);

  const handleConfirmationStatus = () => {
    setExpensesConfirmation(prevState => !prevState);
  };

  useFetchStateDataIfNull(checkoutId, customerId);
  useRedirectIfNotLoggedIn();
  useRedirectIfNotValid();

  const totalSteps = useTotalSteps();

  // Get latest income and expenses
  useEffect(() => {
    if (customerId) {
      dispatch(fetchCustomerIncomeExpense(checkoutId, customerId));
    }
  }, [customerId, checkoutId, dispatch]);

  type FormData = {
    livingExpenses: CustomerLivingExpeseIncomeData;
    livingExpenseTotal: number;
    wages: any;
  };

  /// FORM HANDLING ///
  const reactHookFormIncomeExpenseData = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      livingExpenses,
      livingExpenseTotal: 0,
    },
  });

  const { handleSubmit, setValue: setValueLivingExpenses } = reactHookFormIncomeExpenseData;

  useEffect(() => {
    if (globalCustomerState.livingExpenses) {
      setValueLivingExpenses('livingExpenses', globalCustomerState.livingExpenses);
    }
    // loading is part of the dependency array because we want to set
    // these values after the loader is unmounted and the form is mounted
  }, [globalCustomerState.livingExpenses, setValueLivingExpenses, loading]);

  const wages = livingExpenses?.wages || [];

  const incomeExpenseSubmitHandler = async (formData: any) => {
    if (!checkout || !planId || !customerId) {
      return;
    }

    const allWageCheckboxes = formData.wages || [];
    const otherIncomeSource = formData.livingExpenses?.otherIncomeSource;

    // Retrieve other income value
    const customerOtherIncome = formData.livingExpenses?.otherIncomeSource
      ? Number(formData.livingExpenses?.otherIncome.toString().replace(',', ''))
      : 0.0;

    let selectedWageKeys = Object.keys(allWageCheckboxes)
      .filter(obj => allWageCheckboxes[obj] === true)
      .map(key => parseInt(key));

    if (!isIncomeValid(selectedWageKeys, otherIncomeSource)) {
      setNoMonthlyIncomeSelectedErrorMessage(true);
      return;
    }

    const declaredTotalMonthlyIncome = calculateDeclaredTotalMonthlyIncome(
      wages,
      allWageCheckboxes,
      customerOtherIncome
    );

    let customerDeclaredIncomeExpense: CustomerDeclaredIncomeExpenseData = {
      declaredTotalMonthlyLivingExpenses: clearNumberInputs(formData.livingExpenseTotal),
      declaredTotalMonthlyFinancialExpenses: clearNumberInputs(
        formData.livingExpenses.creditCardRepayment
      ),
      declaredTotalMonthlyIncome,
    };

    if (hasJointAccounts) {
      const appliedLivingExpensePercentage = calculateAppliedLivingExpensePercentage(
        declaredTotalMonthlyIncome,
        livingExpenses.originalTotalMonthlyIncome,
        formData.livingExpenses.appliedLivingExpensePercentage
      );

      const appliedFinancialExpensePercentage = calculateAppliedFinancialExpensePercentage(
        declaredTotalMonthlyIncome,
        livingExpenses.originalTotalMonthlyIncome,
        formData.livingExpenses.appliedFinancialExpensePercentage
      );

      customerDeclaredIncomeExpense = {
        ...customerDeclaredIncomeExpense,
        appliedLivingExpensePercentage,
        appliedFinancialExpensePercentage,
      };
    }

    try {
      await dispatch(
        updateCustomerDeclaredIncomeExpenses(checkoutId, customerId, customerDeclaredIncomeExpense)
      );
      const approvalResult = await dispatch(checkoutApproval(planId, checkoutId));
      approvalResultHandler(approvalResult, checkout, paymentMethods, history, dispatch);
    } catch (err) {
      // attachCustomer error - Main error displayed to user
      console.log(err);
    }
  };

  return (
    <>
      <LargerHeaderWithNavLayout>
        <SCIncomeAndExpenseVerification>
          {loading ? (
            <div className="loader-body">
              <Loader />
            </div>
          ) : (
            <div className="step-body">
              <StepHeader activeStep={3} totalSteps={totalSteps} title="" />
              <div className="section-container">
                <div className="section-content">
                  <div className="section-intro">
                    <h4 className="section-intro--header">Income and expense verification</h4>
                    <div className="section-intro--subheader">
                      <p>
                        You're almost there! The last step is for us to assess your income and
                        expenses to make sure this loan is suitable for you.
                      </p>

                      <p>
                        We've made this really easy for you and have pre-filled your income and
                        expense information from your bank statements and credit file.
                      </p>

                      <p>Please review and make any changes if something doesn't look right.</p>
                    </div>
                  </div>

                  <FormProvider {...reactHookFormIncomeExpenseData}>
                    <form onSubmit={handleSubmit(incomeExpenseSubmitHandler)}>
                      <div className="form-section">
                        <div className="form-section--list">
                          <TotalMonthlyIncome
                            shouldShowIncomeError={noMonthlyIncomeSelectedErrorMessage}
                            wages={wages}
                            otherIncome={livingExpenses?.otherIncome}
                            setIncomeSelected={setIncomeSelected}
                          />
                          <hr />
                          {!expenseSectionVisible && (
                            <div className="button-actiongroup-wrapper">
                              <Button
                                withShadow
                                className="next-button"
                                maxWidth="100%"
                                iconPosition="right"
                                type="button"
                                disabled={!incomeSelected}
                                handleClick={() => setExpenseSectionVisible(true)}
                              >
                                Next
                              </Button>
                            </div>
                          )}
                        </div>

                        <div className={!expenseSectionVisible ? 'hidden' : 'form-section--list'}>
                          <LivingExpenses sectionNo={2} hasJointAccounts={hasJointAccounts} />
                          <hr />
                          {!confiramtionSectionVisible && (
                            <div className="button-actiongroup-wrapper">
                              <Button
                                withShadow
                                className="next-button"
                                maxWidth="100%"
                                iconPosition="right"
                                type="button"
                                handleClick={() => setConfiramtionSectionVisible(true)}
                              >
                                Next
                              </Button>
                            </div>
                          )}
                        </div>
                        <div className={!confiramtionSectionVisible ? 'hidden' : ''}>
                          <div
                            className={
                              !confiramtionSectionVisible ? 'hidden' : 'form-section--list'
                            }
                          >
                            <MonthlyFinancialExpenses
                              sectionNo={3}
                              creditAccounts={creditAccounts}
                              hasJointAccounts={hasJointAccounts}
                            />
                          </div>

                          <div className="form-section--check-wrapper" role="confirmation-check">
                            <Checkbox
                              className="checkbox-component"
                              checked={expensesConfirmation || false}
                              value="Agree"
                              name="confirmation"
                              data-testid="confirmation-check"
                              handleClick={handleConfirmationStatus}
                            >
                              <div className="form-section--label-text">
                                {ALL_EXPENSES_CONFIRMATION_TEXT}
                              </div>
                            </Checkbox>
                          </div>

                          <div className="button-actiongroup-wrapper">
                            <Button
                              withShadow
                              className="next-button"
                              maxWidth="100%"
                              iconPosition="right"
                              disabled={!expensesConfirmation}
                            >
                              Next Step
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                </div>
              </div>
            </div>
          )}
        </SCIncomeAndExpenseVerification>
      </LargerHeaderWithNavLayout>
    </>
  );
};

const isIncomeValid = (selectedWages: any, otherIncome: boolean) => {
  return selectedWages.length > 0 || otherIncome ? true : false;
};

export default Step2_1;
