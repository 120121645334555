import { combineReducers } from 'redux';
import authSlice from './auth-slice';
import customerSlice from './customer-slice';
import checkoutSlice from './checkout-slice';
import paymentMethodsSlice from './payment-methods-slice';
import paymentSlice from './payment-slice';

export type RootState = ReturnType<typeof rootReducer>;

const appReducer = combineReducers({
  auth: authSlice,
  customer: customerSlice,
  checkout: checkoutSlice,
  paymentMethods: paymentMethodsSlice,
  payment: paymentSlice,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
