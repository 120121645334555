import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import LogRocket from 'logrocket';
import { AppThunk } from '../util/store';
import ByronBayApi, {
  RatesSuccessResponse,
  StoreSuccessResponse,
  LoginSuccessResponse,
} from '../util/byronbay-api';
import { setCustomerId } from './customer-slice';
import { loading, loadingFunctionInterface } from '../util/loadingFunctionInterface';
import getConstants from '../util/constants';
const { CONTACT_US_PHONE_NUMBER } = getConstants();

type InitialState = {
  loading: boolean;
  activeRequests: Array<authRequestId>;
  isLoggedIn: boolean;
  hasToken: boolean;
  authToken: string | null;
  customerId: string | null;
  merchantRates: RatesSuccessResponse;
  hasErrors?: boolean;
  successMessage: string | null;
  errorMessage: string | null;
  storeConfig: StoreSuccessResponse | null;
  passwordReset: boolean;
};

export const initialState: InitialState = {
  loading: false,
  activeRequests: [],
  isLoggedIn: false,
  hasToken: false,
  authToken: null,
  customerId: null,
  merchantRates: {
    rates: [],
    establishmentFees: [],
    otherFees: {
      monthlyAccountKeepingFee: 0,
      paymentProcessingFee: 0,
    },
  },
  storeConfig: null,
  hasErrors: false,
  successMessage: null,
  errorMessage: null,
  passwordReset: false,
};

type authRequestId = 'forgotPassword' | 'changePassword' | 'login' | 'logout' | 'merchantData';

const startLoading: loadingFunctionInterface<authRequestId> = (
  activeRequests: Array<authRequestId>,
  requestId: authRequestId
): loading<authRequestId> => {
  activeRequests = [...activeRequests, requestId];
  return {
    activeRequests: activeRequests,
    loading: activeRequests.length > 0,
  };
};

const finishLoading: loadingFunctionInterface<authRequestId> = (
  activeRequests: Array<authRequestId>,
  requestId: authRequestId
): loading<authRequestId> => {
  activeRequests = activeRequests.filter(item => item !== requestId);
  return {
    activeRequests: activeRequests,
    loading: activeRequests.length > 0,
  };
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    forgotPasswordBegin(state) {
      const loadingState = startLoading(state.activeRequests, 'forgotPassword');
      return {
        ...state,
        successMessage: '',
        errorMessage: null,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        passwordReset: false,
      };
    },
    forgotPasswordSuccess(state, action: PayloadAction<{ successMessage: string }>) {
      const loadingState = finishLoading(state.activeRequests, 'forgotPassword');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: false,
        successMessage: action.payload.successMessage,
        errorMessage: null,
        passwordReset: true,
      };
    },
    forgotPasswordFailure(state, action: PayloadAction<{ errorMessage: string }>) {
      const loadingState = finishLoading(state.activeRequests, 'forgotPassword');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    changePasswordBegin(state) {
      const loadingState = startLoading(state.activeRequests, 'changePassword');
      return {
        ...state,
        successMessage: '',
        errorMessage: null,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    changePasswordSuccess(state, action: PayloadAction<{ successMessage: string }>) {
      const loadingState = finishLoading(state.activeRequests, 'changePassword');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: false,
        successMessage: action.payload.successMessage,
        errorMessage: null,
      };
    },
    changePasswordFailure(state, action: PayloadAction<{ errorMessage: string }>) {
      const loadingState = finishLoading(state.activeRequests, 'changePassword');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    loginBegin(state) {
      const loadingState = startLoading(state.activeRequests, 'login');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: null,
      };
    },
    loginSuccess(state, action: PayloadAction<{ authToken: string; customerId: string }>) {
      const { authToken, customerId } = action.payload;
      const loadingState = finishLoading(state.activeRequests, 'login');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        isLoggedIn: true,
        hasToken: true,
        authToken,
        customerId,
        hasErrors: false,
        errorMessage: null,
      };
    },
    loginFailure(state, action: PayloadAction<{ errorMessage: string }>) {
      const loadingState = finishLoading(state.activeRequests, 'login');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    logoutBegin: state => {
      const loadingState = startLoading(state.activeRequests, 'logout');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: null,
        hasErrors: false,
      };
    },
    logoutSuccess: state => {
      const loadingState = finishLoading(state.activeRequests, 'logout');
      return {
        ...state,
        isLoggedIn: false,
        customerId: null,
        authToken: null,
        hasToken: false,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: false,
      };
    },
    logoutFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      const loadingState = finishLoading(state.activeRequests, 'logout');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    fetchMerchantDataBegin(state) {
      const loadingState = startLoading(state.activeRequests, 'merchantData');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    fetchMerchantDataSuccess(
      state,
      action: PayloadAction<{
        merchantRates: RatesSuccessResponse;
        storeConfig: StoreSuccessResponse;
      }>
    ) {
      const loadingState = finishLoading(state.activeRequests, 'merchantData');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: false,
        errorMessage: null,
        merchantRates: action.payload.merchantRates,
        storeConfig: action.payload.storeConfig,
      };
    },
    fetchMerchantDataFailure(state, action: PayloadAction<{ errorMessage: string }>) {
      const loadingState = finishLoading(state.activeRequests, 'merchantData');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    setAuthCustomerId: (state, action: PayloadAction<{ customerId: string }>) => {
      return {
        ...state,
        customerId: action.payload.customerId,
      };
    },
  },
});

export const { setAuthCustomerId } = authSlice.actions;

const {
  loginBegin,
  loginSuccess,
  loginFailure,
  logoutBegin,
  logoutSuccess,
  logoutFailure,
  forgotPasswordBegin,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  changePasswordBegin,
  changePasswordSuccess,
  changePasswordFailure,
  fetchMerchantDataBegin,
  fetchMerchantDataSuccess,
  fetchMerchantDataFailure,
} = authSlice.actions;

export default authSlice.reducer;

// -------------------------
// - Thunks - LOGIN
// -------------------------
export const login = (username: string, password: string): AppThunk => async dispatch => {
  dispatch(loginBegin());

  if (process.env.REACT_APP_DEMO_LOGIN === 'true') {
    localStorage.setItem('authToken', 'FAKE_AUTH_TK');
    localStorage.setItem('customerId', 'FAKE_CUST_ID');
    dispatch(loginSuccess({ authToken: 'FAKE_AUTH_TK', customerId: 'FAKE_CUST_ID' }));
    return;
  }

  try {
    const byronBayApi = new ByronBayApi();
    const result: LoginSuccessResponse = await byronBayApi.login(username, password);
    localStorage.setItem('authToken', result.auth_token);
    localStorage.setItem('customerId', result.customer_id);

    LogRocket.identify(result.customer_id, {
      email: username,
    });

    dispatch(loginSuccess({ authToken: result.auth_token, customerId: result.customer_id }));
    dispatch(setCustomerId({ customerId: result.customer_id }));
  } catch (e) {
    dispatch(loginFailure({ errorMessage: e.message }));
  }
};

// -------------------------
// - Thunks - LOGIN APPLICANT
// -------------------------
export const loginApplicant = (
  username: string,
  password: string
): AppThunk<Promise<void>> => async dispatch => {
  dispatch(loginBegin());

  if (process.env.REACT_APP_DEMO_LOGIN === 'true') {
    localStorage.setItem('authToken', 'FAKE_AUTH_TK');
    localStorage.setItem('customerId', 'FAKE_CUST_ID');
    dispatch(loginSuccess({ authToken: 'FAKE_AUTH_TK', customerId: 'FAKE_CUST_ID' }));
    return;
  }

  try {
    const byronBayApi = new ByronBayApi();
    const result: LoginSuccessResponse = await byronBayApi.loginApplicant(username, password);
    localStorage.setItem('authToken', result.auth_token);
    localStorage.setItem('customerId', result.customer_id);

    LogRocket.identify(result.customer_id, {
      email: username,
    });

    dispatch(loginSuccess({ authToken: result.auth_token, customerId: result.customer_id }));
    dispatch(setCustomerId({ customerId: result.customer_id }));
  } catch (e) {
    dispatch(loginFailure({ errorMessage: e.message }));
  }
};

// -------------------------
// - Thunks - LOGOUT
// -------------------------
export const logout = (): AppThunk => async dispatch => {
  try {
    dispatch(logoutBegin());
    localStorage.removeItem('authToken');
    localStorage.removeItem('customerId');
    localStorage.removeItem('redirectUrl');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    dispatch(logoutSuccess());
  } catch (e) {
    dispatch(logoutFailure(e));
  }
};

// -------------------------
// - Thunks - FORGOT PASSWORD
// -------------------------
export const forgotPassword = (userName: string): AppThunk => async dispatch => {
  dispatch(forgotPasswordBegin());

  try {
    const byronBayApi = new ByronBayApi();
    await byronBayApi.forgotPassword(userName);
    dispatch(
      forgotPasswordSuccess({
        successMessage:
          'A temporary password has been sent to your phone. Please use this to change your password',
      })
    );
  } catch (e) {
    dispatch(
      forgotPasswordFailure({
        errorMessage: `Password reset failed, please contact Payright on ${CONTACT_US_PHONE_NUMBER}`,
      })
    );
  }
};

// -------------------------
// - Thunks - CHANGE PASSWORD
// -------------------------
export const changePassword = (
  username: string,
  tempPassword: string,
  newPassword: string,
  confirmPassword: string
): AppThunk => async dispatch => {
  dispatch(changePasswordBegin());

  try {
    const byronBayApi = new ByronBayApi();
    const auth = await byronBayApi.login(username, tempPassword);
    await byronBayApi.changePassword(
      auth.auth_token,
      auth.customer_id,
      tempPassword,
      newPassword,
      confirmPassword
    );
    dispatch(
      changePasswordSuccess({
        successMessage: 'Your password has been updated, please go back to login',
      })
    );
  } catch (e) {
    dispatch(
      changePasswordFailure({
        errorMessage: `Password reset failed, please contact Payright on ${CONTACT_US_PHONE_NUMBER}`,
      })
    );
  }
};

// -------------------------
// - Thunks - FETCH MERCHANT DATA (RATES AND STORE CONFIG)
// -------------------------
export const fetchMerchantData = (
  checkoutId: string
): AppThunk<Promise<boolean>> => async dispatch => {
  dispatch(fetchMerchantDataBegin());

  try {
    const byronBayApi = new ByronBayApi();
    const rates: RatesSuccessResponse = await byronBayApi.rates(checkoutId);
    const store: StoreSuccessResponse = await byronBayApi.store(checkoutId);

    dispatch(fetchMerchantDataSuccess({ merchantRates: rates, storeConfig: store }));
    return true;
  } catch (e) {
    dispatch(fetchMerchantDataFailure({ errorMessage: e.message }));
    return false;
  }
};
