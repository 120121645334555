import React from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { ButtonRounded, CreditCard, IconPlus, media } from '@payright/web-components';
import { prepareCardDetails } from '../reducers/payment-methods-slice';
import { RootState } from '../reducers';
import {
  ControlledInputField,
  ControlledInputCreditCardExpiry,
  ControlledInputCreditCard,
} from './form-fields';
import Loader from './loader';

type PaymentDetailsProps = {
  onChange: any;
  selectedCardId: string;
  mode: string;
  setMode: Function;
} & (PaymentDetailsEwayProps | PaymentDetailsFatZebraProps);

type PaymentDetailsEwayProps = {
  provider: 'eway';
  expiryMonth: any;
  expiryYear: any;
  cardNumber: any;
  setExpiryMonth: Function;
  setExpiryYear: Function;
  setCardNumber: Function;
};

type PaymentDetailsFatZebraProps = {
  provider: 'fat-zebra';
  iframeUrl: string;
};

const SCPaymentDetails = styled.div`
  .header {
    margin-top: 2em;
    margin-bottom: 2em;
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h4 {
        color: ${props => props.theme.colours.blue.base};
      }
      button {
        font-size: 0.86em;
        svg {
          width: 11px;
          height: 11px;
        }
      }
    }
  }

  .card-details {
    & > div {
      margin-bottom: 1em;
    }
  }

  .expiry-csv {
    display: flex;

    .card-expiry {
      flex: 0.6;
      margin-right: 1em;
    }
    .card-csv {
      flex: 0.4;
      margin-left: 1em;
    }
    ${media.max.medium} {
      flex-direction: column;
      .card-expiry {
        margin-right: 0;
        margin-bottom: 1.6em;
      }
      .card-csv {
        margin-left: 0;
      }
    }
  }

  .fat-zebra-iframe {
    width: 100%;
    max-width: 700px;
  }
`;

const PaymentDetailsAddNewCardEway = ({
  handleClick,
  setExpiryMonth,
  expiryMonth,
  setExpiryYear,
  expiryYear,
  existingCards,
}: any) => {
  const { errors } = useFormContext();
  return (
    <>
      <div className="header">
        <div className="header__title">
          <h4>Add New Card</h4>
          {existingCards && !!existingCards.length && (
            <ButtonRounded handleClick={handleClick} colour="blue">
              Use Existing Card
            </ButtonRounded>
          )}
        </div>
      </div>

      <div className="card-details" data-private>
        <ControlledInputField
          rules={{ required: 'Required' }}
          name="cardHolderName"
          className="cardHolder"
          error={errors.cardHolderName && 'Card holder name is required'}
          type="text"
        >
          Name on card
        </ControlledInputField>

        <ControlledInputCreditCard
          name="cardNo"
          rules={{ required: 'Required' }}
          error={errors.cardNo && 'Card number required'}
          encryptName={''}
        />

        <div className="expiry-csv">
          <ControlledInputCreditCardExpiry
            name="expiry"
            label={'Expiry'}
            year={expiryYear}
            month={expiryMonth}
            updateMonth={setExpiryMonth}
            updateYear={setExpiryYear}
          />
          <ControlledInputField
            rules={{ required: 'Required' }}
            error={errors.csv && 'Card number required'}
            name="csv"
            className="card-csv"
            type="number"
          >
            CSV
          </ControlledInputField>
        </div>
      </div>
    </>
  );
};

const PaymentDetailsAddNewCardFatZebra = ({ handleClick, existingCards, iframeUrl }: any) => {
  return (
    <>
      <div className="header">
        <div className="header__title">
          <h4>Add New Card</h4>
          {existingCards && !!existingCards.length && (
            <ButtonRounded handleClick={handleClick} colour="blue">
              Use Existing Card
            </ButtonRounded>
          )}
        </div>
      </div>

      <iframe
        className="fat-zebra-iframe"
        src={iframeUrl}
        height="400"
        width="100%"
        title="cloud_payment"
      ></iframe>
    </>
  );
};

const PaymentDetailsUseExistingCard = ({
  existingCards,
  selectedCardId,
  handleClickAddNew,
  handleSelectCard,
  loading,
}: {
  existingCards?: ExistingCards;
  selectedCardId?: string;
  handleClickAddNew: React.MouseEventHandler;
  handleSelectCard: (cardId: string) => void;
  loading: boolean;
}) => {
  return (
    <>
      {loading && <Loader text="Loading existing payment details.." />}
      {existingCards && !loading && (
        <>
          <div className="header">
            <div className="header__title">
              <h4>Existing Payment Details</h4>
              <ButtonRounded handleClick={handleClickAddNew} colour="blue" icon={<IconPlus />}>
                Add New Card
              </ButtonRounded>
            </div>
          </div>

          <div className="payment-methods">
            {existingCards &&
              existingCards.map(card => (
                <div style={{ marginBottom: '1em' }}>
                  <CreditCard
                    key={card.id}
                    last4Digits={card.last4digits}
                    cardType={card.type}
                    selected={selectedCardId === card.id}
                    selectCard={() => {
                      handleSelectCard(card.id);
                    }}
                    expired={card.expired}
                    cardExpiry={card.cardExpiry}
                  />
                </div>
              ))}
          </div>
        </>
      )}
    </>
  );
};

type ExistingCards = {
  id: string;
  type: React.ComponentProps<typeof CreditCard>['cardType'];
  last4digits: string;
  cardExpiry: string;
  expired: boolean;
}[];

const PaymentDetails = (props: PaymentDetailsProps) => {
  const { onChange, selectedCardId, provider, mode, setMode } = props;

  const globalPaymentMethodsState = useSelector((state: RootState) => state.paymentMethods);

  const loadingPaymentMethods = globalPaymentMethodsState.loading;
  const paymentMethods = globalPaymentMethodsState.paymentMethods;

  const cards = prepareCardDetails(paymentMethods);

  const existingCards: ExistingCards = cards;

  return (
    <SCPaymentDetails>
      <div className="payment-details">
        {mode === 'add' && !loadingPaymentMethods && provider === 'eway' && (
          <PaymentDetailsAddNewCardEway
            setExpiryMonth={(props as PaymentDetailsEwayProps).setExpiryMonth}
            expiryMonth={(props as PaymentDetailsEwayProps).expiryMonth}
            setExpiryYear={(props as PaymentDetailsEwayProps).setExpiryYear}
            expiryYear={(props as PaymentDetailsEwayProps).expiryYear}
            cardNumber={(props as PaymentDetailsEwayProps).cardNumber}
            setCardNumber={(props as PaymentDetailsEwayProps).setCardNumber}
            handleClick={() => {
              setMode('existing');
            }}
            existingCards={existingCards}
          />
        )}
        {mode === 'add' && !loadingPaymentMethods && provider === 'fat-zebra' && (
          <PaymentDetailsAddNewCardFatZebra
            handleClick={() => {
              setMode('existing');
            }}
            existingCards={existingCards}
            iframeUrl={(props as PaymentDetailsFatZebraProps).iframeUrl}
          />
        )}
        {mode === 'existing' && (
          <PaymentDetailsUseExistingCard
            handleClickAddNew={() => {
              setMode('add');
            }}
            handleSelectCard={cardId => {
              onChange(cardId);
            }}
            selectedCardId={selectedCardId}
            existingCards={existingCards}
            loading={loadingPaymentMethods}
          />
        )}
      </div>
    </SCPaymentDetails>
  );
};

export default PaymentDetails;
