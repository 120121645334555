import * as defaults from './defaults';
// Todo: Could we load these file dynamically? Have them in a separate folder maybe?
import * as nz from './nz';

import { NameValuePair } from '@payright/web-components';
import React from 'react';

export type HeaderType = {
  CUSTOMER_DETAILS: {
    [key: string]: string;
  };
  SUMMARY: {
    [key: string]: string;
  };
  INCOME_AND_EXPENSES: {
    [key: string]: string;
  };
  PAYMENT: {
    [key: string]: string;
  };
};

interface Constants {
  REGION_STATES: Array<NameValuePair>;
  RESIDENTIAL_STATUS: Array<NameValuePair>;
  CUSTOMER_TITLES: Array<NameValuePair>;
  TIMEFRAMES: Array<NameValuePair>;
  TIME_IN_JOB: Array<NameValuePair>;
  EMPLOYMENT_TYPES: Array<NameValuePair>;
  INCOME_FREQUENCIES?: Array<NameValuePair>;
  PAYMENT_FREQUENCIES: Array<NameValuePair>;
  PAYMENT_PERIODS: Array<NameValuePair>;
  DEPOSIT_PERCENTAGES: Array<NameValuePair>;
  IDENTIFICATION_MAX_YEARS_EXPIRY_FROM_TODAY: number;
  TIME_IN_ADDRESS: Array<NameValuePair>;
  MONTHLY_PAYMENT_DATE_SELECTION: Array<NameValuePair>;
  DOB_YEARS: number;
  BUSINESS_NUMBER: string;
  LABEL_STATE: string;
  LABEL_SUBURB: string;
  CUSTOMER_HUB_URL: string;
  FAQS_URL: string;
  REDIRECT_URL: string;
  CONTACT_US_PHONE_NUMBER: string;
  HELPDESK_EMAIL: string;
  APPLICATIONS_EMAIL: string;
  CARE_EMAIL: string;
  TELL_YOUR_CUSTOMER_LINK: string;
  DEFAULT_MARKETING_CHECKBOX: boolean;
  PHONE_VALIDATION_REGEX: RegExp;
  MOBILE_VALIDATION_REGEX: RegExp;
  LICENCE_VALIDATION_REGEX: RegExp;
  LICENCE_CARD_NUMBER_VALIDATION_REGEX: RegExp;
  MOBILE_NUMBER_FORMAT_VALIDATION: string;
  PHONE_NUMBER_FORMAT_VALIDATION: string;
  MARKETING_CHECKBOX_TEXT: string;
  CUSTOMER_DETAILS_CUSTOMER_INSTRUCTIONS: string | React.ReactNode;
  SUCCESSFULL_NEW_PLAN_APPROVED_TITLE: string;
  SUCCESSFULL_NEW_PLAN_APPROVED_BODY: string;
  SUCCESSFULL_PRE_APPROVED_PLAN_TITLE: string;
  SUCCESSFULL_PRE_APPROVED_PLAN_BODY: string;
  SUCCESSFULL_PRE_APPROVED_PAYMENT_PLAN_TITLE: string;
  SUCCESSFULL_PRE_APPROVED_PAYMENT_PLAN_BODY: string;
  ADDRESS_DETAILS?: string;
  SHIPPING_DETAILS_TITLE: string;
  CANCEL_POPUP_TITLE: string;
  CANCEL_POPUP_TEXT: string;
  CANCEL_POPUP_CANCEL_PLAN: string;
  CANCEL_POPUP_ABORT_CANCEL: string;
  ADDRESS_DETAIL_TEXT_NEW_CUSTOMER: string;
  ADDRESS_DETAIL_TEXT_EXISTING_CUSTOMER: string;
  CUSTOMER_IDENTIFICATION: string | React.ReactNode;
  LIVING_EXPENSES_CATEGORIES: Array<{ name: string; value: string; hint?: string }>;
  ALL_EXPENSES_CONFIRMATION_TEXT: string;
  TOTAL_MONTHLY_LOAN_REPAYMENTS_TEXT: string;
  INCOME_MUST_BE_GREATER_THAN_ZERO: string;
  ONE_MONTHLY_INCOME_MUST_SELECTED: string;
  MONTHLY_EXPENSE_MAX_VALIDATION: string;
  MONTHLY_EXPENSE_TOTAL_VALIDATION: string;
  MONTHLY_TOTAL_REPAYMENT_VALIDATION: string;
  EXPENSE_MUST_BE_NOT_NULL: string;
  DEFAULT_INCOME_PERCENTAGE: number;
  OTHER_INCOME_LABEL: string;
  DECIMAL_NUMBERS_VALIDATION: string;
  HEADER: HeaderType;
  LESS_THAN_6_MONTHS: string;
  HEADER_MESSAGE_REVIEW: string;
  REVIEW_MESSAGE: string | React.ReactNode;
  NONEDITABLE_FIELD_MESSAGE: string;
}
const getConstants = (): Constants => {
  let constants: Constants = { ...defaults };

  if (!process.env.REACT_APP_REGION) {
    return constants;
  }

  // Todo: make more dynamic for other regions?
  if (process.env.REACT_APP_REGION.toLowerCase() === 'nz') {
    constants = { ...constants, ...nz };
  }

  return constants;
};

export default getConstants;
