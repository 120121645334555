import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { CalendarStandard } from '@payright/web-components';
import styled from 'styled-components';
import { RootState } from '../../reducers';
import { ControlledDropdown } from '../../components/form-fields';
import getConstants from 'util/constants';

const { MONTHLY_PAYMENT_DATE_SELECTION } = getConstants();

type FormData = {
  selectedPaymentDate: Date | null;
  cardHolderName: string | null;
  csv: string | null;
  expiry: string | null;
  selectedMonthlyDate: string | null;
};

const SCPaymentStartDate = styled.div`
  margin-top: 2em;
  h4 {
    color: ${props => props.theme.colours.blue.base};
    margin-top: 2em;
    margin-bottom: 1em;
  }
`;

const PaymentStartDate = () => {
  const checkout = useSelector((state: RootState) => state.checkout);
  const customer = useSelector((state: RootState) => state.customer);
  const repeatCustomer = customer?.accountDetails?.isReturnCustomer;
  // THIS IS THE NEXT REPAYMENT DATE
  const nextRepaymentDate = customer?.accountDetails?.nextRepaymentDate;

  const paymentFrequency = checkout.paymentDetails?.paymentFrequency;
  //const nextRepaymentDateReturningCustomer
  const [date, setDate] = useState(new Date());

  const { errors, setValue } = useFormContext<FormData>();

  return (
    <SCPaymentStartDate>
      {paymentFrequency === 'Monthly' && <h4>Select day of the month for repayments</h4>}
      <div className="dateof-firstrepayment">
        {paymentFrequency === 'Monthly' && !repeatCustomer && (
          <ControlledDropdown
            name="selectedMonthlyDate"
            options={MONTHLY_PAYMENT_DATE_SELECTION}
            readOnly={false}
            rules={{ required: 'Required' }}
            error={errors.selectedMonthlyDate && 'Please select a day'}
          >
            Monthly payment day
          </ControlledDropdown>
        )}

        {nextRepaymentDate && repeatCustomer && (
          <h4>
            Your next payment date is -
            {new Date(nextRepaymentDate).toLocaleString('en-AU', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })}
          </h4>
        )}

        {paymentFrequency === 'Fortnightly' && !repeatCustomer && (
          <Controller
            onChangeName="handleInputChange"
            rules={{ required: true }}
            as={
              <CalendarStandard
                label="Date of next payment"
                value={date}
                minDate={new Date()}
                maxDate={new Date(new Date().setDate(new Date().getDate() + 2 * 7))} // Payment date can be with in two weeks
                handleDateChange={date => {
                  setValue('selectedPaymentDate', date);
                  setDate(date);
                }}
              />
            }
            name="selectedPaymentDate"
          ></Controller>
        )}

        {paymentFrequency === 'Weekly' && !repeatCustomer && (
          <Controller
            onChangeName="handleInputChange"
            rules={{ required: true }}
            as={
              <CalendarStandard
                label="Date of first payment"
                value={date}
                minDate={new Date()}
                maxDate={new Date(new Date().setDate(new Date().getDate() + 7))} // Payment date can be within one weeks
                handleDateChange={date => {
                  setValue('selectedPaymentDate', date);
                  setDate(date);
                }}
              />
            }
            name="selectedPaymentDate"
          ></Controller>
        )}
      </div>
    </SCPaymentStartDate>
  );
};

export default PaymentStartDate;
