import { AppDispatch } from '../../util/store';
import { updateCheckout, checkoutCancellationThenRedirect } from '../../reducers/checkout-slice';
import { CheckoutSuccessResponse, PlanStatus, PaymentMethodsResponse } from 'util/byronbay-api';
import { PlanResultType } from '../../types/app';
import { History } from 'history';
import { CheckoutStep } from '../../types/checkout';

// CHECKOUT PLAN CANCELLATION
export const cancellationClickHandler = (
  checkoutId: string,
  redirectUrl: string,
  dispatch: AppDispatch
) => {
  if (checkoutId && redirectUrl) {
    dispatch(checkoutCancellationThenRedirect(checkoutId, redirectUrl));
  }
};

export const approvalResultHandler = (
  approvalStatus: PlanStatus,
  checkout: CheckoutSuccessResponse,
  paymentMethods: PaymentMethodsResponse,
  history: History,
  dispatch: AppDispatch
) => {
  if (approvalStatus === PlanStatus.APPROVED) {
    const { id: checkoutId, applicationCompletedBy } = checkout;
    const checkoutType = checkout.attributes?.type;
    if (checkoutId && checkout?.attributes && approvalStatus === 'Approved') {
      // SKIP PAYMENT FOR EXISTING USER AND NEW PRE-APPROVAL CHECKOUT
      if (checkoutType === 'pre-approval' && paymentMethods.length > 0) {
        history.push('/payment-plan/plan-result' + history.location.search);
        dispatch(
          updateCheckout({
            checkoutIdentifier: checkoutId,
            paymentDetails: checkout.attributes.paymentDetails,
            checkoutData: {
              type: checkout.attributes.type,
              redirectUrl: checkout.attributes.redirectUrl,
              expiresAt: checkout.attributes.expiresAt,
              step: CheckoutStep.CHECKOUT_STEP_PLAN_RESULT,
            },
          })
        );
      }
      // REDIRECT TO STEP 3 FOR ALL APPROVED CHECKOUTS
      if (
        applicationCompletedBy === 'Customer' ||
        checkoutType === 'standard' ||
        checkoutType === 'applyAndPay' ||
        (checkoutType === 'pre-approval' && !paymentMethods.length)
      ) {
        history.push('/payment-plan/payment' + history.location.search);
        dispatch(
          updateCheckout({
            checkoutIdentifier: checkoutId,
            paymentDetails: checkout.attributes.paymentDetails,
            checkoutData: {
              type: checkout.attributes.type,
              redirectUrl: checkout.attributes.redirectUrl,
              expiresAt: checkout.attributes.expiresAt,
              step: CheckoutStep.CHECKOUT_STEP_PAYMENT,
            },
          })
        );
      }
    }
    return;
  }

  if (approvalStatus === PlanStatus.APPROVED_PENDING_ID || approvalStatus === PlanStatus.REVIEW) {
    history.push('/payment-plan/plan-result' + history.location.search, {
      planResultType: PlanResultType.REVIEW,
    });
    return;
  }

  if (approvalStatus === PlanStatus.CANCELLED || approvalStatus === PlanStatus.DECLINED) {
    history.push('/payment-plan/plan-result' + history.location.search);
    return;
  }

  if (approvalStatus === PlanStatus.APPROVED_STATEMENT_REQUESTED) {
    history.push('/payment-plan/plan-result' + history.location.search, {
      planResultType: PlanResultType.APPROVED_STATEMENT_REQUESTED,
    });
    return;
  }
};
