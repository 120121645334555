import React, { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';

// UTILS
import { useQuery } from '../../util/hooks';

// REDUX AND SLICES
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { login, logout } from '../../reducers/auth-slice';
import { fetchCheckoutAttributes, updateCheckout } from '../../reducers/checkout-slice';

// WEB COMPONENTS
import { Button, IconLogo } from '@payright/web-components';

// OTHER COMPONENTS
import { ControlledInputField } from '../../components/form-fields';
import SCSpinner from '../../components/spinner';

// STYLES AND STYLED COMPONENTS
import SCEcommerceLogin from './css';
import { CheckoutStep } from 'types/checkout';

interface LoginProps {}

type FormData = {
  username: string;
  password: string;
};

const Login = (props: LoginProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  // URL QUERY PARAMS
  const query = useQuery();
  const queryParamCheckoutId = query.get('checkoutId');

  // GLOBAL STATE
  const globalAuthState = useSelector((state: RootState) => state.auth);
  const globalCheckoutState = useSelector((state: RootState) => state.checkout);

  // VARIABLES
  const { errorMessage, isLoggedIn, loading } = globalAuthState;
  const { hasErrors } = globalCheckoutState;
  const customerIdAuth = globalAuthState.customerId;
  const customerIdCheckout = globalCheckoutState.checkout?.customerId;
  const checkout = globalCheckoutState.checkout;
  const checkoutId = globalCheckoutState.checkout?.id || '';
  const checkoutType = globalCheckoutState.checkout?.attributes?.type;
  const status = globalCheckoutState.checkout?.status;

  // START LOGIN FORM
  const reactHookForm = useForm<FormData>({
    defaultValues: {
      username: '',
      password: '',
    },
    mode: 'onChange',
  });
  const { handleSubmit, formState, setValue } = reactHookForm;

  const formSubmitHandler = (formData: FormData) => {
    dispatch(login(formData.username, formData.password));
  };
  // END LOGIN FORM

  // LOAD LATEST CHECKOUT FROM THE SERVER
  useEffect(() => {
    if (queryParamCheckoutId) {
      dispatch(fetchCheckoutAttributes(queryParamCheckoutId));
    }
  }, [queryParamCheckoutId, dispatch]);

  // REDIRECT TO STEP 3 PAYMENT IF CHECKOUT TYPE IS PRE-APPROVAL
  // AND CHECKOUT CUSTOMER ID EQUALS LOGGED IN CUSTOMER ID
  // AND CHECKOUT STATUS IS APPROVED
  useEffect(() => {
    if (
      isLoggedIn &&
      checkout?.attributes &&
      checkoutType === 'pre-approval' &&
      customerIdCheckout === customerIdAuth &&
      (status === 'approved' || status === 'approved_pre_approval_card_details_taken')
    ) {
      history.push('/payment-plan/payment' + history.location.search);
      dispatch(
        updateCheckout({
          checkoutIdentifier: checkoutId,
          paymentDetails: checkout.attributes.paymentDetails,
          checkoutData: {
            type: checkout.attributes.type,
            redirectUrl: checkout.attributes.redirectUrl,
            expiresAt: checkout.attributes.expiresAt,
            step: CheckoutStep.CHECKOUT_STEP_PRE_APPROVAL_PAYMENT,
          },
        })
      );
    }
  }, [isLoggedIn, history, checkoutType, customerIdCheckout, customerIdAuth]);

  // REDIRECT TO STEP 3 PAYMENT IF CHECKOUT TYPE IS APPLY AND PAY
  // AND CHECKOUT CUSTOMER ID EQUALS LOGGED IN CUSTOMER ID
  // AND CHECKOUT STATUS IS APPROVED
  useEffect(() => {
    if (
      isLoggedIn &&
      checkout?.attributes &&
      checkoutType === 'applyAndPay' &&
      customerIdCheckout === customerIdAuth &&
      status === 'approved'
    ) {
      history.push('/payment-plan/payment' + history.location.search);
      dispatch(
        updateCheckout({
          checkoutIdentifier: checkoutId,
          paymentDetails: checkout.attributes.paymentDetails,
          checkoutData: {
            type: checkout.attributes.type,
            redirectUrl: checkout.attributes.redirectUrl,
            expiresAt: checkout.attributes.expiresAt,
            step: CheckoutStep.CHECKOUT_STEP_PAYMENT,
          },
        })
      );
    }
  }, [isLoggedIn, history, checkoutType, customerIdCheckout, customerIdAuth, status]);

  // REDIRECT TO STEP 1 FORM IF CHECKOUT STATUS IS PENDING
  useEffect(() => {
    if (isLoggedIn && status === 'pending') {
      history.push('/payment-plan/customer-details' + history.location.search);
    }
  }, [isLoggedIn, history, checkoutType, status]);

  // REDIRECT TO PLAN RESULT IF CHECKOUT STATUS IS REVIEW OR DECLINED
  useEffect(() => {
    if (isLoggedIn && (status === 'review' || status === 'declined')) {
      history.push('/payment-plan/plan-result' + history.location.search);
    }
  }, [isLoggedIn, history, checkoutType, status]);

  // DEMO LOGIN
  if (process.env.REACT_APP_DEMO_LOGIN === 'true') {
    setValue('username', 'demo@payright.com.au');
    setValue('password', 'demo_password');
  }

  return (
    <SCEcommerceLogin>
      <div className="ecommerce-image" />
      <div className="login-wrapper">
        <div className="login-header">
          <div className="logo">
            <IconLogo width={180} height={74} />
          </div>
          <h4>Login</h4>
        </div>
        <FormProvider {...reactHookForm}>
          <form onSubmit={handleSubmit(formSubmitHandler)} className="loginForm">
            <div data-testid="error-message">
              {errorMessage && (
                <div className="validation-msg-box">Incorrect email or password</div>
              )}
            </div>
            <div>
              {hasErrors && <div className="validation-msg-box">Checkout ID is invalid</div>}
            </div>
            <ControlledInputField
              placeholder="Email address"
              name="username"
              type="email"
              rules={{ required: true }}
            >
              Email Address
            </ControlledInputField>
            <ControlledInputField
              placeholder="Password"
              name="password"
              type="password"
              rules={{ required: true }}
            >
              Password
            </ControlledInputField>
            <div className="forgot-password">
              <Link to={`/forgot-password/${history.location.search}`}>
                <span className="hide-mobile">I've forgotten my password</span>
                <span className="mobile-only">Forgot password?</span>
              </Link>
            </div>
            <Button
              withShadow
              disabled={formState.isValid === false || loading}
              maxWidth="100%"
              type="submit"
              iconPosition="right"
              icon={loading ? <SCSpinner /> : undefined}
            >
              Login
            </Button>
          </form>
        </FormProvider>
      </div>
    </SCEcommerceLogin>
  );
};

export default Login;
