import React, { useEffect, useState } from 'react';
import { Button, IconNext } from '@payright/web-components';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers';

type TimerProps = {
  seconds: number;
  checkoutId: string;
  redirectUrl?: string;
};

const ButtonWrapper = styled.div`
  margin-top: -2em;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonTimer = ({ seconds, checkoutId, redirectUrl }: TimerProps) => {
  const [timeLeft, setTimeLeft] = useState(seconds);
  const [autoRedirect, setAutoRedirect] = useState(true);
  const globalCheckoutState = useSelector((state: RootState) => state.checkout);
  const checkoutStatus = globalCheckoutState.checkout?.status;

  const getRedirectUrl = (checkoutId: string) => {
    if (typeof redirectUrl !== 'undefined' && redirectUrl !== null) {
      const seperator = redirectUrl.indexOf('?') !== -1 ? '&' : '?';
      const status =
        checkoutStatus === 'review' || checkoutStatus === 'declined' ? 'decline' : checkoutStatus;
      return redirectUrl + seperator + `checkoutId=${checkoutId}&status=${status?.toUpperCase()}`; // COMPLETE | DECLINE | CANCELLED
    }
    return '/';
  };

  useEffect(() => {
    if (autoRedirect) {
      if (!timeLeft) {
        if (typeof redirectUrl !== 'undefined') {
          const url = getRedirectUrl(checkoutId);
          window.location.replace(url);
        }
      }

      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [autoRedirect, timeLeft, redirectUrl, checkoutId]);

  useEffect(() => {
    if (!autoRedirect) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('customerId');
      window.location.replace(getRedirectUrl(checkoutId));
    }
  }, [autoRedirect]);

  if (!redirectUrl) {
    return null;
  }

  return (
    <ButtonWrapper>
      <p>
        {timeLeft < 0
          ? `Click the return button to redirect`
          : `Please do not close this window or click the Back button on your browser. You will be automatically redirected in ${timeLeft} seconds`}
      </p>
      <Button
        type="submit"
        handleClick={() => setAutoRedirect(false)}
        icon={<IconNext />}
        disabled={!autoRedirect}
      >
        Return
      </Button>
    </ButtonWrapper>
  );
};

export default ButtonTimer;
