import styled from 'styled-components';
import { media } from '@payright/web-components';

export const SCStepHeader = styled.h4`
  margin: 2em 0;
  span {
    color: ${props => props.theme.colours.blue.base};
    margin-right: 0.75em;
  }
  color: ${props => props.theme.colours.blue.base};
  ${media.max.medium} {
    font-size: 1.13em;
    span {
      display: block;
    }
  }
`;
export interface StepHeaderProps {
  activeStep: number;
  totalSteps: number;
  title: string;
}

export const StepHeader = ({ activeStep, title, totalSteps }: StepHeaderProps) => (
  <SCStepHeader>
    <span>
      Step {activeStep} of {totalSteps}.
    </span>
    {title}
  </SCStepHeader>
);

export default StepHeader;
