import styled from 'styled-components';
import { media } from '@payright/web-components';

const SCPlanResult = styled.div`
  .icon,
  .header,
  .message,
  .action {
    margin: 2em 0;
    text-align: center;
  }

  ${media.min.medium} {
    .icon {
      margin-top: 6em;
    }
  }

  .header {
    margin: 2em 0;
    &__title {
      display: flex;
      justify-content: center;
      align-items: center;
      h4 {
        color: ${props => props.theme.colours.blue.base};
      }
    }
  }

  p {
    margin: 2em 0;
    font-size: 1.2em;
  }
  .next-steps {
    text-align: left;
    margin-left: 12.5em;
    margin-top: 0.5em;

    ol {
        margin-top:1em;
        list-style-type:decimal;
         color: ${props => props.theme.colours.plum};
        li {
            list-style-position: outside;
            line-height:30px;
            margin-left:1em;
            padding-left:20px;
         }
        li::before {
            width: 20px;
            display:inline;
           }
      }
   }

   .approved-pending-statements {
     text-align: left;
     font-size: inherit;
     padding-left: 1em;
   }
  }

  .actions {
    display: flex;
    padding-bottom: 30px;
    ${media.max.medium} {
      button:first-of-type {
        margin-right: 0px;
      }
      button:last-of-type {
        margin-left: 4px;
        box-shadow: unset;
      }
    }

    ${media.min.medium} {
      button {
        max-width: unset;
        padding: 0 2.5em;
        &:first-of-type {
          margin-right: 1.66em;
        }
      }
    }
  }

  .item-card-list {
    padding: 0.5rem;
  }
`;

export const SCItemCard = styled.div`
  .item-card {
    display: flex;
    flex-direction: column;
    margin: 2rem auto;
    padding: 1rem;
    border: 1px solid rgb(0, 0, 0, 0.2);
    box-shadow: 0 3px 7px -1px rgba(#000, 0.5);
    background: #fff;
    line-height: 1.4;
    border-radius: 5px;
    z-index: 0;
    text-align: left;

    position: relative;

    .meta {
      position: relative;
      z-index: 0;
      height: 200px;
      overflow: hidden;
      margin: 0 auto;
      text-align: center;
    }
    .photo {
      height: 100%;
      width: auto;
    }
    .item-details {
      padding: 1rem;
      background: #fff;
      position: relative;
      z-index: 1;
      h4 {
        line-height: 1;
        margin: 0;
      }
      .item-description {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .item-price {
        font-weight: bold;
        color: #531dff;
        padding-bottom: 1rem;

        .strikethrough {
          text-decoration: line-through;
        }
      }
      .item-footer {
        position: absolute;
        padding-top: 10px;
        content: '';
        display: table;
        clear: both;
        width: 100%;
        bottom: 0;

        .item-order-receipt {
          display: inline;
          float: left;
          font-size: 14px;
          width: 50%;

          span {
            text-align: left;
          }
        }
        .item-order-date {
          display: inline;
          float: right;
          font-size: 14px;
          width: 50%;

          span {
            text-align: right;
          }
        }
      }
    }

    .item-quantity {
      position: absolute;
      top: -21px;
      right: -21px;
      width: 42px;
      height: 42px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      line-height: 42px;
      border-radius: 50%;
      background: #531dff;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    p {
      position: relative;
      margin: 0.7rem 0 0;
      &:first-of-type {
        margin-top: 1.25rem;
      }
    }

    @media (min-width: 640px) {
      flex-direction: row;
      max-width: 700px;
      .meta {
        flex-basis: 40%;
      }
      .item-details {
        flex-basis: 60%;
        &:before {
          content: '';
          background: #fff;
          width: 30px;
          position: absolute;
          left: -10px;
          top: 0;
          bottom: 0;
          z-index: -1;
        }
      }
    }
  }
`;

export const LoadingWrapper = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default SCPlanResult;
