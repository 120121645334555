import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { media, SelectableCard } from '@payright/web-components';
import { Row, Col } from 'react-flexbox-grid';
import LicenceDetails from './licence-details';
import { ControlledInputField, ControlledDatePicker } from '../form-fields';
import {
  IdentificationDetailsData as MerchantApiIdentificationDetailsData,
  IdentificationType as MerchantApiIdentificationType,
} from '../../types/customer';
import getConstants from 'util/constants';
import { useIsNz } from 'util/hooks';

const { IDENTIFICATION_MAX_YEARS_EXPIRY_FROM_TODAY } = getConstants();

export type IdentificationDetailsData = MerchantApiIdentificationDetailsData;
export type IdentificationType = MerchantApiIdentificationType;

export interface IdentificationDetailsProps {
  existingCustomer: boolean;
  editing: boolean;
  handleIdentificationType: Function;
  identificationType: IdentificationType;
}

export interface IdentificationDetailsProps {
  existingCustomer: boolean;
  editing: boolean;
}

const SCIdentificationDetails = styled.div`
  .card-wrapper {
    margin-top: 1em;
  }
  margin-bottom: 3em;
  ${media.max.medium} {
    .input-field,
    .dropdown {
      margin-bottom: 1.33em;
    }
    .dropdown {
      max-width: 100%;
    }
  }
  ${media.min.medium} {
    .input-field {
      margin-bottom: 2.46em;
    }
    .state-expiry-wrapper {
      display: flex;
      margin-top: 1em;
      .date-picker {
        width: calc(50% - 0.75em);
        margin-left: 0.75em;
      }
      .input-field {
        flex: calc(50% - 0.75em);
        margin-bottom: 1em;
      }
      & > .dropdown {
        width: calc(50% - 0.75em);
        margin-right: 0.75em;

        .error {
          text-align: left;
        }
      }
    }
    .passport-expiry-wrapper {
      width: 50%;
      .date-picker {
        width: 100%;
        margin-left: 0em;
      }
      .input-field {
        flex: calc(50% - 0.75em);
        margin-bottom: 1em;
      }
      & > .dropdown {
        width: calc(50% - 0.75em);
        margin-right: 0.75em;
      }
    }
  }
`;

const IdentificationDetails = ({
  existingCustomer,
  editing,
  handleIdentificationType,
  identificationType,
}: IdentificationDetailsProps) => {
  const { errors, setValue, register } = useFormContext<{
    identificationType: IdentificationType;
    identificationDetails: IdentificationDetailsData;
  }>();

  const isNz = useIsNz();

  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (Object.keys(errors).length > 0 && errors.identificationDetails && sectionRef.current) {
      // check for null and focus target on block with errors
      sectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [errors]); // Errors object changes on submit

  useEffect(() => {
    register({ name: 'identificationDetails.identificationType' });
    setValue('identificationDetails.identificationType', identificationType);
  }, [identificationType, register, setValue]);

  const colWidth = !isNz ? 4 : 6;

  return (
    <SCIdentificationDetails id="IdentificationSection">
      <div ref={sectionRef} style={{ position: 'relative', top: '-2em' }} />
      <Row>
        <Col xs={12} md={12} lg={12}>
          <Row style={{ marginBottom: '2em' }}>
            <Col xs={12} md={colWidth} lg={colWidth} style={{ marginTop: '1em' }}>
              <SelectableCard
                selected={identificationType === 'license'}
                handleSelect={handleIdentificationType}
                title="Licence"
                name={'license' as IdentificationType}
                body=""
              />
            </Col>
            <Col xs={12} md={colWidth} lg={colWidth} style={{ marginTop: '1em' }}>
              <SelectableCard
                selected={identificationType === 'passport'}
                handleSelect={handleIdentificationType}
                title="Passport"
                name={'passport' as IdentificationType}
                body=""
              />
            </Col>
            {!isNz && (
              <Col xs={12} md={4} lg={4} style={{ marginTop: '1em' }}>
                <SelectableCard
                  selected={identificationType === 'other'}
                  handleSelect={handleIdentificationType}
                  title="Other"
                  name={'other' as IdentificationType}
                  body=""
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      {identificationType === 'license' && (
        <LicenceDetails existingCustomer={existingCustomer} editing={editing} />
      )}

      {identificationType === 'passport' && (
        <div className="details-body">
          <div className="identificationtype-wrapper">
            <div className="identificationtype-item"></div>
            <div className="identificationtype-item"></div>
          </div>

          <ControlledInputField
            name="identificationDetails.passportNumber"
            rules={{
              required: 'Passport number is required',
              pattern: {
                value: /^(?!^0+$)[a-zA-Z0-9]{4,20}$/,
                message: 'Invalid passport number',
              },
            }}
            error={(errors.identificationDetails as any)?.passportNumber?.message as string}
            readOnly={existingCustomer && !editing}
          >
            Number
          </ControlledInputField>

          <div className="passport-expiry-wrapper">
            <ControlledDatePicker
              name="identificationDetails.passportExpiry"
              rules={{
                required: 'Passport Expiry is required',
                validate: (value: any) => {
                  const expiry = new Date(value);
                  const today = new Date();
                  today.setDate(today.getDate() + 1);
                  if (expiry.getTime() < today.getTime()) {
                    return 'Enter a valid expiry';
                  }
                },
              }}
              error={(errors.identificationDetails as any)?.passportExpiry?.message as string}
              readOnly={existingCustomer && !editing}
              startYear={new Date().getFullYear()}
              endYear={new Date().getFullYear() + IDENTIFICATION_MAX_YEARS_EXPIRY_FROM_TODAY}
            >
              Expiry
            </ControlledDatePicker>
          </div>
        </div>
      )}

      {identificationType === 'other' && !isNz && (
        <div className="details-body">
          <div className="identificationtype-wrapper">
            <div className="identificationtype-item"></div>
            <div className="identificationtype-item"></div>
          </div>

          <ControlledInputField
            readOnly={existingCustomer && !editing}
            rules={{
              required: 'Other ID is required',
              minLength: 2 || 'ID needs to a min of 2 characters',
            }}
            error={(errors.identificationDetails as any)?.otherIdType.message as string}
            name="identificationDetails.otherIdType"
          >
            Other type of government photo ID
          </ControlledInputField>
        </div>
      )}
    </SCIdentificationDetails>
  );
};

export default IdentificationDetails;
