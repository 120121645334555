import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { media } from '@payright/web-components';
import {
  ControlledDropdown,
  ControlledInputField,
  ControlledDropdownWithInput,
} from '../form-fields';
import { EmploymentDetailsData as MerchantApiEmploymentDetailsData } from '../../types/customer';
import getConstants from '../../util/constants';
import { envIsTrue } from '../../util/helpers';

const {
  TIME_IN_JOB,
  EMPLOYMENT_TYPES,
  INCOME_FREQUENCIES,
  PHONE_VALIDATION_REGEX,
  PHONE_NUMBER_FORMAT_VALIDATION,
  MOBILE_VALIDATION_REGEX,
  MOBILE_NUMBER_FORMAT_VALIDATION,
  OTHER_INCOME_LABEL,
} = getConstants();

export type EmploymentDetailsData = MerchantApiEmploymentDetailsData;

export interface EmploymentDetailsProps {
  existingCustomer: boolean;
  editing: boolean;
  allowIncomeEditing?: boolean;
}

const SCEmploymentDetails = styled.div`
  .details-body {
    display: grid;
    grid-row-gap: 1.5rem;

    .current-employer {
      width: 100%;
      display: grid;
      row-gap: 1.5rem;
      column-gap: 0.5rem;

      ${media.min.medium} {
        grid-auto-flow: row;
        grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
      }
    }

    .income-details {
      display: grid;
      grid-row-gap: 1.5rem;
    }
  }

  .dropdown label,
  .input-field label {
    white-space: nowrap;
  }
  .wages-and-salary {
    color: #6e6e6e;
  }
`;

const EmploymentDetails = ({
  existingCustomer,
  editing,
  allowIncomeEditing = true,
}: EmploymentDetailsProps) => {
  const { clearErrors, errors, watch } = useFormContext<{
    employmentDetails: EmploymentDetailsData;
  }>();

  const employmentType = watch(
    'employmentDetails.employmentType'
  ) as EmploymentDetailsData['employmentType'];

  let additionalEmploymentDetailsBlock: 'employed' | 'self-employed' | 'others' = 'employed';

  switch (employmentType) {
    case 'Full time':
    case 'Part Time':
    case 'Casual':
      additionalEmploymentDetailsBlock = 'employed';
      break;
    case 'Self Employed':
      additionalEmploymentDetailsBlock = 'self-employed';
      break;
    default:
      additionalEmploymentDetailsBlock = 'others';
  }

  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    clearErrors('employmentDetails');
  }, [employmentType]);

  useEffect(() => {
    if (Object.keys(errors).length > 0 && errors.employmentDetails && sectionRef.current) {
      // check for null and focus target on block with errors
      sectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [errors]); // Errors object changes on submit

  return (
    <SCEmploymentDetails>
      <div ref={sectionRef} style={{ position: 'relative', top: '-2em' }} />
      <div className="details-body">
        <ControlledDropdown
          name="employmentDetails.employmentType"
          rules={{ required: 'Employment Type is required' }}
          readOnly={existingCustomer && !editing}
          options={EMPLOYMENT_TYPES}
          maxWidth="100%"
          error={(errors.employmentDetails as any)?.employmentType?.message as string}
        >
          Employment or Benefit Type
        </ControlledDropdown>

        <div className="current-employer">
          {additionalEmploymentDetailsBlock === 'employed' && (
            <div className="current-employer">
              <ControlledInputField
                name="employmentDetails.employerName"
                readOnly={existingCustomer && !editing}
                rules={{ required: 'Employer Name is required' }}
                error={(errors.employmentDetails as any)?.employerName?.message as string}
              >
                Employer Name*
              </ControlledInputField>

              <ControlledInputField
                name="employmentDetails.employerPhone"
                readOnly={existingCustomer && !editing}
                rules={{
                  required: 'Phone number is required',
                  pattern: envIsTrue('REACT_APP_SKIP_CUSTOMER_MOBILE_VALIDATION')
                    ? undefined
                    : {
                        value: PHONE_VALIDATION_REGEX,
                        message: PHONE_NUMBER_FORMAT_VALIDATION,
                      },
                }}
                error={(errors.employmentDetails as any)?.employerPhone?.message as string}
              >
                Employer Phone No.*
              </ControlledInputField>

              <ControlledDropdown
                name="employmentDetails.timeInJob"
                options={TIME_IN_JOB}
                readOnly={existingCustomer && !editing}
                rules={{ required: 'Time in Job is required' }}
                error={(errors.employmentDetails as any)?.timeInJob?.message as string}
              >
                Time in Job*
              </ControlledDropdown>
            </div>
          )}

          {additionalEmploymentDetailsBlock === 'self-employed' && (
            <div className="current-employer">
              <ControlledInputField
                name="employmentDetails.tradingName"
                readOnly={existingCustomer && !editing}
                rules={{ required: 'Trading Name is required' }}
                error={(errors.employmentDetails as any)?.tradingName?.message as string}
              >
                Trading Name*
              </ControlledInputField>

              <ControlledInputField
                name="employmentDetails.tradingPhone"
                readOnly={existingCustomer && !editing}
                rules={{
                  required: 'Phone number is required',
                  pattern: envIsTrue('REACT_APP_SKIP_CUSTOMER_MOBILE_VALIDATION')
                    ? undefined
                    : {
                        value: MOBILE_VALIDATION_REGEX,
                        message: MOBILE_NUMBER_FORMAT_VALIDATION,
                      },
                }}
                error={(errors.employmentDetails as any)?.tradingPhone?.message as string}
              >
                Employer Phone No.*
              </ControlledInputField>

              <ControlledInputField
                name="employmentDetails.abn"
                readOnly={existingCustomer && !editing}
                rules={{ required: 'ABN is required' }}
                error={(errors.employmentDetails as any)?.abn?.message as string}
              >
                ABN*
              </ControlledInputField>
            </div>
          )}
        </div>
        <hr />
        <div className="income-details">
          <ControlledDropdownWithInput
            dropdownName="employmentDetails.incomeFrequency"
            inputName="employmentDetails.incomeAmount"
            inputPrefix="$"
            readOnly={(existingCustomer && !editing) || !allowIncomeEditing}
            options={INCOME_FREQUENCIES}
            inputRules={{
              required: 'Personal income is required',
              validate: (value: any) => {
                if (isNaN(value)) {
                  return 'Personal income must be a number';
                }
              },
            }}
            error={(errors.employmentDetails as any)?.incomeAmount?.message as string}
          >
            Wages & Salary (After Tax)
          </ControlledDropdownWithInput>
          <ControlledDropdownWithInput
            dropdownName="employmentDetails.otherIncomeFrequency"
            inputName="employmentDetails.otherIncomeAmount"
            inputPrefix="$"
            readOnly={(existingCustomer && !editing) || !allowIncomeEditing}
            options={INCOME_FREQUENCIES}
            inputRules={{
              required: 'Other income is required',
              validate: (value: any) => {
                if (isNaN(value)) {
                  return 'Other income must be a number';
                }
              },
            }}
            error={(errors.employmentDetails as any)?.otherIncomeAmount?.message as string}
          >
            {OTHER_INCOME_LABEL}{' '}
            <span className="wages-and-salary">
              (Such as Centrelink, Superannuation, Rental income)
            </span>
          </ControlledDropdownWithInput>
        </div>
      </div>
    </SCEmploymentDetails>
  );
};

export default EmploymentDetails;
