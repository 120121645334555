import styled from 'styled-components';

const SCStep3 = styled.div`
  min-height: 72.5vh;

  .alert-container {
    margin-top: 2rem;
  }

  .loading-wrapper {
    margin: 0;
    position: absolute;
    top: 44.85%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .redirect-button {
    margin-top: 1em;
    margin-bottom: 2em;
  }

  .message-title {
    color: ${props => props.theme.colours.blue.base};
  }

  .message-subtitle {
    margin-top: 1em;
  }

  hr {
    border-style: solid;
    border-bottom: none;
    border-color: ${props => props.theme.colours.grey.altlight};
    margin: 2.23em 0 3em;
  }
`;

export default SCStep3;
