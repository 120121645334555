import Tooltip from 'react-tooltip-lite';
import { useFormContext } from 'react-hook-form';
import { useFeatures, FeatureFlags } from 'providers/features-provider';

import getConstants from 'util/constants';
import { IconInfo } from '@payright/web-components';
import { ControlledInputField, ControlledRange } from 'components/form-fields';
import { SCLivingExpensesSection } from './living-expenses-css';
import { useEffect, useCallback } from 'react';

type LivingExpensesProps = {
  sectionNo: number;
  hasJointAccounts: boolean;
};

const LivingExpenses = ({ sectionNo, hasJointAccounts }: LivingExpensesProps) => {
  const { errors, getValues, setValue, watch } = useFormContext();
  const { flagsConfig } = useFeatures();

  const results = watch('livingExpenses');

  const {
    LIVING_EXPENSES_CATEGORIES,
    MONTHLY_EXPENSE_MAX_VALIDATION,
    MONTHLY_EXPENSE_TOTAL_VALIDATION,
    EXPENSE_MUST_BE_NOT_NULL,
    DECIMAL_NUMBERS_VALIDATION,
  } = getConstants();

  let livingExpensesCategories = LIVING_EXPENSES_CATEGORIES;

  // PT-1035 - Remove Leisure and Entertainment category from this form and the total calculations
  if (flagsConfig[FeatureFlags.PT_1035]) {
    livingExpensesCategories = livingExpensesCategories.filter(
      category => category.value !== 'leisureEntertainmentMonthly'
    );
  }

  const totalCal = useCallback(
    results => {
      let total = 0;
      livingExpensesCategories.forEach(category => {
        let output = results[category.value];
        output = Number(output.toString().replace(',', ''));
        total = total + (Number.isNaN(output) ? 0 : output);
      });

      return new Intl.NumberFormat().format(Number(total));
    },
    [livingExpensesCategories]
  );

  useEffect(() => {
    const output = totalCal(results);
    setValue('livingExpenseTotal', output);
  }, [results, setValue, totalCal]);

  return (
    <SCLivingExpensesSection>
      <h1 className="form-section--header">{sectionNo}. Living expenses</h1>
      <div className="form-block">
        <p>
          We have calculated your monthly living expenses from the bank statements provided. Please
          adjust if any of these amounts don’t look right or you think you can easily reduce some of
          these expenses once your loan is live.
        </p>
      </div>
      <div className="form-section--form-block">
        {livingExpensesCategories.map((category, i) => (
          <div key={i} className="form-row">
            <div className="form-label">
              {category.hint ? (
                <div className="label">
                  {category.name}
                  <Tooltip content={category.hint} className="py-tooltip" direction="right">
                    <IconInfo />
                  </Tooltip>
                </div>
              ) : (
                <div className="label">{category.name}</div>
              )}
            </div>
            <div className="form-input">
              <ControlledInputField
                name={`livingExpenses.${category.value}`}
                className="category"
                readOnly={false}
                type="text"
                prefix="$"
                thousandSeparator={true}
                rules={{
                  maxLength: { value: 6, message: MONTHLY_EXPENSE_MAX_VALIDATION },
                  validate: () => {
                    if (
                      getValues(`livingExpenses.${category.value}`) === '' ||
                      parseInt(getValues(`livingExpenses.${category.value}`)) < 0
                    ) {
                      return EXPENSE_MUST_BE_NOT_NULL;
                    }
                  },
                  pattern: {
                    value: /^[0-9,\b]+$/,
                    message: DECIMAL_NUMBERS_VALIDATION,
                  },
                }}
                error={errors.livingExpenses && errors.livingExpenses[category.value]?.message}
              ></ControlledInputField>
            </div>
          </div>
        ))}
        <div className="form-row">
          <div className="form-label">
            <strong>Total</strong>
          </div>
          <div className="form-input">
            <ControlledInputField
              name={'livingExpenseTotal'}
              className="category__total"
              readOnly={true}
              type="text"
              prefix="$"
              thousandSeparator={true}
              rules={{
                validate: () => {
                  if (getValues('livingExpenseTotal') === 0)
                    return MONTHLY_EXPENSE_TOTAL_VALIDATION;
                },
              }}
              error={errors.livingExpenseTotal?.message as string}
            ></ControlledInputField>
          </div>
        </div>

        {hasJointAccounts && (
          <div className="slider-row">
            <ControlledRange
              name="livingExpenses.appliedLivingExpensePercentage"
              min={0}
              max={100}
              step={1}
            >
              <p>
                Because you have supplied a joint bank account we need to know what % of these total
                expenses <u>you</u> contribute to.
              </p>

              <p>What % of these living expenses do you contribute to?</p>
            </ControlledRange>
          </div>
        )}
      </div>
    </SCLivingExpensesSection>
  );
};

export default LivingExpenses;
