import styled from 'styled-components';

const SCStep2 = styled.div`
  min-height: 72.5vh;
  hr {
    border-style: solid;
    border-bottom: none;
    border-color: ${props => props.theme.colours.grey.altlight};
    margin: 2.23em 0 3em;
  }
  .loading-wrapper {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .submit-button {
    margin-top: 2em;
  }

  .redirect-button {
    margin-top: 1em;
  }

  .cancel-button-secondary {
    margin-top: 2em;
  }

  .invalid-validation {
    margin-top: 2em;
  }

  .approval-message {
    margin-top: 2.53em;
  }

  .payment-summary {
    margin-bottom: 3em;
  }
`;

export default SCStep2;
