import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import { envIsTrue } from 'util/helpers';
import { PayrightThemeProvider, media } from '@payright/web-components';
import { createGlobalStyle } from 'styled-components';

import App from './App';
import store from './util/store';
import * as serviceWorker from './serviceWorker';
import { ConfigProvider } from 'providers/config-provider';
import { FeaturesProvider } from 'providers/features-provider';

if (envIsTrue('REACT_APP_ENABLE_LOGGING')) {
  LogRocket.init('eutwkz/interstellar');

  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      beforeSend(event) {
        // Ignore all error 429s
        // Also, removed the 'integrations browsertracing'
        return event.message?.match(/SentryError: HTTP Error \(429\)/) ? null : event;
      },
      environment: process.env.REACT_APP_ENVIRONMENT,

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    });
  }
}

const GlobalStyle = createGlobalStyle`
  // Reduce the extra large heading sizes for smaller devices
  ${media.max.large} {
    h1 {
      font-size: 2.2em;
    }
    h2 {
      font-size: 1.93em;
    }
    h3 {
      font-size: 1.65em;
    }
  }
`;

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <PayrightThemeProvider>
        <GlobalStyle />
        <ConfigProvider>
          <FeaturesProvider>
            <App />
          </FeaturesProvider>
        </ConfigProvider>
      </PayrightThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
