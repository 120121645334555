import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'util/store';
import ByronBayApi, { PaymentMethodsResponse } from '../util/byronbay-api';
import _ from 'lodash';
import { isCardExpired } from 'util/helpers';

const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  paymentMethods: [],
};

const paymentMethodsSlice = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {
    resetPaymentMethod: () => initialState,
    startFetchingPaymentMethods: (state, { payload }) => {
      return {
        ...state,
        loading: true,
        hasErrors: false,
      };
    },
    getPaymentMethodsSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        hasErrors: false,
        paymentMethods: payload,
      };
    },
    getPaymentMethodsFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    makeCardActiveBegin: state => {
      return {
        ...state,
        loading: true,
        hasErrors: false,
      };
    },
    makeCardActiveSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        hasErrors: false,
        paymentMethods: payload,
      };
    },
    makeCardActiveFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
  },
});

export const {
  resetPaymentMethod,
  startFetchingPaymentMethods,
  getPaymentMethodsSuccess,
  getPaymentMethodsFailure,
  makeCardActiveBegin,
  makeCardActiveSuccess,
  makeCardActiveFailure,
} = paymentMethodsSlice.actions;

export default paymentMethodsSlice.reducer;

// ---------------------------------------------
// - Thunks - HANDLING THE FETCH PAYMENT METHODS
// ----------------------------------------------
export const fetchCustomerPaymentMethods = (
  checkoutId: string,
  customerId: any
): AppThunk => async dispatch => {
  const byronBayApi = new ByronBayApi();
  dispatch(startFetchingPaymentMethods({}));
  try {
    const response: PaymentMethodsResponse = await byronBayApi.getPaymentMethodsList(
      checkoutId,
      customerId
    );
    dispatch(getPaymentMethodsSuccess(response));
  } catch (err) {
    const errorMessage =
      typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined'
        ? err.response.data.message
        : 'Something went wrong when fetching payment methods';
    dispatch(
      getPaymentMethodsFailure({
        errorMessage: errorMessage,
      })
    );
  }
};

export const prepareCardDetails = (paymentMethods: any) => {
  if (_.isEmpty(paymentMethods)) return [];

  let cardList = paymentMethods.map((method: any) => {
    const expired = isCardExpired(method.cardExpiry);

    return {
      id: method.id,
      type: method.cardProvider,
      last4digits: method.cardNumber.slice(-4),
      cardExpiry: method.cardExpiry,
      expired: expired,
    };
  });

  return cardList;
};

export const getActiveCard = (paymentMethods: any) => {
  if (_.isEmpty(paymentMethods)) return '';

  const activeAndNonExpiredCard = paymentMethods.find(
    (method: any) => method.enabled === true && !isCardExpired(method.cardExpiry)
  );

  return _.isEmpty(activeAndNonExpiredCard) ? null : activeAndNonExpiredCard.id;
};

export const doMakeCardActive = (
  checkoutId: string,
  paymentMethodId: string,
  customerId: any
): AppThunk<Promise<PaymentMethodsResponse>> => async dispatch => {
  const byronBayApi = new ByronBayApi();
  try {
    dispatch(makeCardActiveBegin());
    const response: PaymentMethodsResponse = await byronBayApi.makeCardActive(
      checkoutId,
      customerId,
      paymentMethodId
    );
    dispatch(makeCardActiveSuccess(response));
    return response;
  } catch (err) {
    const errorMessage =
      typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined'
        ? err.response.data.message
        : 'Something went wrong when fetching payment methods';
    dispatch(
      makeCardActiveFailure({
        errorMessage: errorMessage,
      })
    );
    throw err;
  }
};
