import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import LogRocket from 'logrocket';
import { AppThunk } from '../util/store';
import ByronBayApi, {
  CheckoutResource,
  CurrentCheckoutResponse,
  CheckoutSuccessResponse,
  CheckoutItems,
  PreApprovalData,
  ApprovalResponse as ApprovalPayload,
  PlanStatus,
  AcceptDepositBumpingSuccessResponse,
  PlanApprovalCheckResponse,
} from '../util/byronbay-api';
import { PaymentCalculatorData } from '@payright/web-components';
import { ApplicationCompletedBy } from 'types/plan';
import { NextStep } from 'types/checkout';

import { loading, loadingFunctionInterface } from '../util/loadingFunctionInterface';
import getConstants from '../util/constants';
const { CONTACT_US_PHONE_NUMBER } = getConstants();

type InitialState = {
  loading: boolean;
  activeRequests: Array<checkoutRequestId>;
  paymentDetails: PaymentCalculatorData;
  paymentDetailsEdit?: boolean;
  checkout?: CheckoutSuccessResponse | null;
  checkoutItems?: CheckoutItems;
  checkoutValidationStatus: boolean;
  hasErrors: boolean;
  errorTitle?: string | null;
  errorMessage?: string | null;
  termsAndConditionAccepted: boolean;
  showAllAvailableTerms?: boolean;
};

export const initialState: InitialState = {
  loading: false,
  activeRequests: [],
  paymentDetails: {
    saleAmount: 1000,
    depositPercent: 10,
    paymentFrequency: 'Fortnightly',
    paymentPeriod: 12,
    establishmentFee: 29,
    depositAmount: 100,
  },
  paymentDetailsEdit: false,
  checkout: null,
  checkoutValidationStatus: false,
  hasErrors: false,
  errorTitle: null,
  errorMessage: null,
  termsAndConditionAccepted: false,
  showAllAvailableTerms: false,
};

type checkoutRequestId =
  | 'createCheckout'
  | 'attachCustomer'
  | 'updateCheckout'
  | 'getCurrentCheckout'
  | 'getCurrentCheckoutItems'
  | 'getCheckoutValidation'
  | 'runPlanPreApproval'
  | 'acceptDepositBumping'
  | 'getPlanApproval'
  | 'getPlanApprovalCheck'
  | 'updateBsoOptions'
  | 'sendVerificationCode';

const startLoading: loadingFunctionInterface<checkoutRequestId> = (
  activeRequests: Array<checkoutRequestId>,
  requestId: checkoutRequestId
): loading<checkoutRequestId> => {
  activeRequests = [...activeRequests, requestId];
  return {
    activeRequests: activeRequests,
    loading: activeRequests.length > 0,
  };
};

const finishLoading: loadingFunctionInterface<checkoutRequestId> = (
  activeRequests: Array<checkoutRequestId>,
  requestId: checkoutRequestId
): loading<checkoutRequestId> => {
  activeRequests = activeRequests.filter(item => item !== requestId);
  return {
    activeRequests: activeRequests,
    loading: activeRequests.length > 0,
  };
};

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    resetCheckout: () => initialState,
    updateSaleDetails(
      state,
      action: PayloadAction<{
        paymentDetails: PaymentCalculatorData;
      }>
    ) {
      return {
        ...state,
        paymentDetails: action.payload.paymentDetails,
      };
    },
    createCheckoutBegin: state => {
      const loadingState = startLoading(state.activeRequests, 'createCheckout');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    createCheckoutSuccess(
      state,
      action: PayloadAction<{
        checkout: CheckoutResource;
      }>
    ) {
      const loadingState = finishLoading(state.activeRequests, 'createCheckout');
      return {
        ...state,
        hasErrors: false,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        checkout: action.payload.checkout,
      };
    },
    createCheckoutFailure(state, action: PayloadAction<{ errorMessage: string }>) {
      const loadingState = finishLoading(state.activeRequests, 'createCheckout');
      return {
        ...state,
        hasErrors: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: action.payload.errorMessage,
      };
    },
    attachCustomerBegin(state) {
      const loadingState = startLoading(state.activeRequests, 'attachCustomer');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    attachCustomerSuccess(
      state,
      action: PayloadAction<{
        checkout: CheckoutResource;
      }>
    ) {
      const loadingState = finishLoading(state.activeRequests, 'attachCustomer');
      return {
        ...state,
        hasErrors: false,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        checkout: action.payload.checkout,
      };
    },
    attachCustomerFailure(state, action: PayloadAction<{ errorMessage: string }>) {
      const loadingState = finishLoading(state.activeRequests, 'attachCustomer');
      return {
        ...state,
        hasErrors: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: action.payload.errorMessage,
      };
    },
    updateCheckoutBegin: state => {
      const loadingState = startLoading(state.activeRequests, 'updateCheckout');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    updateCheckoutSuccess(state) {
      const loadingState = finishLoading(state.activeRequests, 'updateCheckout');
      return {
        ...state,
        paymentDetailsEdit: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    updateCheckoutFailure(state, action: PayloadAction<{ errorMessage: string }>) {
      const loadingState = finishLoading(state.activeRequests, 'updateCheckout');
      return {
        ...state,
        hasErrors: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: action.payload.errorMessage,
      };
    },
    getCurrentCheckoutSessionBegin(state) {
      const loadingState = startLoading(state.activeRequests, 'getCurrentCheckout');
      return {
        ...state,
        hasErrors: false,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    getCurrentCheckoutSessionSuccess(state, action: PayloadAction<CurrentCheckoutResponse>) {
      const loadingState = finishLoading(state.activeRequests, 'getCurrentCheckout');
      const { payload } = action;
      return {
        ...state,
        checkout: {
          ...state.checkout,
          ...payload,
        },
        paymentDetails: payload.attributes.paymentDetails,
        showAllAvailableTerms: payload.attributes.showAllAvailableTerms,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    getCurrentCheckoutSessionFailure(state, action: PayloadAction<{ errorMessage: string }>) {
      const loadingState = finishLoading(state.activeRequests, 'getCurrentCheckout');
      return {
        ...state,
        hasErrors: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: action.payload.errorMessage,
      };
    },
    getCheckoutValidationBegin(state) {
      const loadingState = startLoading(state.activeRequests, 'getCheckoutValidation');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: false,
        errorTitle: null,
        errorMessage: null,
      };
    },
    getCheckoutValidationSuccess(state) {
      const loadingState = finishLoading(state.activeRequests, 'getCheckoutValidation');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: false,
      };
    },
    getCheckoutValidationFailure(
      state,
      action: PayloadAction<{ errorTitle: string; errorMessage: string }>
    ) {
      const loadingState = finishLoading(state.activeRequests, 'getCheckoutValidation');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: true,
        errorTitle: action.payload.errorTitle,
        errorMessage: action.payload.errorMessage,
      };
    },
    runPlanPreApprovalBegin(state) {
      const loadingState = startLoading(state.activeRequests, 'runPlanPreApproval');
      return {
        ...state,
        hasErrors: false,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    runPlanPreApprovalSuccess: (state, action: PayloadAction<PreApprovalData>) => {
      const loadingState = finishLoading(state.activeRequests, 'runPlanPreApproval');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: false,
        checkout: {
          ...state.checkout,
          nextSteps: action.payload.nextSteps,
          preApprovalDetails: action.payload,
        },
      };
    },
    runPlanPreApprovalFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      const loadingState = finishLoading(state.activeRequests, 'runPlanPreApproval');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    acceptDepositBumpingBegin(state) {
      const loadingState = startLoading(state.activeRequests, 'acceptDepositBumping');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    acceptDepositBumpingSuccess: (
      state,
      action: PayloadAction<AcceptDepositBumpingSuccessResponse>
    ) => {
      const loadingState = finishLoading(state.activeRequests, 'acceptDepositBumping');

      const attributes = state.checkout?.attributes
        ? {
            ...state.checkout.attributes,
            depositPayable: action.payload.depositPaid,
            depositPaidPercentage: action.payload.depositPaidPercentage,
            paymentDetails: {
              ...state.checkout.attributes.paymentDetails,
              depositAmount: action.payload.depositPaid,
              depositPercent: action.payload.depositPaidPercentage,
            },
          }
        : undefined;

      const newState = {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: false,
        checkout: {
          ...state.checkout,
          nextSteps: action.payload.nextSteps,
          attributes: attributes,
        },
        paymentDetails: {
          ...state.paymentDetails,
          depositAmount: action.payload.depositPaid,
          depositPercent: action.payload.depositPaidPercentage,
        },
      };
      return newState;
    },
    acceptDepositBumpingFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      const loadingState = finishLoading(state.activeRequests, 'acceptDepositBumping');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    getPlanApprovalBegin(state) {
      const loadingState = startLoading(state.activeRequests, 'getPlanApproval');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: false,
        errorTitle: null,
        errorMessage: null,
      };
    },
    getPlanApprovalSuccess(state, action: PayloadAction<ApprovalPayload>) {
      const loadingState = finishLoading(state.activeRequests, 'getPlanApproval');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        checkout: {
          ...state.checkout,
          approvalStatus: action.payload.approvalStatus,
        },
      };
    },
    getPlanApprovalFailure(
      state,
      action: PayloadAction<{ errorTitle: string; errorMessage: string }>
    ) {
      const loadingState = finishLoading(state.activeRequests, 'getPlanApproval');
      return {
        ...state,
        hasErrors: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorTitle: action.payload.errorTitle,
        errorMessage: action.payload.errorMessage,
      };
    },
    getPlanApprovalCheckBegin(state) {
      const loadingState = startLoading(state.activeRequests, 'getPlanApprovalCheck');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: false,
        errorTitle: null,
        errorMessage: null,
      };
    },
    getPlanApprovalCheckSuccess(state) {
      const loadingState = finishLoading(state.activeRequests, 'getPlanApprovalCheck');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    getPlanApprovalCheckFailure(
      state,
      action: PayloadAction<{ errorTitle: string; errorMessage: string }>
    ) {
      const loadingState = finishLoading(state.activeRequests, 'getPlanApprovalCheck');
      return {
        ...state,
        hasErrors: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorTitle: action.payload.errorTitle,
        errorMessage: action.payload.errorMessage,
      };
    },
    updateBsoOptionsBegin: state => {
      const loadingState = startLoading(state.activeRequests, 'updateBsoOptions');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    updateBsoOptionsSuccess: (state, action: PayloadAction<{ status: string }>) => {
      const loadingState = finishLoading(state.activeRequests, 'updateBsoOptions');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: false,
      };
    },
    updateBsoOptionsFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      const loadingState = finishLoading(state.activeRequests, 'updateBsoOptions');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        hasErrors: true,
      };
    },
    setTermsAndConditionAccepted: (state, action: PayloadAction<{ accepted: boolean }>) => {
      return {
        ...state,
        termsAndConditionAccepted: action.payload.accepted,
      };
    },
    sendVerificationCodeBegin: state => {
      const loadingState = startLoading(state.activeRequests, 'sendVerificationCode');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    sendVerificationCodeSuccess(state) {
      const loadingState = finishLoading(state.activeRequests, 'sendVerificationCode');
      return {
        ...state,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    sendVerificationCodeFailure(state, action: PayloadAction<{ errorMessage: string }>) {
      const loadingState = finishLoading(state.activeRequests, 'sendVerificationCode');
      return {
        ...state,
        hasErrors: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: action.payload.errorMessage,
      };
    },
    getCurrentCheckoutItems(state) {
      const loadingState = startLoading(state.activeRequests, 'getCurrentCheckoutItems');
      return {
        ...state,
        hasErrors: false,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    getCurrentCheckoutItemsSuccess(state, action: PayloadAction<CheckoutItems>) {
      const loadingState = finishLoading(state.activeRequests, 'getCurrentCheckoutItems');
      const { payload } = action;
      return {
        ...state,
        checkout: {
          ...state.checkout,
        },
        checkoutItems: payload,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
      };
    },
    getCurrentCheckoutItemsFailure(state, action: PayloadAction<{ errorMessage: string }>) {
      const loadingState = finishLoading(state.activeRequests, 'getCurrentCheckoutItems');
      return {
        ...state,
        hasErrors: true,
        activeRequests: loadingState.activeRequests,
        loading: loadingState.loading,
        errorMessage: action.payload.errorMessage,
      };
    },
  },
});

export const {
  resetCheckout,
  updateSaleDetails,
  setTermsAndConditionAccepted,
} = checkoutSlice.actions;

const {
  createCheckoutBegin,
  createCheckoutSuccess,
  createCheckoutFailure,
  attachCustomerBegin,
  attachCustomerSuccess,
  attachCustomerFailure,
  updateCheckoutBegin,
  updateCheckoutSuccess,
  updateCheckoutFailure,
  getCurrentCheckoutSessionBegin,
  getCurrentCheckoutSessionSuccess,
  getCurrentCheckoutSessionFailure,
  runPlanPreApprovalBegin,
  getCurrentCheckoutItems,
  getCurrentCheckoutItemsSuccess,
  getCurrentCheckoutItemsFailure,
  runPlanPreApprovalSuccess,
  runPlanPreApprovalFailure,
  acceptDepositBumpingBegin,
  acceptDepositBumpingSuccess,
  acceptDepositBumpingFailure,
  getCheckoutValidationBegin,
  getCheckoutValidationSuccess,
  getCheckoutValidationFailure,
  getPlanApprovalBegin,
  getPlanApprovalSuccess,
  getPlanApprovalFailure,
  getPlanApprovalCheckBegin,
  getPlanApprovalCheckSuccess,
  getPlanApprovalCheckFailure,
  updateBsoOptionsBegin,
  updateBsoOptionsSuccess,
  updateBsoOptionsFailure,
  sendVerificationCodeBegin,
  sendVerificationCodeSuccess,
  sendVerificationCodeFailure,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;

// -------------------------
// - Thunks - FETCHING THE CHECKOUT ATTRIBUTES TO RE-INTIALISE THE CHECKOUT STATE ON PAGE REFRESH
// -------------------------
export const fetchCheckoutAttributes = (checkoutId: string): AppThunk => async dispatch => {
  try {
    dispatch(getCurrentCheckoutSessionBegin());
    const byronBayApi = new ByronBayApi();
    const currentCheckout: CurrentCheckoutResponse = await byronBayApi.getCurrentCheckout(
      checkoutId
    );
    dispatch(getCurrentCheckoutSessionSuccess(currentCheckout));
  } catch (error) {
    dispatch(getCurrentCheckoutSessionFailure(error.message));
  }
};

// -------------------------
// - Thunks - FETCHING THE CHECKOUT ATTRIBUTES TO RE-INTIALISE THE CHECKOUT STATE ON PAGE REFRESH
// -------------------------
export const fetchCheckoutItems = (checkoutId: string): AppThunk => async dispatch => {
  try {
    dispatch(getCurrentCheckoutItems());
    const byronBayApi = new ByronBayApi();
    const checkoutItems: CheckoutItems = await byronBayApi.getCurrentCheckoutItems(checkoutId);
    dispatch(getCurrentCheckoutItemsSuccess(checkoutItems));
  } catch (error) {
    dispatch(getCurrentCheckoutItemsFailure(error.message));
  }
};

// -------------------------
// - Thunks - HANDLING THE CHECKOUT CREATION
// -------------------------
export const createCheckout = ({
  paymentDetails,
  merchantReference,
  successCallback,
}: {
  paymentDetails: PaymentCalculatorData;
  merchantReference?: string;
  successCallback?: (checkoutId: string) => void;
}): AppThunk => async dispatch => {
  try {
    dispatch(createCheckoutBegin());

    const paymentFrequency = paymentDetails.paymentFrequency;

    const createCheckoutParams = {
      attributes: {
        saleAmount: paymentDetails.saleAmount,
        terms: paymentFrequency,
        depositPaidPercentage: paymentDetails.depositPercent,
        paymentPeriod: paymentDetails.paymentPeriod,
        paymentDetails: paymentDetails,
        type: '',
        expiresAt: '',
        redirectUrl: '',
      },
      merchantReference: merchantReference,
    };

    const byronBayApi = new ByronBayApi();
    const checkout: CheckoutResource = await byronBayApi.createCheckout(createCheckoutParams);

    dispatch(updateSaleDetails({ paymentDetails: paymentDetails }));

    dispatch(
      createCheckoutSuccess({
        checkout: checkout,
      })
    );
    if (successCallback && checkout.id) {
      successCallback(checkout.id);
    }
  } catch (err) {
    dispatch(createCheckoutFailure({ errorMessage: err }));
  }
};

// -------------------------
// - Thunks - UPDATE CHECKOUT
// -------------------------
/**
 * TODO:
 * Rewrite how this works so it can take as many or as few props
 * as it needs
 * We don't need to force paymentDetails or half the checkoutData
 */
export const updateCheckout = ({
  checkoutIdentifier,
  paymentDetails,
  checkoutData,
}: {
  checkoutData: any;
  paymentDetails: PaymentCalculatorData;
  checkoutIdentifier: string;
}): AppThunk<Promise<boolean>> => async dispatch => {
  try {
    dispatch(updateCheckoutBegin());

    const updateCheckoutParams = {
      attributes: {
        saleAmount: paymentDetails.saleAmount,
        terms: paymentDetails.paymentFrequency,
        depositPayable: paymentDetails.depositAmount,
        depositPaidPercentage: paymentDetails.depositPercent,
        paymentPeriod: paymentDetails.paymentPeriod,
        paymentDetails: paymentDetails,
        type: checkoutData.type,
        redirectUrl: checkoutData.redirectUrl,
        expiresAt: checkoutData.expiresAt,
        step: checkoutData.step,
      },
    };

    const byronBayApi = new ByronBayApi();
    await byronBayApi.updateCheckout(checkoutIdentifier, updateCheckoutParams);

    dispatch(updateSaleDetails({ paymentDetails: paymentDetails }));
    dispatch(updateCheckoutSuccess());
    dispatch(fetchCheckoutAttributes(checkoutIdentifier));
    return true;
  } catch (err) {
    dispatch(updateCheckoutFailure({ errorMessage: 'Update checkout failed' }));
    throw err.message;
  }
};

// -------------------------
// - Thunks - ATTACH CUSTOMER ID TO A CHECKOUT
// -------------------------
export const attachCustomer = (
  checkoutId: string,
  customerId: string,
  applicationCompletedBy: ApplicationCompletedBy
): AppThunk<Promise<string | null>> => async (dispatch, getState) => {
  const checkout = getState().checkout?.checkout || null;

  if (checkout?.planId !== null && checkout?.customerId !== null) {
    // console.log('Skip attaching');
    return checkout?.planId as string;
  }

  dispatch(attachCustomerBegin());
  LogRocket.track('Attaching Customer');

  try {
    const byronBayApi = new ByronBayApi();
    const result = await byronBayApi.customerToCheckout(
      customerId,
      checkoutId,
      applicationCompletedBy
    );
    dispatch(attachCustomerSuccess({ checkout: result }));
    LogRocket.track('Attach Customer Successful');

    // console.log('Attached: ', result);
    return result.planId;
  } catch (err) {
    dispatch(attachCustomerFailure({ errorMessage: err.message }));
    console.log(err);
    LogRocket.track(`Attaching Customer Failed - Error: ${err.message}`);
    throw new Error(err);
  }
};

// -------------------------
// - Thunks - PLAN PRE-APPROVAL CHECK
// -------------------------
export const runPlanPreApprovalCheck = ({
  checkoutId,
  isCheckoutUpdated,
  isExistingCustomer,
  onSuccessHandleRedirection,
}: {
  checkoutId: string;
  isCheckoutUpdated: boolean;
  isExistingCustomer: boolean;
  onSuccessHandleRedirection: (params: {
    nextSteps: Array<NextStep>;
    paymentDetails?: PaymentCalculatorData;
    approvedDeposit: {
      percentage: any;
      amount: any;
    };
  }) => void;
}): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(runPlanPreApprovalBegin());
    const byronBayApi = new ByronBayApi();
    const response = await byronBayApi.runPlanPreApprovalCheck(
      checkoutId,
      isCheckoutUpdated,
      isExistingCustomer
    );

    // We run this before redirecting so we update the state before getState()
    dispatch(runPlanPreApprovalSuccess(response));

    const paymentDetails = { ...getState().checkout.paymentDetails };
    const params = {
      nextSteps: response.nextSteps,
      paymentDetails,
      approvedDeposit: {
        percentage: response.approvedDepositPercentage,
        amount: response.approvedDepositAmount,
      },
    };

    onSuccessHandleRedirection(params);
  } catch (error) {
    console.log(error.message);
    dispatch(runPlanPreApprovalFailure(error.message));
  }
};

// -------------------------
// - Thunks - ACCEPT DEPOSIT BUMP AND PLAN PRE-APPROVAL
// -------------------------
export const acceptDepositBumping = ({
  checkoutId,
  depositAmount,
  depositPercentage,
  onSuccessHandleRedirection,
}: {
  checkoutId: string;
  depositAmount: number;
  depositPercentage: number;
  onSuccessHandleRedirection: (params: {
    nextSteps: Array<NextStep>;
    paymentDetails?: PaymentCalculatorData;
  }) => void;
}): AppThunk<Promise<void>> => async (dispatch, getState) => {
  try {
    dispatch(acceptDepositBumpingBegin());
    const byronBayApi = new ByronBayApi();

    const response = await byronBayApi.runAcceptPreApprovalCheck(
      checkoutId,
      depositAmount,
      depositPercentage
    );

    // We run this before redirecting so we update the state before getState()
    dispatch(acceptDepositBumpingSuccess(response));

    const paymentDetails = { ...getState().checkout.paymentDetails };
    const params = {
      nextSteps: response.nextSteps,
      paymentDetails,
    };

    onSuccessHandleRedirection(params);
  } catch (error) {
    dispatch(acceptDepositBumpingFailure(error.message));
  }
};

// -------------------------
// - Thunks - UPDATE BANK STATEMENT OPTIONS
// -------------------------
export const updateBsoOptions = ({
  checkoutId,
  provideBso,
  onSuccessHandleRedirection,
}: {
  checkoutId: string;
  provideBso: string;
  onSuccessHandleRedirection: (params: {
    nextSteps: Array<NextStep>;
    paymentDetails?: PaymentCalculatorData;
  }) => void;
}): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(updateBsoOptionsBegin());

    const byronBayApi = new ByronBayApi();
    const response = await byronBayApi.updateBsoOptions(checkoutId, provideBso);

    const paymentDetails = { ...getState().checkout.paymentDetails };
    const params = {
      nextSteps: response.nextSteps,
      paymentDetails,
    };

    onSuccessHandleRedirection(params);

    dispatch(updateBsoOptionsSuccess(response));
  } catch (error) {
    dispatch(updateBsoOptionsFailure(error.message));
  }
};

// ------------------------------
// - Thunks - Validate code
// ------------------------------
export const checkoutValidate = (
  planId: string,
  checkoutId: string,
  validationCode?: string
): AppThunk<Promise<boolean>> => async dispatch => {
  try {
    if (!validationCode) {
      getCheckoutValidationFailure({
        errorTitle: 'No validation code provided',
        errorMessage: 'Validation code is required',
      });
      return false;
    }

    const byronBayApi = new ByronBayApi();
    dispatch(getCheckoutValidationBegin());
    LogRocket.track('Verifying plan validation code');

    const currentCheckoutVerification = await byronBayApi.verifyPlanSubmission(
      planId,
      checkoutId,
      validationCode
    );

    if (currentCheckoutVerification.status === true) {
      dispatch(getCheckoutValidationSuccess());
      return true;
    } else {
      dispatch(
        getCheckoutValidationFailure({
          errorTitle: 'Invalid validation code',
          errorMessage: 'Recheck the validation code',
        })
      );
      return false;
    }
  } catch (error) {
    dispatch(
      getPlanApprovalFailure({
        errorTitle: 'Unexpected error',
        errorMessage: `There was an unexpected error. Please try again or contact our office for further assistance
        on ${CONTACT_US_PHONE_NUMBER}.`,
      })
    );
    return false;
  }
};

// ------------------------------
// - Thunks - Perform dry-run
// ------------------------------
export const checkoutCheckApprovalOutcome = (
  checkoutId: string
): AppThunk<Promise<PlanApprovalCheckResponse | void>> => async dispatch => {
  try {
    const byronBayApi = new ByronBayApi();

    dispatch(getPlanApprovalCheckBegin());

    try {
      const planApproval = await byronBayApi.planApprovalCheck(checkoutId);
      dispatch(getPlanApprovalCheckSuccess());
      LogRocket.track('Plan approval (check) complete with outcome ' + planApproval.outcome);
      return planApproval;
    } catch (error) {
      LogRocket.track('Plan approval (check) HTTP response error');

      // Try, try, try again
      const getOutcome = () => byronBayApi.planApprovalCheck(checkoutId);

      const retryMultipleTimes = (
        getOutcome: () => Promise<PlanApprovalCheckResponse>,
        retries = 6,
        delay = 5000
      ) =>
        new Promise<PlanApprovalCheckResponse>((resolve, reject) => {
          getOutcome()
            .then(result => {
              resolve(result);
              return;
            })
            .catch(error => {
              setTimeout(() => {
                if (retries === 0) {
                  reject(error);
                  return;
                }

                // Retry
                retryMultipleTimes(getOutcome, retries - 1, delay).then(resolve, reject);
              }, delay);
            });
        });

      return retryMultipleTimes(getOutcome)
        .then(result => {
          dispatch(getPlanApprovalCheckSuccess());
          LogRocket.track(
            'Plan approval (check) complete after retry with status ' + result.outcome
          );
          return result;
        })
        .catch(() => {
          LogRocket.track('Plan approval (check) did not complete after numerous retries');
          dispatch(
            getPlanApprovalCheckFailure({
              errorTitle: 'Unexpected error',
              errorMessage: `There was an unexpected error. Please try again or contact our office for further assistance
              on ${CONTACT_US_PHONE_NUMBER}.`,
            })
          );
        });
    }
  } catch (error) {
    dispatch(
      getPlanApprovalCheckFailure({
        errorTitle: 'Unexpected error',
        errorMessage: `There was an unexpected error. Please try again or contact our office for further assistance
        on ${CONTACT_US_PHONE_NUMBER}.`,
      })
    );
  }
};

// -------------------------
// - Thunks - PLAN APPROVAL
// -------------------------
export const checkoutApproval = (
  planId: string,
  checkoutId: string
): AppThunk<Promise<PlanStatus>> => async dispatch => {
  try {
    const byronBayApi = new ByronBayApi();

    dispatch(getPlanApprovalBegin());

    try {
      const planApproval = await byronBayApi.planApproval(checkoutId);
      dispatch(getPlanApprovalComplete(planApproval));
      LogRocket.track('Plan approval complete with status ' + planApproval.approvalStatus);
      return planApproval.approvalStatus;
    } catch (error) {
      LogRocket.track('Plan approval HTTP response error');
      // If planApproval fails due to timeout error or 409 error, poll the server to retrieve the latest plan status until
      // it has changed to something other than "Pending". Then run the usual flow

      const getPlanStatus = () => byronBayApi.getPlanStatus(checkoutId, planId);

      const retryMultipleTimes = (
        getPlanStatus: () => Promise<PlanStatus>,
        retries = 6,
        delay = 5000
      ) => {
        return new Promise<PlanStatus>((resolve, reject) => {
          getPlanStatus()
            .then(planStatus => {
              if (planStatus !== 'Pending') {
                resolve(planStatus);
                return;
              } else {
                throw new Error('Plan status is still in Pending state');
              }
            })
            .catch(error => {
              setTimeout(() => {
                if (retries === 0) {
                  reject(error);
                  return;
                }

                // Retry
                retryMultipleTimes(getPlanStatus, retries - 1, delay).then(resolve, reject);
              }, delay);
            });
        });
      };

      return retryMultipleTimes(getPlanStatus)
        .then(result => {
          dispatch(getPlanApprovalComplete({ approvalStatus: result }));
          LogRocket.track('Plan approval complete after retry with status ' + result);
          return result;
        })
        .catch(() => {
          LogRocket.track('Plan approval did not complete after numerous retries');
          dispatch(
            getPlanApprovalFailure({
              errorTitle: 'Unexpected error',
              errorMessage: `There was an unexpected error. Please try again or contact our office for further assistance
              on ${CONTACT_US_PHONE_NUMBER}.`,
            })
          );
          return PlanStatus.UNDEFINED;
        });
    }
  } catch (error) {
    dispatch(
      getPlanApprovalFailure({
        errorTitle: 'Unexpected error',
        errorMessage: `There was an unexpected error. Please try again or contact our office for further assistance
        on ${CONTACT_US_PHONE_NUMBER}.`,
      })
    );
    return PlanStatus.UNDEFINED;
  }
};

const getPlanApprovalComplete = (approvalPayload: ApprovalPayload): AppThunk => dispatch => {
  dispatch(getPlanApprovalSuccess(approvalPayload));

  if (approvalPayload.approvalStatus === 'Declined') {
    dispatch(
      getPlanApprovalFailure({
        errorTitle: 'Decline - Sorry we are unable to approve the application.',
        errorMessage: `Unfortunately we have been unable to approve this application based on the information
        provided. We apologise for the inconvience and thank you for thinking of us.`,
      })
    );
  }
  if (approvalPayload.approvalStatus === 'Review') {
    dispatch(
      getPlanApprovalFailure({
        errorTitle: 'Decline - Sorry we are unable to approve the application.',
        errorMessage: `Unfortunately we have been unable to approve this application based on the information
        provided. Please contact our office for further information on ${CONTACT_US_PHONE_NUMBER}.`,
      })
    );
  }
};

// -------------------------
// - Thunks - PLAN CANCELLATION
// -------------------------
export const checkoutCancellation = (checkoutId: string): AppThunk => async dispatch => {
  try {
    const byronBayApi = new ByronBayApi();

    try {
      // Initiate plan cancellation, with given checkout id'
      await byronBayApi.planCancellation(checkoutId);

      // Clear all local storage upon plan cancellation
      localStorage.removeItem('authToken');
      localStorage.removeItem('customerId');
    } catch (error) {
      dispatch(
        getPlanApprovalFailure({
          errorTitle: 'Plan cancellation failed',
          errorMessage: `We were unable to cancel your plan. Please try again or contact our office for further assistance
            on ${CONTACT_US_PHONE_NUMBER}.`,
        })
      );
    }
  } catch (error) {
    dispatch(
      getPlanApprovalFailure({
        errorTitle: 'Unexpected error',
        errorMessage: `There was an unexpected error. Please try again or contact our office for further assistance
        on ${CONTACT_US_PHONE_NUMBER}.`,
      })
    );
  }
};

// -------------------------
// - Thunks - PLAN CANCELLATION THEN REDIRECT
// -------------------------
export const checkoutCancellationThenRedirect = (
  checkoutId: string,
  redirectBaseUrl: string
): AppThunk => async dispatch => {
  try {
    const byronBayApi = new ByronBayApi();

    try {
      // Initiate plan cancellation, with given checkout id'
      await byronBayApi.planCancellation(checkoutId);

      // Clear all local storage upon plan cancellation
      localStorage.removeItem('authToken');
      localStorage.removeItem('customerId');

      const seperator = redirectBaseUrl.indexOf('?') !== -1 ? '&' : '?';
      // Redirect customer back to where they came from
      window.location.replace(
        redirectBaseUrl + seperator + `checkoutId=${checkoutId}&status=CANCEL`
      );
    } catch (error) {
      dispatch(
        getPlanApprovalFailure({
          errorTitle: 'Plan cancellation failed',
          errorMessage: `We were unable to cancel your plan. Please try again or contact our office for further assistance
            on ${CONTACT_US_PHONE_NUMBER}.`,
        })
      );
    }
  } catch (error) {
    dispatch(
      getPlanApprovalFailure({
        errorTitle: 'Unexpected error',
        errorMessage: `There was an unexpected error. Please try again or contact our office for further assistance
        on ${CONTACT_US_PHONE_NUMBER}.`,
      })
    );
  }
};

// -------------------------
// - Thunks - SEND VERIFICATION CODE SMS
// -------------------------
export const sendVerificationCode = (
  checkoutId: string
): AppThunk<Promise<void>> => async dispatch => {
  const byronBayApi = new ByronBayApi();
  dispatch(sendVerificationCodeBegin());

  try {
    await byronBayApi.sendVerificationCode(checkoutId);
    dispatch(sendVerificationCodeSuccess());
  } catch (e) {
    dispatch(sendVerificationCodeFailure({ errorMessage: e.message }));
  }
};
