import styled from 'styled-components';
import { media } from '@payright/web-components';

const SCLanding = styled.div`
  background-color: white;
  margin: 0 20px;

  ${media.min.medium} {
    margin: 0px;
  }

  button {
    margin: 0 auto;
  }

  ${media.min.medium} {
    height: 100vh;
    min-height: 600px;
    display: flex;
    flex-flow: row-reverse;
    align-items: center;
    justify-content: center;

    button {
      margin: 0 0;
    }

    .login-or-signup {
      height: 100%;
      flex-basis: 50%;

      background-color: white;
      padding: 0 20px;

      // Align the contents of its children
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .what-is-payright {
      flex-basis: 50%;
      padding: 0 20px;
      max-width: 450px;
    }
  }

  ${media.min.tablet} {
    .login-or-signup,
    .what-is-payright {
      max-width: 550px;
    }

    .login-or-signup {
      padding: 0 35px;
    }

    .what-is-payright {
      padding: 0 70px;
    }
  }
`;

export default SCLanding;
