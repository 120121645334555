import React, { useEffect, useState, useCallback, useRef } from 'react';
import Tooltip from 'react-tooltip-lite';
import { IconInfo } from '@payright/web-components';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { media, IconGreenCheckIco } from '@payright/web-components';
import { ControlledInputField, ControlledDropdown, ControlledDatePicker } from '../form-fields';
import { CustomerDetailsData as MerchantApiCustomerDetailsData } from '../../types/customer';
import { calculateAgeLuxon, envIsTrue } from 'util/helpers';
import getConstants from 'util/constants';

const {
  CUSTOMER_TITLES,
  DOB_YEARS,
  MOBILE_VALIDATION_REGEX,
  MOBILE_NUMBER_FORMAT_VALIDATION,
  NONEDITABLE_FIELD_MESSAGE,
} = getConstants();

export type CustomerDetailsData = MerchantApiCustomerDetailsData;

export type CustomerDetailsProps = {
  existingCustomer: boolean;
  readOnly: boolean;
};

const SCCustomerDetails = styled.div`
  .details-body {
    display: flex;
    flex-wrap: wrap;
  }

  .py-tooltip {
    display: inline-block;
    vertical-align: middle;
    svg {
      height: 0.9em;
      width: 0.9em;
    }
  }

  ${media.max.medium} {
    .input-field,
    .dropdown {
      width: 100%;
      max-width: 100%;
    }
    .title-firstname-wrapper {
      width: 100%;
      & > div:first-of-type {
        margin-bottom: 1.33em;
      }
    }
    .date-picker {
      width: 100%;
      margin-bottom: 1.5em;
    }
    .details-body > div,
    .input-field {
      margin-bottom: 1.46em;
    }
  }

  ${media.min.medium} {
    .title-firstname-wrapper {
      flex: 50%;
      padding-right: 0;
      display: flex;
      .title {
        margin-right: 0.75em;
      }
      .first-name {
        margin: 0;
      }
      .middle-name {
        margin-left: 0.75em;
        margin-right: 0.75em;
      }
    }

    .title-lastname-wrapper {
      flex: 50%;
      display: flex;
    }

    .last-name {
      margin-left: 0.75em;
    }

    .email {
      margin-right: 0.75em;
    }
    .email-confirm {
      margin-left: 0.75em;
    }

    .phone {
      width: calc(50% - 0.75em);
      margin-right: 0.75em;
    }
    .phone2 {
      width: 100%;
      margin-left: 0.75em;
    }
    .date-picker {
      width: calc(50% - 0.75em);
    }

    .input-field {
      flex: calc(50% - 0.75em);
      margin-bottom: 2.46em;
    }

    hr {
      &#details-hr {
        margin-top: 2.27em;
        margin-bottom: 4.4em;
      }
      margin-top: 1.15em;
      margin-bottom: 2em;
    }

    .passwordMessage {
      bottom: 0;
      text-align: left;
      font-size: 0.86em;
      margin-top: -1.5em;
      max-width: 320px;
      width: 320px;
      li {
        padding-bottom: 0.5em;
      }
      ${media.min.large} {
        max-width: 500px;
        width: 500px;
      }
    }
  }
`;

const CustomerDetails = ({ readOnly, existingCustomer }: CustomerDetailsProps) => {
  const [liStyle1, setLiStyle1] = useState({ color: 'red' });
  const [liStyle2, setLiStyle2] = useState({ color: 'red' });
  const [liStyle3, setLiStyle3] = useState({ color: 'red' });
  const [liStyle4, setLiStyle4] = useState({ color: 'red' });

  const { watch, errors } = useFormContext<{
    customerDetails: CustomerDetailsData;
  }>();

  const password: string = watch('customerDetails.password');

  const passwordCheck = useCallback(() => {
    const hasMin8Chars = new RegExp('^(?=.{8,})');
    const hasUppercase = new RegExp('^(?=.*[A-Z])');
    const hasLowercase = new RegExp('^(?=.*[a-z])');
    const hasSpecialChar = new RegExp('^(?=.*[!@#$%^&*])');

    if (hasMin8Chars.test(password)) {
      setLiStyle1({ color: 'green' });
    } else {
      setLiStyle1({ color: 'red' });
    }

    if (hasUppercase.test(password)) {
      setLiStyle2({ color: 'green' });
    } else {
      setLiStyle2({ color: 'red' });
    }

    if (hasLowercase.test(password)) {
      setLiStyle3({ color: 'green' });
    } else {
      setLiStyle3({ color: 'red' });
    }

    if (hasSpecialChar.test(password)) {
      setLiStyle4({ color: 'green' });
    } else {
      setLiStyle4({ color: 'red' });
    }
  }, [password]);

  const sectionRef = useRef<HTMLDivElement>(null);

  // Scroll to block (by ID) when user submits and validation fails
  useEffect(() => {
    if (Object.keys(errors).length > 0 && errors.customerDetails && sectionRef.current) {
      // check for null and focus target on block with errors
      sectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [errors]); // Errors object changes on submit

  useEffect(() => {
    passwordCheck();
  }, [passwordCheck]);

  return (
    <SCCustomerDetails>
      <div ref={sectionRef} style={{ position: 'relative', top: '-2em' }} />
      <div className="details-body">
        <div className="title-firstname-wrapper">
          <ControlledDropdown
            name="customerDetails.title"
            rules={{ required: 'Required' }}
            className="title"
            options={CUSTOMER_TITLES}
            readOnly={readOnly}
            maxWidth="22%"
            error={errors.customerDetails?.title?.message}
          >
            Title *
          </ControlledDropdown>
          <ControlledInputField
            name="customerDetails.firstName"
            rules={{
              required: 'First name is required',
              pattern: {
                value: /[A-Za-z]/i,
                message: 'Enter a valid First Name',
              },
            }}
            readOnly={readOnly}
            className="first-name"
            error={errors.customerDetails?.firstName?.message}
          >
            First Name *
          </ControlledInputField>
          <ControlledInputField
            name="customerDetails.middleName"
            rules={{
              pattern: {
                value: /[A-Za-z]/i,
                message: 'Enter a valid middle name',
              },
            }}
            className="middle-name"
            readOnly={readOnly}
            error={errors.customerDetails?.middleName?.message}
          >
            Middle Name
          </ControlledInputField>
        </div>
        <div className="title-firstname-wrapper">
          <ControlledInputField
            name="customerDetails.lastName"
            rules={{
              required: 'Last name is required',
              pattern: {
                value: /[A-Za-z]/i,
                message: 'Enter a valid last name',
              },
            }}
            className="last-name"
            readOnly={readOnly}
            error={errors.customerDetails?.lastName?.message}
          >
            Last Name *
          </ControlledInputField>
        </div>
        <ControlledInputField
          name="customerDetails.email"
          rules={{
            required: 'Email is required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i,
              message: 'Enter a valid email address',
            },
          }}
          className="email"
          readOnly={readOnly}
          error={errors.customerDetails?.email?.message}
        >
          Email *
        </ControlledInputField>
        {!readOnly && (
          <ControlledInputField
            name="customerDetails.confirmEmail"
            rules={{
              required: 'Email confirmation is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i,
                message: 'Enter a valid email address',
              },
              validate: (value: any) =>
                value === watch('customerDetails.email') || 'Email needs to match',
            }}
            className="email-confirm"
            readOnly={readOnly}
            error={errors.customerDetails?.confirmEmail?.message}
          >
            Confirm Email *
          </ControlledInputField>
        )}
        <ControlledInputField
          name="customerDetails.phone"
          rules={{
            required: 'Phone number is required',
            pattern: envIsTrue('REACT_APP_SKIP_CUSTOMER_MOBILE_VALIDATION')
              ? undefined
              : {
                  value: MOBILE_VALIDATION_REGEX,
                  message: MOBILE_NUMBER_FORMAT_VALIDATION,
                },
          }}
          className={existingCustomer ? 'phone2' : 'phone'}
          readOnly={existingCustomer}
          error={errors.customerDetails?.phone?.message}
        >
          Mobile No. *
          {!readOnly && (
            <Tooltip content={NONEDITABLE_FIELD_MESSAGE} className="py-tooltip">
              <IconInfo />
            </Tooltip>
          )}
        </ControlledInputField>
        <ControlledDatePicker
          name="customerDetails.dateOfBirth"
          rules={{
            required: 'Date of Birth is required',
            validate: (value: any) => calculateAgeLuxon(value) || 'Must be at least 18 years old',
          }}
          readOnly={readOnly}
          startYear={new Date().getFullYear() - 18}
          endYear={new Date().getFullYear() - 18 - DOB_YEARS}
          error={errors.customerDetails?.dateOfBirth?.message}
        >
          Date of Birth *
        </ControlledDatePicker>
        {/* If the customer record is not in SugarCRM yet (i.e not an existing customer) ,
         * the user will be prompted to enter their password as well. */}
        {!existingCustomer && (
          <>
            <ControlledInputField
              name="customerDetails.password"
              placeholder=""
              type="password"
              rules={{
                required: 'Password is required',
                minLength: 8,
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*[\]"';:_\-<>., =+/\\]).{8,}$/,
                  message: 'Password strength does not meet the requirements',
                },
              }}
              className="email"
              readOnly={existingCustomer}
              error={errors.customerDetails?.password?.message}
            >
              Password *
            </ControlledInputField>
            <ControlledInputField
              name="customerDetails.confirmPassword"
              placeholder=""
              type="password"
              rules={{
                required: 'Password confirmation is required',
                validate: (value: any) =>
                  value === watch('customerDetails.password') || 'Password needs to match',
              }}
              className="email-confirm"
              readOnly={existingCustomer}
              error={errors.customerDetails?.confirmPassword?.message}
            >
              Confirm Password *
            </ControlledInputField>
            <div className="passwordMessage">
              <ul>
                <li style={liStyle1}>
                  {liStyle1.color === 'green' ? <IconGreenCheckIco /> : '- '}Minimum 8 characters
                </li>
                <li style={liStyle2}>
                  {liStyle2.color === 'green' ? <IconGreenCheckIco /> : '- '}1 upper case letter
                </li>
                <li style={liStyle3}>
                  {liStyle3.color === 'green' ? <IconGreenCheckIco /> : '- '}1 lower case letter
                </li>
                <li style={liStyle4}>
                  {liStyle4.color === 'green' ? <IconGreenCheckIco /> : '- '}1 special character
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    </SCCustomerDetails>
  );
};

export default CustomerDetails;
