import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

// UTILS
import { useQuery } from '../../util/hooks';
import getConstants from '../../util/constants';
import { CheckoutStatus } from '../../util/byronbay-api';
import { CheckoutType } from '../../types/checkout';
import { PlanResultType } from '../../types/app';

// REDUX AND SLICES
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { fetchCheckoutAttributes, fetchCheckoutItems } from '../../reducers/checkout-slice';
import { fetchMerchantData } from '../../reducers/auth-slice';
import { fetchExistingData } from '../../reducers/customer-slice';
import { useConfig } from 'providers/config-provider';
import { SiftDecisionType } from 'types/sift-decision';

// WEB COMPONENTS
import {
  IconTickAlt,
  ImageWithIcon,
  IconFace01,
  IconFace02,
  IconMegaSearch,
  Button,
  IconNext,
  styled,
} from '@payright/web-components';

// OTHER COMPONENTS
import CenterContentLayout from '../../layouts/center-content-layout';
import ButtonTimer from '../../components/timer';

// STYLES  AND STYLED COMPONENTS
import SCPlanResult, { SCItemCard } from './css';

// IMAGES
import loginImage from '../login/login-image.png';

// moment
import moment from 'moment';

const {
  CONTACT_US_PHONE_NUMBER,
  APPLICATIONS_EMAIL,
  CARE_EMAIL,
  HEADER_MESSAGE_REVIEW,
  REDIRECT_URL,
  REVIEW_MESSAGE,
} = getConstants();

const renderPlanResultMessage = ({
  icon,
  header,
  message,
  action,
  customerNumber,
  planNumber,
}: {
  icon: React.ReactNode;
  header: string;
  message: React.ReactNode;
  action: React.ReactNode;
  customerNumber?: string | null;
  planNumber?: string | null;
}) => (
  <SCPlanResult>
    {icon && <div className="icon">{icon}</div>}
    {header && (
      <div className="header">
        <div className="header__title">
          <h4>{header}</h4>
        </div>
      </div>
    )}
    {message && <div className="message">{message}</div>}
    {customerNumber && (
      <div className="message">
        <p>
          Your Customer Number is <span className="bold">{customerNumber}</span>
        </p>
      </div>
    )}
    {planNumber && (
      <div className="message">
        <p>
          Your Plan Number is <span className="bold">{planNumber}</span>
        </p>
      </div>
    )}
    {action && <div className="actions">{action}</div>}
  </SCPlanResult>
);

const formatItemDate = (date: string) => {
  return moment(date).format('DD-MM-YYYY');
};

const PlanResult = (props: any) => {
  const dispatch = useDispatch();
  const location: any = useLocation();
  const history = useHistory();
  const applicationResult: PlanResultType = location.state?.planResultType;

  // URL QUERY PARAMS
  const query = useQuery();
  const queryParamCheckoutId = query.get('checkoutId');

  // GlOBAL STATE
  const globalAuthState = useSelector((state: RootState) => state.auth);
  const globalCheckoutState = useSelector((state: RootState) => state.checkout);
  const globalPaymentState = useSelector((state: RootState) => state.payment);
  const globalPaymentMethodsState = useSelector((state: RootState) => state.paymentMethods);

  // VARIABLES
  const depositTaker = globalAuthState.storeConfig?.depositTaker || false;
  const customerId = globalCheckoutState.checkout?.customerId || globalAuthState.customerId;
  const checkoutId = globalCheckoutState.checkout?.id || queryParamCheckoutId || '';
  const checkoutType = globalCheckoutState.checkout?.attributes?.type;
  const checkoutStatus = globalCheckoutState.checkout?.status;
  const paymentStatus = globalPaymentState.payment.status;
  const applicationCompletedBy = globalCheckoutState.checkout?.applicationCompletedBy;
  const paymentMethods = globalPaymentMethodsState.paymentMethods;
  const checkoutItems = globalCheckoutState.checkoutItems || [];
  const siftDecision = globalPaymentState.siftDecision;

  // Loading states
  const loadingCheckout = globalCheckoutState.loading;

  // Redirect URL
  const redirectUrl = globalCheckoutState.checkout?.attributes?.redirectUrl;

  // SCROLL TO TOP ON PAGE LOAD
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // LOAD EXISTING CUSTOMER DETAILS, PAYMENT METHODS, AND MERCHANT DATA
  useEffect(() => {
    if (customerId && checkoutId) {
      dispatch(fetchExistingData(checkoutId, customerId));
      dispatch(fetchCheckoutAttributes(checkoutId));
      dispatch(fetchCheckoutItems(checkoutId));
      dispatch(fetchMerchantData(checkoutId));
    }
  }, [customerId, queryParamCheckoutId, checkoutId, dispatch]);

  // Show relevant page based on plan status
  useEffect(() => {
    if (
      !loadingCheckout &&
      checkoutStatus === 'approved' &&
      (checkoutType === 'standard' ||
        checkoutType === 'applyAndPay' ||
        applicationCompletedBy === 'Customer' ||
        (checkoutType === 'pre-approval' && !paymentMethods.length))
    ) {
      history.push('/payment-plan/payment' + history.location.search);
    }
  }, [checkoutStatus]);

  // Plan Result Status Check
  const planResultType = (): PlanResultType => {
    // result override - provided by redirect
    if (applicationResult && Object.values(PlanResultType).includes(applicationResult)) {
      return applicationResult;
    }

    // Plan is quick links quotes and payment does not collect
    // customer is on first step of quick links quote
    if (
      checkoutType === CheckoutType.PRE_APPROVAL &&
      paymentStatus !== 'success' &&
      checkoutStatus === CheckoutStatus.APPROVED_PRE_APPROVAL_CARD_DETAILS_TAKEN
    ) {
      return PlanResultType.PRE_APPROVAL;
    }

    // plan is declined
    if (checkoutStatus === CheckoutStatus.DECLINED || checkoutStatus === CheckoutStatus.CANCELLED) {
      if (siftDecision === SiftDecisionType.DECLINED) {
        return PlanResultType.SIFT_DECLINED;
      }
      return PlanResultType.DECLINED;
    }

    // Plan is in review for customer led plans, does not matter if CCCFA flag is on or off
    if (checkoutStatus === CheckoutStatus.REVIEW) {
      return PlanResultType.REVIEW;
    }

    // approved_store_deposit
    // Plan is approved, checkout status is complete and merchant is deposit taker.
    // does not matter if CCCFA flag is on or off
    if (
      (checkoutStatus === CheckoutStatus.APPROVED || checkoutStatus === CheckoutStatus.COMPLETE) &&
      depositTaker === true
    ) {
      return PlanResultType.APPROVED_STORE_DEPOSIT;
    }

    // approved_paid
    // Plan is approved, checkout status is complete and merchant is application completer.
    // does not matter if CCCFA flag is on or off
    if (
      (checkoutStatus === CheckoutStatus.APPROVED || checkoutStatus === CheckoutStatus.COMPLETE) &&
      depositTaker === false
    ) {
      return PlanResultType.APPROVED_PAID;
    }

    // default status
    return PlanResultType.REVIEW;
  };

  const planResultMessages = {
    [PlanResultType.APPROVED_PAID]: {
      icon: <ImageWithIcon image={loginImage} icon={<IconTickAlt />} />,
      header: 'Your plan is approved!',
      message: (
        <>
          <p>
            Great news, your plan is approved! Be sure to check the info on fees and repayments that
            we’ve emailed to you.
          </p>
          <div className="item-card-list">
            {Array.isArray(checkoutItems) &&
              checkoutItems.map(item => {
                return (
                  <SCItemCard>
                    <div className="item-card">
                      <span className="item-quantity">
                        {item.quantity > 999 ? '999+' : item.quantity}
                      </span>
                      <div className="meta">
                        <img className="photo" src={item.productImage} alt="productImage" />
                      </div>
                      <div className="item-details">
                        <h4>{item.productTitle}</h4>
                        <p className="item-description">{item.productDescription}</p>
                        {item.originalPrice !== '-' &&
                        item.discountedPrice !== '-' &&
                        item.discountedPrice < item.originalPrice ? (
                          <>
                            <p className="item-price strikethrough">
                              Price:{' '}
                              <span className="strikethrough">
                                ${item.originalPrice.toFixed(2)}
                              </span>{' '}
                              ${item.discountedPrice.toFixed(2)}
                            </p>
                          </>
                        ) : (
                          <p className="item-price">Price: ${item.originalPrice}</p>
                        )}
                        <div className="item-footer">
                          <div className="item-order-receipt">
                            <span>Order Receipt: {item.orderReceiptNumber}</span>
                          </div>
                          <div className="item-order-date">
                            <span>Order Date: {formatItemDate(item.orderDate)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SCItemCard>
                );
              })}
          </div>
        </>
      ),
      action: redirectUrl ? (
        <ButtonTimer seconds={10} checkoutId={checkoutId} redirectUrl={redirectUrl} />
      ) : (
        <ReturnHomeButton />
      ),
    },
    [PlanResultType.APPROVED_STORE_DEPOSIT]: {
      icon: <ImageWithIcon image={loginImage} icon={<IconTickAlt />} />,
      header: 'Plan has been arranged!',
      message: (
        <>
          <p>Please contact the Merchant to make your deposit and finalise your payment plan.</p>
        </>
      ),
      action: redirectUrl ? (
        <ButtonTimer seconds={10} checkoutId={checkoutId} redirectUrl={redirectUrl} />
      ) : (
        <ReturnHomeButton />
      ),
    },
    [PlanResultType.PRE_APPROVAL]: {
      icon: <ImageWithIcon image={loginImage} icon={<IconTickAlt />} />,
      header: 'Your plan application has been started',
      message: (
        <>
          <p>Congratulations, your plan has been pre-approved</p>
          <div className="next-steps">
            So what's next?
            <ol>
              <li>
                When the work has been complete, or your items have been received, you will receive
                an email with a link to pay your deposit, and complete your plan application.
              </li>
              <li>
                Once approved, you will receive your repayment schedule and begin paying off your
                Payright plan in bite-size instalments.
              </li>
            </ol>
          </div>
        </>
      ),
      action: redirectUrl ? (
        <ButtonTimer seconds={10} checkoutId={checkoutId} redirectUrl={redirectUrl} />
      ) : (
        <ReturnHomeButton />
      ),
    },
    [PlanResultType.DECLINED]: {
      icon: <IconFace01 height="150" />,
      header: 'Unfortunately, your application can’t be approved at this time.',
      message: (
        <>
          <p>
            Based on our assessment of your application, you’re currently not eligible for a
            Payright plan.
          </p>
          <p>
            More info on our lending decision can be found here:{' '}
            <a href="https://payright.com.au/12837-2/" style={{ textDecoration: 'none' }}>
              https://payright.com.au/12837-2/
            </a>
          </p>
        </>
      ),
      action: redirectUrl ? (
        <ButtonTimer seconds={10} checkoutId={checkoutId} redirectUrl={redirectUrl} />
      ) : (
        <ReturnHomeButton />
      ),
    },
    [PlanResultType.SIFT_DECLINED]: {
      icon: <IconFace01 height="150" />,
      header: "Unfortunately, your application can't be approved this time.",
      message: (
        <>
          <div>
            <p>
              Based on your assessment of your application, you're currently not eligible for a
              Payright plan.
            </p>
            <p>
              More information on our lending decision can be found here{' '}
              <a href="http://payright.com.au/12837-2/" target="_blank">
                http://payright.com.au/12837-2/
              </a>
            </p>
          </div>
        </>
      ),
      action: redirectUrl ? (
        <ButtonTimer seconds={10} checkoutId={checkoutId} redirectUrl={redirectUrl} />
      ) : (
        <ReturnHomeButton />
      ),
    },
    [PlanResultType.BSO_NOT_AVAILABLE]: {
      icon: <IconFace01 height="150" />,
      header: 'Application Cancelled',
      message: (
        <div>
          <p>Sorry, but we're unable to proceed with your application at this time.</p>
          <p>
            Unfortunately, there seems to be a problem with your financial institution sending us
            your account transaction data. Please contact our office for further information on{' '}
            {CONTACT_US_PHONE_NUMBER}.
          </p>
        </div>
      ),
      action: redirectUrl ? (
        <ButtonTimer seconds={30} checkoutId={checkoutId} redirectUrl={redirectUrl} />
      ) : (
        <ReturnHomeButton />
      ),
    },
    [PlanResultType.BSO_NOT_VALID]: {
      icon: <IconFace02 height="150" />,
      header: 'Review - Your application is in review.',
      message: (
        <div>
          <p>
            The account data you’ve provided appears not to contain any income. As such your
            application can’t be progressed.
          </p>
          <p>
            Our Customer Support Team will be in contact with you in the next few days to discuss
            steps to move forward with your application.
          </p>
          <p>
            Alternatively, you can reach out to them directly at{' '}
            <a href={`mailto:${CARE_EMAIL}`}>{CARE_EMAIL}</a>
          </p>
        </div>
      ),
      action: redirectUrl ? (
        <ButtonTimer seconds={30} checkoutId={checkoutId} redirectUrl={redirectUrl} />
      ) : (
        <ReturnHomeButton />
      ),
    },
    [PlanResultType.REVIEW]: {
      icon: <IconFace02 height="150" />,
      header: HEADER_MESSAGE_REVIEW,
      message: REVIEW_MESSAGE,
      action: redirectUrl ? (
        <ButtonTimer seconds={10} checkoutId={checkoutId} redirectUrl={redirectUrl} />
      ) : (
        <ReturnHomeButton />
      ),
    },
    [PlanResultType.APPROVED_STATEMENT_REQUESTED]: {
      icon: <IconMegaSearch width={60} height={60} />,
      header: 'Conditionally Approved - Bank Statements Required',
      message: (
        <>
          <div className="approved-pending-statements">
            <p>
              You’re almost there! As a final step, we will review a copy of your bank statements so
              we can assess if this Payment Plan is suitable for you.
            </p>{' '}
            <p>
              Please email a copy of your bank statements from the last 90 days to{' '}
              <a href={`mailto:${APPLICATIONS_EMAIL}`}>{APPLICATIONS_EMAIL}</a>
            </p>{' '}
            <p>
              Please ensure you provide statements for all bank accounts that contain income or
              expenses.
            </p>
          </div>
        </>
      ),
      action: redirectUrl ? (
        <ButtonTimer seconds={10} checkoutId={checkoutId} redirectUrl={redirectUrl} />
      ) : (
        <ReturnHomeButton />
      ),
    },
  };

  return (
    <CenterContentLayout>
      {!loadingCheckout && renderPlanResultMessage(planResultMessages[planResultType()])}
    </CenterContentLayout>
  );
};

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ReturnHomeButton = () => {
  const redirectHome = () => window.location.replace(REDIRECT_URL);

  return (
    <ButtonWrapper>
      <Button type="submit" handleClick={redirectHome} icon={<IconNext />}>
        Return
      </Button>
    </ButtonWrapper>
  );
};

export default PlanResult;
