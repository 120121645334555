import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';

import { RootState } from '../../reducers';
import { AppDispatch } from 'util/store';
import getConstants from 'util/constants';
import { clearErrors, doPayment, doUpdatePaymentStatus } from '../../reducers/payment-slice';
import { doMakeCardActive, getActiveCard } from '../../reducers/payment-methods-slice';
import { doUpdatePaymentDate } from '../../reducers/customer-slice';

import PaymentDetails from 'components/payment-details-new';
import PaymentStartDate from 'components/payment/payment-start-date';
import ConfirmationMessage from './confirmation-message';
import SaveButton from './save-button';
import { shouldUpdatePaymentStatus } from '../../pages/step-3';
import { CheckoutStep, CheckoutType } from '../../types/checkout';

type FatZebraFormProps = {
  iframeUrl: string;
};

type FormData = {
  selectedPaymentDate: Date | null;
  selectedMonthlyDate: string | null;
};

const { REDIRECT_URL } = getConstants();

const FatZebraForm = ({ iframeUrl }: FatZebraFormProps) => {
  const dispatch: AppDispatch = useDispatch();

  const checkout = useSelector((state: RootState) => state.checkout);
  const customer = useSelector((state: RootState) => state.customer);
  const repeatCustomer = customer?.accountDetails?.isReturnCustomer;
  const { loading: loadingPayment } = useSelector((state: RootState) => state.payment);
  const { paymentMethods } = useSelector((state: RootState) => state.paymentMethods);
  const { storeConfig } = useSelector((state: RootState) => state.auth);

  const checkoutId = checkout.checkout?.id || '';
  const planId = checkout.checkout?.planId || '';
  const customerId = checkout.checkout?.customerId || '';
  const redirectUrl = checkout.checkout?.attributes?.redirectUrl || REDIRECT_URL;
  const checkoutType = checkout.checkout?.attributes?.type || CheckoutType.UNDEFINED;
  const checkoutStep = checkout.checkout?.attributes?.step || CheckoutStep.UNDEFINED;

  const depositAmount = checkout.checkout?.attributes?.depositPayable || 0;
  const depositTaker = storeConfig?.depositTaker || false;

  const activeCardId = getActiveCard(paymentMethods);
  const [selectedCardId, setSelectedCardId] = useState<string>(activeCardId);
  const [clickChangeCard, setClickChangeCard] = useState<boolean>(false);
  const [mode, setMode] = useState<'add' | 'existing'>('add');

  const [hasValidCardSelected, setHasValidCardSelected] = useState<boolean>(
    activeCardId ? true : false
  );

  const handleCardChange = (cardId: string) => {
    setSelectedCardId(cardId);
    setHasValidCardSelected(true);
    setClickChangeCard(true);
  };

  useEffect(() => {
    if (activeCardId) {
      setSelectedCardId(activeCardId);
      setClickChangeCard(true);
    }
  }, [activeCardId, setSelectedCardId, setClickChangeCard]);

  useEffect(() => {
    if (Object.keys(paymentMethods).length === 0) {
      setMode('add');
    } else {
      setMode('existing');
    }
  }, [paymentMethods]);

  const formSubmitHandler = async (formData: any) => {
    try {
      dispatch(clearErrors());
      if (mode === 'existing' && clickChangeCard) {
        await dispatch(doMakeCardActive(checkoutId, selectedCardId, customerId));
      }

      if (!depositTaker && depositAmount > 0) {
        await dispatch(doPayment(checkoutId, customerId, planId));
      }

      // if shouldUpdatePaymentStatus returns true then only we will update the payment status
      if (shouldUpdatePaymentStatus(checkoutType, checkoutStep)) {
        dispatch(doUpdatePaymentStatus(planId, checkoutId, depositAmount, depositTaker));
      }

      /* Only set the payment start date for new customers */
      if (repeatCustomer === false) {
        const formattedDate = formData.selectedPaymentDate
          ? moment(formData.selectedPaymentDate).format('YYYY-MM-DD')
          : formData.selectedMonthlyDate;

        const paymentDateData = {
          checkoutId,
          paymentDetails: {
            paymentStartDate: formattedDate,
          },
        };
        dispatch(doUpdatePaymentDate(checkoutId, customerId, paymentDateData));
      }
    } catch (error) {
      // error messages are dispatched via redux
    }
  };

  const { handleSubmit } = useFormContext<FormData>();

  return (
    <form onSubmit={handleSubmit(formSubmitHandler)}>
      <PaymentDetails
        provider="fat-zebra"
        onChange={handleCardChange}
        selectedCardId={selectedCardId}
        mode={mode}
        setMode={setMode}
        iframeUrl={iframeUrl}
      />

      {mode === 'existing' && (
        <>
          <PaymentStartDate />
          <hr />
          <ConfirmationMessage depositAmount={depositAmount} depositTaker={depositTaker} />
          <SaveButton
            loading={loadingPayment}
            depositAmount={depositAmount}
            depositTaker={depositTaker}
            mode={mode}
            checkoutId={checkoutId}
            redirectUrl={redirectUrl}
            checkoutType={checkoutType}
            checkoutStep={checkoutStep}
            hasValidCardSelected={hasValidCardSelected}
          />
        </>
      )}
    </form>
  );
};

export default FatZebraForm;
