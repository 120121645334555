import React, { useState } from 'react';
import { ControlledCheckbox, ControlledInputField } from 'components/form-fields';
import Tooltip from 'react-tooltip-lite';
import { SCIncomeSection } from './total-monthly-income-css';
import { useFormContext } from 'react-hook-form';
import getConstants from 'util/constants';
import { CustomerLivingExpeseIncomeData } from 'util/byronbay-api';
import { Range, IconInfo } from '@payright/web-components';
import { round } from 'lodash';

const {
  ONE_MONTHLY_INCOME_MUST_SELECTED,
  INCOME_MUST_BE_GREATER_THAN_ZERO,
  DEFAULT_INCOME_PERCENTAGE,
} = getConstants();

interface totalMonthlyIncomeProps {
  shouldShowIncomeError: boolean;
  wages: Array<any>;
  otherIncome?: number;
  setIncomeSelected: Function;
}

const TotalMonthlyIncome = ({
  shouldShowIncomeError,
  wages,
  otherIncome,
  setIncomeSelected,
}: totalMonthlyIncomeProps) => {
  const { setValue, watch, getValues, errors } = useFormContext<{
    livingExpenses: CustomerLivingExpeseIncomeData;
  }>();
  watch('wages');
  watch('livingExpenses');
  const [incomeOptionSelected, setIncomeOptionSelected] = useState(0);

  const handlePercentageChange = (val: number[]) => {
    const percent = val[0];
    if (otherIncome) {
      const otherIncomeValue = Number(otherIncome.toString().replace(',', ''));
      const calculation = round((otherIncomeValue * percent) / 100, 0);

      setValue('livingExpenses.otherIncome', new Intl.NumberFormat().format(Number(calculation)));
    }
  };

  const handleIncomeSelection = (value: boolean) => {
    const options = value ? incomeOptionSelected + 1 : incomeOptionSelected - 1;
    setIncomeOptionSelected(options);

    if (options > 0) {
      setIncomeSelected(true);
    } else {
      setIncomeSelected(false);
    }
  };

  return (
    <SCIncomeSection>
      <h2 className="form-section--header">1. Income</h2>
      <div className="form-block">
        <p>The bank statements you have provided identify the income sources listed below.</p>
        <p>
          So we can verify your income, please confirm the income sources earned by <u>you only</u>,
          by selecting all that apply.
        </p>
      </div>
      <div className="form-section--form-block">
        <div className="form-section--data-row">
          {wages.length > 0 && (
            <div data-testid="wages-list">
              <h3 className="form-section--header">Wages</h3>
              {wages.map(({ key, value }, i) => (
                <div className="wage-row" key={key}>
                  <ControlledCheckbox
                    name={`wages.${i}`}
                    checked={getValues(`wages.${i}`) || false}
                    handleClick={(name: string, val: boolean) => {
                      setValue(name, !val);
                      handleIncomeSelection(!val);
                    }}
                    readOnly={false}
                  >
                    {value}
                  </ControlledCheckbox>
                </div>
              ))}
            </div>
          )}
        </div>
        {otherIncome !== undefined && parseInt(otherIncome.toString().replace(',', '')) > 0 && (
          <div className="check-other-income-wrapper" data-testid="other-income-section">
            <div className="form-section--data-row">
              <h3 className="form-section--header">Other income sources</h3>
              <div className="other-income-row">
                <div className="other-income-checkbox">
                  <ControlledCheckbox
                    name="livingExpenses.otherIncomeSource"
                    checked={getValues('livingExpenses.otherIncomeSource') || false}
                    readOnly={false}
                    handleClick={(name: string, value: boolean) => {
                      setValue(name, !value);
                      handleIncomeSelection(!value);
                    }}
                  >
                    <div className="label-text">Other monthly income</div>
                  </ControlledCheckbox>
                  <Tooltip
                    content={
                      'Other income sources such as Rental income, NZ Work & Income payments, and Pension/Super'
                    }
                    className="py-tooltip"
                    direction="bottom"
                  >
                    <IconInfo />
                  </Tooltip>
                </div>

                <ControlledInputField
                  name="livingExpenses.otherIncome"
                  readOnly={true}
                  prefix="$"
                  rules={{
                    validate: () => {
                      if (getValues('livingExpenses.otherIncome') === 0)
                        return INCOME_MUST_BE_GREATER_THAN_ZERO;
                    },
                  }}
                  error={errors.livingExpenses?.otherIncome?.message as string}
                ></ControlledInputField>
              </div>
            </div>

            <Range
              min={0}
              max={100}
              step={1}
              defaultValue={DEFAULT_INCOME_PERCENTAGE}
              handleChange={handlePercentageChange}
            >
              Other monthly income was identified to the monthly total displayed above. Please move
              the slider below to confirm the % of this income earned by <u>you</u>.
            </Range>
          </div>
        )}
        <div className="errorMessage">
          {shouldShowIncomeError && ONE_MONTHLY_INCOME_MUST_SELECTED}
        </div>
      </div>
    </SCIncomeSection>
  );
};

export default TotalMonthlyIncome;
