import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useFormContext, Controller } from 'react-hook-form';
import PlacesAutocomplete from '../address-autocomplete';
import { media } from '@payright/web-components';
import { ControlledInputField, ControlledDropdown } from '../form-fields';
import { AddressDetailsData as MerchantApiAddressDetailsData } from '../../types/customer';
import { ShippingDetailsData as MerchantApiShippingDetailsData } from '../../types/customer';

import getConstants from 'util/constants';

const {
  REGION_STATES,
  RESIDENTIAL_STATUS,
  TIME_IN_ADDRESS,
  LABEL_STATE,
  LABEL_SUBURB,
  ADDRESS_DETAILS,
  LESS_THAN_6_MONTHS,
} = getConstants();

export type AddressDetailsData = MerchantApiAddressDetailsData;
export type ShippingDetailsData = MerchantApiShippingDetailsData;

export type CustomerAddressDetailsProps = {
  existingCustomer: boolean;
  editing: boolean;
  formName?: string;
};

const SCCustomerAddressDetails = styled.div`
  .details-body {
    display: flex;
    flex-wrap: wrap;
  }
  ${media.max.medium} {
    .input-field,
    .dropdown {
      width: 100%;
      max-width: 100%;
    }
    .title-firstname-wrapper {
      width: 100%;
      & > div:first-of-type {
        margin-bottom: 1.33em;
      }
    }
    .date-picker {
      width: 100%;
    }
    .details-body > div,
    .address-body .input-field,
    .address-body .dropdown {
      margin-bottom: 1.33em;
    }
    .address-body .dropdown.right {
      margin-left: 0;
      margin-right: 0;
    }
  }

  ${media.min.medium} {
    hr {
      &#details-hr {
        margin-top: 2.27em;
        margin-bottom: 4.4em;
      }
      margin-top: 1.15em;
      margin-bottom: 2em;
    }
    .address-wrapper {
      display: flex;
      flex-wrap: wrap;
      .input-field,
      .dropdown {
        max-width: calc(50% - 0.75em);
      }
      .input-field {
        margin-right: 0.75em;
      }

      .state,
      .res-status {
        margin-left: 0.75em;
      }
    }

    .details-body > div,
    .address-body .input-field,
    .address-body .dropdown {
      margin-bottom: 1.33em;
    }
    .address-body .dropdown.right {
      margin-left: 1.5em;
      margin-right: 0;
    }
  }
`;

const AddressDetails = ({ existingCustomer, editing, formName }: CustomerAddressDetailsProps) => {
  const { errors, clearErrors, setValue, watch, getValues } = useFormContext<{
    addressDetails: AddressDetailsData;
    shippingDetails: ShippingDetailsData;
  }>();

  const timeAtCurrentAddress = watch(
    'addressDetails.residentialTimeAtAddress'
  ) as AddressDetailsData['residentialTimeAtAddress'];

  const handleStreetAutoComplete = (addressDetails: any) => {
    clearErrors(`${formName}`);
    setValue(`${formName}.suburb`, addressDetails.suburb);
    setValue(`${formName}.street`, addressDetails.street);
    setValue(`${formName}.state`, addressDetails.states);
    setValue(`${formName}.postcode`, addressDetails.postcode);
  };

  const sectionRef = useRef<HTMLDivElement>(null);

  // Scroll to block (by ID) when user submits and validation errors
  useEffect(() => {
    if (Object.keys(errors).length > 0 && errors.addressDetails && sectionRef.current) {
      // check for null and focus target on block with errors
      sectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [errors]); // Errors object changes on submit

  return (
    <SCCustomerAddressDetails id="AddressSection">
      <div ref={sectionRef} style={{ position: 'relative', top: '-2em' }} />
      <div className="address-body">
        <Controller
          name={formName + '.street'}
          rules={{ required: 'Street address is required' }}
          render={({ onChange }) => {
            return (
              <PlacesAutocomplete
                name={formName + '.street'}
                handleInputChange={onChange}
                onStreetChange={handleStreetAutoComplete}
                readOnly={existingCustomer && !editing}
                error={errors.addressDetails?.street?.message}
                clearErrors={clearErrors}
              />
            );
          }}
        />
        <div className="address-wrapper">
          <ControlledInputField
            name={formName + '.suburb'}
            rules={{ required: `${LABEL_SUBURB} is required` }}
            className="suburb"
            readOnly={existingCustomer && !editing}
            error={errors.addressDetails?.suburb?.message}
          >
            {`${LABEL_SUBURB} *`}
          </ControlledInputField>
          <ControlledDropdown
            name={formName + '.state'}
            rules={{ required: `${LABEL_STATE} is required` }}
            className="state"
            options={REGION_STATES}
            readOnly={existingCustomer && !editing}
            error={errors.addressDetails?.state?.message}
          >
            {`${LABEL_STATE} *`}
          </ControlledDropdown>
          <ControlledInputField
            name={formName + '.postcode'}
            rules={{ required: 'Postcode required' }}
            className="postcode"
            readOnly={existingCustomer && !editing}
            error={errors.addressDetails?.postcode?.message}
            type="number"
          >
            Postcode *
          </ControlledInputField>
          {formName === ADDRESS_DETAILS && (
            <>
              <ControlledDropdown
                name={formName + '.residentialStatus'}
                rules={{ required: 'Residential status required' }}
                className="res-status"
                options={RESIDENTIAL_STATUS}
                readOnly={existingCustomer && !editing}
                error={errors.addressDetails?.residentialStatus?.message}
              >
                Residential Status *
              </ControlledDropdown>
              <ControlledDropdown
                name={formName + '.residentialTimeAtAddress'}
                rules={{ required: 'Time at current address is required' }}
                error={errors.addressDetails?.residentialTimeAtAddress?.message}
                className="res-timeataddress"
                options={TIME_IN_ADDRESS}
                readOnly={existingCustomer && !editing}
              >
                Time at Current Address *
              </ControlledDropdown>

              {timeAtCurrentAddress === LESS_THAN_6_MONTHS && (
                <>
                  <ControlledDropdown
                    name={formName + '.residentialTimeAtPreviousAddress'}
                    rules={{
                      validate: (value: string) => {
                        if (
                          getValues('addressDetails.residentialTimeAtAddress') ===
                            LESS_THAN_6_MONTHS &&
                          !value
                        ) {
                          return 'Time in previous address is required';
                        }
                      },
                    }}
                    error={
                      errors.addressDetails?.residentialTimeAtPreviousAddress?.message as string
                    }
                    className="right res-timeatPreviousaddress"
                    options={TIME_IN_ADDRESS}
                    readOnly={existingCustomer && !editing}
                  >
                    Time at Previous Address *
                  </ControlledDropdown>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </SCCustomerAddressDetails>
  );
};

export default AddressDetails;
