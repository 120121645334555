import * as React from 'react';
import styled from 'styled-components';
import { ButtonRounded, IconTick, media } from '@payright/web-components';

export interface EditDetailsBlockProps {
  title: string;
  text?: any;
  children?: any;
  showEditToggleButton: boolean;
  toggleEditing: Function;
  editing: boolean;
}

export interface EditDetailsBlockState {
  editing: boolean;
}

const SCEditDetailsBlock = styled.section`
  .header {
    margin: 2em 0;
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h4 {
        color: ${props => props.theme.colours.blue.base};
      }
      button {
        font-size: 0.86em;
        svg {
          width: 11px;
          height: 11px;
        }
      }
    }
    p {
      margin: 2em 0;
    }
  }

  ${media.max.medium} {
    .hide-mobile {
      display: none;
    }
  }
`;

class EditDetailsBlock extends React.Component<EditDetailsBlockProps, EditDetailsBlockState> {
  render() {
    const { showEditToggleButton, children, title, text, toggleEditing, editing } = this.props;

    return (
      <SCEditDetailsBlock>
        <div className="header">
          <div className="header__title">
            <h4>{title}</h4>{' '}
            {showEditToggleButton && (
              <ButtonRounded
                handleClick={() => toggleEditing(title)}
                selected={editing}
                icon={editing ? <IconTick height={13} width={13} /> : undefined}
                colour="blue"
              >
                {editing ? (
                  <span>
                    Done<span className="hide-mobile">&nbsp;editing</span>
                  </span>
                ) : (
                  <span>
                    Edit<span className="hide-mobile">&nbsp;details</span>
                  </span>
                )}
              </ButtonRounded>
            )}
          </div>
          <p>{text}</p>
        </div>
        {children}
      </SCEditDetailsBlock>
    );
  }
}

export default EditDetailsBlock;
