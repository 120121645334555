import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// UTILS
import { useQuery } from '../../util/hooks';

// REDUX AND SLICES
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../reducers';

import { fetchCheckoutAttributes } from '../../reducers/checkout-slice';
import { logout, fetchMerchantData } from '../../reducers/auth-slice';

// OTHER COMPONENTS
import WhatIsPayright from '../../components/what-is-payright';
import LoginOrSignup from '../../components/login-or-signup';
import Loader from '../../components/loader';
import { AppDispatch } from '../../util/store';

// STYLES AND STYLED COMPONENTS
import SCLanding from './css';

interface LandingProps {}

const Landing = (props: LandingProps) => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();

  // URL QUERY PARAMS
  const query = useQuery();
  const queryParamCheckoutId = query.get('checkoutId');

  // GLOBAL VARIABLES
  const globalAuthState = useSelector((state: RootState) => state.auth);
  const globalCheckoutState = useSelector((state: RootState) => state.checkout);

  // VARIABLES
  const customerAttached = globalCheckoutState.checkout?.customerId || '';
  const applicationCompleteBy = globalCheckoutState.checkout?.applicationCompletedBy || '';
  const planId = globalCheckoutState.checkout?.planId || '';

  // SIGNUP HANDLER
  const handleSignup = () => {
    history.push('/payment-plan/customer-details' + history.location.search);
  };

  // LOGIN HANDLER
  const handleLogin = () => {
    if (planId && applicationCompleteBy === 'Customer') {
      history.push('/login-applicant' + history.location.search);
    } else {
      history.push('/login' + history.location.search);
    }
  };

  // FETCH AUTH TOKENS AND RATES
  useEffect(() => {
    if (queryParamCheckoutId) {
      dispatch(fetchCheckoutAttributes(queryParamCheckoutId));
      dispatch(fetchMerchantData(queryParamCheckoutId));
    }
  }, [dispatch, queryParamCheckoutId]);

  // CLEAR LOCAL STORAGE
  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  if (globalAuthState.loading || globalCheckoutState.loading) {
    return (
      <SCLanding>
        <Loader />
      </SCLanding>
    );
  }

  return (
    <SCLanding>
      <LoginOrSignup
        queryParamCheckoutId={queryParamCheckoutId}
        signupClickHandler={handleSignup}
        loginClickHandler={handleLogin}
        customerAttached={customerAttached}
        hasErrors={globalCheckoutState.hasErrors}
        loading={globalCheckoutState.loading}
      />
      <WhatIsPayright />
    </SCLanding>
  );
};

export default Landing;
