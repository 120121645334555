import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { Row, Col } from 'react-flexbox-grid';
import scrollToComponent from 'react-scroll-to-component';

// UTILS
import { useQuery, useTotalSteps } from '../../util/hooks';

import getConstants from 'util/constants';

// REDUX AND SLICES
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../reducers';
import { fetchMerchantData } from '../../reducers/auth-slice';
import { fetchExistingData } from '../../reducers/customer-slice';
import { fetchCheckoutAttributes, updateCheckout } from '../../reducers/checkout-slice';
import { getActiveCard, fetchCustomerPaymentMethods } from '../../reducers/payment-methods-slice';
import { getPaymentGatewayDetails } from '../../reducers/payment-slice';
import { CheckoutStep, CheckoutType } from '../../types/checkout';

// WEB COMPONENTS
import { Alert, IconAttention } from '@payright/web-components';

// OTHER COMPONENTS
import StepHeader from '../../components/step-header';
import Loader from '../../components/loader';
import EwayForm from '../../components/payment/eway-form';
import FatZebraForm from '../../components/payment/fat-zebra-form';

// LAYOUT COMPONENTS
import LargerHeaderWithNavLayout from '../../layouts/large-header-with-nav-layout';
import { ApplicationCompletedBy } from 'types/plan';

// STYLES AND STYLED COMPONENTS
import SCStep3 from './css';
import { SiftDecisionType } from 'types/sift-decision';

// CONSTANTS
const {
  SUCCESSFULL_NEW_PLAN_APPROVED_TITLE,
  SUCCESSFULL_NEW_PLAN_APPROVED_BODY,
  SUCCESSFULL_PRE_APPROVED_PLAN_TITLE,
  SUCCESSFULL_PRE_APPROVED_PLAN_BODY,
  SUCCESSFULL_PRE_APPROVED_PAYMENT_PLAN_TITLE,
  SUCCESSFULL_PRE_APPROVED_PAYMENT_PLAN_BODY,
  REDIRECT_URL,
} = getConstants();

interface Step3Props {}

type FormData = {
  selectedPaymentDate: Date | null;
  cardHolderName: string | null;
  cardNo: string | null;
  csv: string | null;
  expiry: string | null;
  selectedMonthlyDate: string | null;
};

const Step3 = (props: Step3Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  // URL QUERY PARAMS
  const query = useQuery();
  const queryParamCheckoutId = query.get('checkoutId');

  // GLOBAL STATE
  const globalAuthState = useSelector((state: RootState) => state.auth);
  const globalCheckoutState = useSelector((state: RootState) => state.checkout);
  const globalCustomerState = useSelector((state: RootState) => state.customer);
  const globalPaymentState = useSelector((state: RootState) => state.payment);
  const globalPaymentMethodsState = useSelector((state: RootState) => state.paymentMethods);

  // VARIABLES
  const isLoggedIn = globalAuthState.isLoggedIn;
  const depositTaker = globalAuthState.storeConfig?.depositTaker || false;
  const checkout = globalCheckoutState.checkout;
  const checkoutId = globalCheckoutState.checkout?.id || queryParamCheckoutId || '';
  const customerId = globalCheckoutState.checkout?.customerId || globalAuthState.customerId;
  const checkoutType = globalCheckoutState.checkout?.attributes?.type;
  const checkoutStep = globalCheckoutState.checkout?.attributes?.step;
  const redirectUrl = globalCheckoutState.checkout?.attributes?.redirectUrl || REDIRECT_URL;
  const paymentMethods = globalPaymentMethodsState.paymentMethods;
  const errorMessagePaymentMethods = globalPaymentMethodsState.errorMessage;
  const errorMessage = globalPaymentState.errorMessage;
  const addCardError = globalPaymentState.addCardError;
  const paymentStatus = globalPaymentState.payment.status;
  const paymentGateway = globalPaymentState.paymentGateway;
  const addCardStatus = globalPaymentState.addCardStatus;
  const submitSucceeded = globalPaymentState.submitSucceeded;
  const activeCardId = getActiveCard(paymentMethods);

  const loadingAuth = globalAuthState.loading;
  const loadingCustomer = globalCustomerState.loading;
  const loadingCheckout = globalCheckoutState.loading;
  const loadingPayment = globalPaymentState.loading;
  const loadingPaymentMethods = globalPaymentMethodsState.loading;
  const siftDecision = globalPaymentState.siftDecision;

  // LOCAL STATE: OTHER
  const [selectedCardId, setSelectedCardId] = useState<string>(activeCardId);
  const [clickChangeCard, setClickChangeCard] = useState<boolean>(false);
  const [hasValidCardSelected, setHasValidCardSelected] = useState<boolean>(
    activeCardId ? true : false
  );

  const totalSteps = useTotalSteps();

  // REFS TO SCROLL TO
  let mainBlockRef: HTMLDivElement | null = null;

  // START PAYMENT FORM
  const reactHookForm = useForm<FormData>({
    defaultValues: {
      selectedPaymentDate: new Date(),
    },
    mode: 'onSubmit',
  });
  // END PAYMENT FORM

  // If sift decision is declined for the ecommerce
  useEffect(() => {
    if (
      siftDecision === SiftDecisionType.DECLINED &&
      checkout?.applicationCompletedBy === ApplicationCompletedBy.ECOMMERCE
    ) {
      history.push('/payment-plan/plan-result/' + history.location.search);
    }
  }, [siftDecision, checkout]);

  const handleCardChange = (cardId: string) => {
    setSelectedCardId(cardId);
    setHasValidCardSelected(true);
    setClickChangeCard(true);
  };

  // SCROLL TO TOP ON PAGE LOAD
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (redirectUrl) {
      localStorage.setItem('redirectUrl', redirectUrl);
    }
  }, [redirectUrl]);

  useEffect(() => {
    if (activeCardId) {
      setSelectedCardId(activeCardId);
      setHasValidCardSelected(true);
      setClickChangeCard(true);
    }
  }, [activeCardId, setSelectedCardId, setClickChangeCard]);

  // DISPLAY AND SCROLL TO ALERT BOX WHEN THERE IS AN ERROR
  useEffect(() => {
    if (errorMessage) {
      scrollToComponent(mainBlockRef, {
        offset: -25,
        align: 'top',
        duration: 600,
        ease: 'linear',
      });
    }
  }, [errorMessage, mainBlockRef]);

  // LOAD LATEST CHECKOUT FROM THE SERVER
  useEffect(() => {
    if (checkoutId) {
      dispatch(fetchCheckoutAttributes(checkoutId));
    }
  }, [checkoutId, dispatch]);

  // LOAD EXISTING CUSTOMER DETAILS, PAYMENT METHODS, AND MERCHANT DATA
  useEffect(() => {
    if (customerId && checkoutId) {
      dispatch(fetchExistingData(checkoutId, customerId));
      dispatch(getPaymentGatewayDetails(customerId, checkoutId));
      dispatch(fetchCustomerPaymentMethods(checkoutId, customerId));
      dispatch(fetchMerchantData(checkoutId));
    }
  }, [customerId, checkoutId, dispatch]);

  // REDIRECT TO LANDING PAGE IF NO CHECKOUT ID IN URL (DOES NOT CHECK IF VALID ID)
  useEffect(() => {
    if (!queryParamCheckoutId) {
      history.push('/');
    }
  }, [history, queryParamCheckoutId]);

  // REDIRECT TO LANDING PAGE IF CHECKOUT EXPIRED
  useEffect(() => {
    if (checkout && checkout.attributes && checkout.attributes.expiresAt !== undefined) {
      if (checkout.attributes?.expiresAt < new Date().toISOString()) {
        history.push('/');
      }
    }
  }, [history, checkout]);

  // REDIRECT TO LANDING PAGE IF NOT LOGGED IN
  useEffect(() => {
    if (!isLoggedIn) {
      history.push('/');
    }
  }, [history, isLoggedIn]);

  // GO TO PLAN RESULT IF PAYMENT SUCCESSFUL FOR STANDARD AND APPLY AND PAY CHECKOUT TYPE
  useEffect(() => {
    if (
      checkout?.attributes &&
      checkoutType !== 'pre-approval' &&
      !addCardError &&
      (paymentStatus === 'success' || submitSucceeded)
    ) {
      history.push('/payment-plan/plan-result' + history.location.search);
      dispatch(
        updateCheckout({
          checkoutIdentifier: checkoutId,
          paymentDetails: checkout.attributes.paymentDetails,
          checkoutData: {
            type: checkout.attributes.type,
            //redirectUrl: checkout.attributes.redirectUrl,
            expiresAt: checkout.attributes.expiresAt,
            step: CheckoutStep.CHECKOUT_STEP_PLAN_RESULT,
          },
        })
      );
    }
  }, [history, checkoutType, addCardError, paymentStatus, depositTaker, submitSucceeded]);

  // GO TO PLAN RESULT FOR PRE-APPROVAL CHECKOUT TYPE - NO PAYMENT
  // THIS REDIRECTION HAPPENS FOR NEW CUSTOMER
  // BECAUSE THEY NEED TO ADD THE CARD FIRST
  useEffect(() => {
    if (
      checkout?.attributes &&
      checkoutType === 'pre-approval' &&
      checkoutStep === 'payment' &&
      paymentStatus === '' &&
      !addCardError &&
      (addCardStatus === true || clickChangeCard === true)
    ) {
      history.push('/payment-plan/plan-result' + history.location.search);
      dispatch(
        updateCheckout({
          checkoutIdentifier: checkoutId,
          paymentDetails: checkout.attributes.paymentDetails,
          checkoutData: {
            type: checkout.attributes.type,
            redirectUrl: checkout.attributes.redirectUrl,
            expiresAt: checkout.attributes.expiresAt,
            step: CheckoutStep.CHECKOUT_STEP_PLAN_RESULT,
          },
        })
      );
    }
  }, [
    history,
    checkoutType,
    checkoutStep,
    addCardError,
    paymentStatus,
    addCardStatus,
    clickChangeCard,
  ]);

  // GO TO PLAN RESULT FOR PRE-APPROVAL CHECKOUT TYPE - PAYMENT
  // THIS WILL WORK FOR ANY MERCHANT TYPE (DEPOSIT TAKER, APPLICATION TAKER, ZERO DEPOSIT)
  useEffect(() => {
    if (
      checkout?.attributes &&
      checkoutType === 'pre-approval' &&
      (paymentStatus === 'success' || submitSucceeded) &&
      checkoutStep === 'pre-approval-payment' &&
      !addCardError
    ) {
      history.push('/payment-plan/plan-result' + history.location.search);
      dispatch(
        updateCheckout({
          checkoutIdentifier: checkoutId,
          paymentDetails: checkout.attributes.paymentDetails,
          checkoutData: {
            type: checkout.attributes.type,
            redirectUrl: checkout.attributes.redirectUrl,
            expiresAt: checkout.attributes.expiresAt,
            step: CheckoutStep.CHECKOUT_STEP_PLAN_RESULT,
          },
        })
      );
    }
  }, [
    history,
    checkoutType,
    checkoutStep,
    addCardError,
    paymentStatus,
    addCardStatus,
    clickChangeCard,
    depositTaker,
    submitSucceeded,
  ]);

  return (
    <LargerHeaderWithNavLayout>
      <SCStep3>
        {loadingAuth ||
        loadingCustomer ||
        loadingCheckout ||
        loadingPayment ||
        loadingPaymentMethods ? (
          <div className="loading-wrapper">
            <Loader text="" />
          </div>
        ) : (
          <div className="step-body">
            <StepHeader activeStep={totalSteps} totalSteps={totalSteps} title="" />
            <FormProvider {...reactHookForm}>
              {checkoutType !== 'pre-approval' && (
                <Row className="message-wrapper">
                  <Col xs={12} md={12} lg={12}>
                    <h4 className="message-title">{SUCCESSFULL_NEW_PLAN_APPROVED_TITLE}</h4>
                    <p className="message-subtitle">{SUCCESSFULL_NEW_PLAN_APPROVED_BODY}</p>
                  </Col>
                </Row>
              )}

              {checkoutType === 'pre-approval' && (
                <Row className="message-wrapper">
                  <Col xs={12} md={12} lg={12}>
                    <h4 className="message-title">
                      {checkoutStep === 'payment'
                        ? SUCCESSFULL_PRE_APPROVED_PLAN_TITLE
                        : SUCCESSFULL_PRE_APPROVED_PAYMENT_PLAN_TITLE}
                    </h4>
                    <p className="message-subtitle">
                      {checkoutStep === 'payment'
                        ? SUCCESSFULL_PRE_APPROVED_PLAN_BODY
                        : SUCCESSFULL_PRE_APPROVED_PAYMENT_PLAN_BODY}
                    </p>
                  </Col>
                </Row>
              )}

              <div
                ref={(section: HTMLDivElement) => {
                  mainBlockRef = section;
                }}
              ></div>

              {errorMessage && (
                <div className="alert-container">
                  <Alert
                    title={'Unable to process this payment'}
                    body={errorMessage}
                    outcome="error"
                    icon={<IconAttention />}
                  />
                </div>
              )}

              {errorMessagePaymentMethods && (
                <div className="alert-container">
                  <Alert
                    title={'Unable to process this payment'}
                    body={errorMessagePaymentMethods}
                    outcome="error"
                    icon={<IconAttention />}
                  />
                </div>
              )}

              {paymentGateway?.serviceProvider === 'Eway' && (
                <EwayForm
                  handleCardChange={handleCardChange}
                  clickChangeCard={clickChangeCard}
                  selectedCardId={selectedCardId}
                  activeCardId={activeCardId}
                  hasValidCardSelected={hasValidCardSelected}
                />
              )}

              {paymentGateway?.serviceProvider === 'Cloud_Payments' && (
                <FatZebraForm iframeUrl={paymentGateway?.iframeUrl} />
              )}
            </FormProvider>
          </div>
        )}
      </SCStep3>
    </LargerHeaderWithNavLayout>
  );
};

// Method to check what's the checkout step and checkout type in checkout table
// Based uppn that, we are updating deposit status in the sugar.
export const shouldUpdatePaymentStatus = (
  checkoutType: CheckoutType,
  checkoutStep: CheckoutStep
): boolean => {
  // for quick links quote
  if (
    checkoutType === CheckoutType.PRE_APPROVAL &&
    checkoutStep === CheckoutStep.CHECKOUT_STEP_PRE_APPROVAL_PAYMENT
  ) {
    return true;
  }
  // for customer led and quick links invoice
  if (
    checkoutType === CheckoutType.APPLY_AND_PAY &&
    checkoutStep === CheckoutStep.CHECKOUT_STEP_PAYMENT
  ) {
    return true;
  }
  // for ecommerce
  if (
    checkoutType === CheckoutType.STANDARD &&
    checkoutStep === CheckoutStep.CHECKOUT_STEP_PAYMENT
  ) {
    return true;
  }
  // By default it will return false
  return false;
};

export default Step3;
