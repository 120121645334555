import styled from 'styled-components';
import { media } from '@payright/web-components';

export const SCIncomeSection = styled.div`
  .form-section--data-row {
    margin-bottom: 1em;
  }

  .wage-row {
    padding: 0.5em 0;
  }

  .check-other-income-wrapper {
    margin-bottom: 3em;
    .other-income-row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0.5em 0;

      .other-income-checkbox {
        margin-bottom: 0.75em;
        display: inline-flex;
      }

      // Responsive styles
      ${media.min.medium} {
        flex-direction: row;
        align-items: center;

        .other-income-checkbox {
          margin-bottom: 0em;
        }

        > * {
          width: 50%;
        }
      }
    }
  }

  .py-tooltip {
    display: inline-block;
    vertical-align: middle;
    svg {
      height: 15px;
      width: 15px;
      margin: 0.4em;
    }
  }

  .data-col {
    strong {
      font-weight: 600;
    }
  }
`;
