import React from 'react';
import { ControlledInputField, ControlledDatePicker } from '../../form-fields';
import { useFormContext } from 'react-hook-form';
import { NzIdentificationLicenseData } from '../../../types/customer';
import getConstants from '../../../util/constants';
import { envIsTrue } from '../../../util/helpers';
import { LicenceDetailsProps } from './index';

const { IDENTIFICATION_MAX_YEARS_EXPIRY_FROM_TODAY, LICENCE_VALIDATION_REGEX } = getConstants();

const NzLicenceDetails = ({ existingCustomer, editing }: LicenceDetailsProps) => {
  const { errors } = useFormContext<{
    identificationDetails: NzIdentificationLicenseData;
  }>();

  return (
    <div className="details-body">
      <ControlledInputField
        name="identificationDetails.licenseNo"
        rules={{
          required: 'Licence Number is required',
          pattern: envIsTrue('REACT_APP_SKIP_CUSTOMER_LICENCE_VALIDATION')
            ? undefined
            : {
                value: LICENCE_VALIDATION_REGEX,
                message: 'Licence Number must be formatted ab123456',
              },
        }}
        error={(errors.identificationDetails as any)?.licenseNo?.message}
        readOnly={existingCustomer && !editing}
      >
        Licence No. *
      </ControlledInputField>
      <div className="state-expiry-wrapper">
        <ControlledInputField
          name="identificationDetails.licenseVersionNumber"
          rules={{
            required: 'Licence Version Number is required',
            pattern: envIsTrue('REACT_APP_SKIP_CUSTOMER_LICENCE_VERSION_NUMBER_VALIDATION')
              ? undefined
              : {
                  value: /^[0-9]{3}$/,
                  message: 'Licence Version Number must be 3 numbers long',
                },
          }}
          readOnly={existingCustomer && !editing}
          error={(errors.identificationDetails as any)?.licenseVersionNumber?.message}
        >
          Licence Version No. *
        </ControlledInputField>
        <ControlledDatePicker
          name="identificationDetails.licenseExpiry"
          readOnly={existingCustomer && !editing}
          rules={{
            required: 'Licence expiry is required',
            validate: (value: any) => {
              const expiry = new Date(value);
              const today = new Date();
              today.setDate(today.getDate() + 1);
              if (expiry.getTime() < today.getTime()) {
                return 'Enter a valid expiry';
              }
            },
          }}
          error={(errors.identificationDetails as any)?.licenseExpiry?.message as string}
          startYear={new Date().getFullYear()}
          endYear={new Date().getFullYear() + IDENTIFICATION_MAX_YEARS_EXPIRY_FROM_TODAY}
        >
          Expiry *
        </ControlledDatePicker>
      </div>
    </div>
  );
};

export default NzLicenceDetails;
