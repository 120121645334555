import numeral from 'numeral';

export function calculateDeclaredTotalMonthlyIncome(
  allWages: Array<{ key: string; value: string }>,
  allWageCheckboxes: Array<boolean>,
  otherIncome: number
) {
  // Loop through all the checkboxes, find the index of which ones were checked (i.e. value = true)
  let selectedWageIndexes = Object.keys(allWageCheckboxes)
    .filter(index => allWageCheckboxes[parseInt(index)] === true)
    .map(key => parseInt(key));

  // Loop through all existing wages from BSO, and find the value of which ones were checked
  const selectedWages: Array<number> = [];
  for (const selectedIndex of selectedWageIndexes) {
    selectedWages.push(parseFloat(allWages[selectedIndex].key));
  }

  // Sum up all the selected wages
  const selectedWagesTotal = selectedWages.reduce((sum, currentValue) => {
    return numeral(sum).add(currentValue);
  }, numeral(0.0));

  // Add the other income as well
  const total = numeral(selectedWagesTotal)
    .add(otherIncome)
    .value() as number;

  return total;
}

export function calculateAppliedLivingExpensePercentage(
  declaredTotalMonthlyIncome: number,
  originalTotalMonthlyIncome: number,
  appliedLivingExpensePercentage: number
) {
  // incomePercentage = (declaredTotalMonthlyIncome / originalTotalMonthlyIncome) * 100
  const incomePercentage = numeral(declaredTotalMonthlyIncome)
    .divide(originalTotalMonthlyIncome)
    .multiply(100)
    .value() as number;

  let result = 0;

  // Take the higher of the two as the result
  if (appliedLivingExpensePercentage >= incomePercentage) {
    result = appliedLivingExpensePercentage;
  } else {
    result = incomePercentage;
  }

  // Round the percentage to 2 decimal points
  return Number((numeral(result).value() as number).toFixed(2));
}

export function calculateAppliedFinancialExpensePercentage(
  declaredTotalMonthlyIncome: number,
  originalTotalMonthlyIncome: number,
  appliedFinancialExpensePercentage: number
) {
  // incomePercentage = (declaredTotalMonthlyIncome / originalTotalMonthlyIncome) * 100
  const incomePercentage = numeral(declaredTotalMonthlyIncome)
    .divide(originalTotalMonthlyIncome)
    .multiply(100)
    .value() as number;

  let result = 0;

  // Take the highest of the two, defaulting to a mimumum of 50%
  if (appliedFinancialExpensePercentage >= incomePercentage) {
    result = appliedFinancialExpensePercentage;
  } else {
    result = incomePercentage;
  }

  if (result < 50) {
    result = 50;
  }

  // Round the percentage to 2 decimal points
  return Number((numeral(result).value() as number).toFixed(2));
}
