import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';

// UTILS
import { useQuery } from '../../util/hooks';

// REDUX AND SLICES
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { loginApplicant, logout } from '../../reducers/auth-slice';
import { fetchCheckoutAttributes, updateCheckout } from '../../reducers/checkout-slice';

// WEB COMPONENTS
import { Button, IconLogo } from '@payright/web-components';

// OTHER COMPONENTS
import { ControlledInputField } from '../../components/form-fields';
import SCSpinner from '../../components/spinner';

// STYLES AND STYLED COMPONENTS
import SCEcommerceLogin from './css';
import { CheckoutStep } from '../../types/checkout';

interface LoginApplicantProps {}

type FormData = {
  code: string;
};

const LoginApplicant = (props: LoginApplicantProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  // URL QUERY PARAMS
  const query = useQuery();
  const queryParamCheckoutId = query.get('checkoutId');

  // GLOBAL STATE
  const globalAuthState = useSelector((state: RootState) => state.auth);
  const globalCheckoutState = useSelector((state: RootState) => state.checkout);

  // VARIABLES
  const { errorMessage, isLoggedIn, loading } = globalAuthState;
  const customerIdAuth = globalAuthState.customerId;
  const customerIdCheckout = globalCheckoutState.checkout?.customerId;
  const checkout = globalCheckoutState.checkout;
  const checkoutId = globalCheckoutState.checkout?.id || '';
  const planId = globalCheckoutState.checkout?.planId || '';
  const checkoutType = globalCheckoutState.checkout?.attributes?.type;
  const status = globalCheckoutState.checkout?.status;

  // START LOGIN FORM
  const reactHookForm = useForm<FormData>({
    defaultValues: {
      code: '',
    },
    mode: 'onChange',
  });
  const { handleSubmit, formState } = reactHookForm;

  const formSubmitHandler = (formData: FormData) => {
    console.log('Code is:', formData.code);
    dispatch(loginApplicant(planId, formData.code));
  };
  // END LOGIN FORM

  // LOAD LATEST CHECKOUT FROM THE SERVER
  useEffect(() => {
    if (queryParamCheckoutId) {
      dispatch(fetchCheckoutAttributes(queryParamCheckoutId));
    }
  }, [queryParamCheckoutId, dispatch]);

  // REDIRECT TO STEP 3 PAYMENT IF CHECKOUT TYPE IS PRE-APPROVAL
  // AND CHECKOUT CUSTOMER ID EQUALS LOGGED IN CUSTOMER ID
  // AND CHECKOUT STATUS IS APPROVED
  useEffect(() => {
    console.log('status: ', status);
    if (
      isLoggedIn &&
      checkout?.attributes &&
      checkoutType === 'pre-approval' &&
      customerIdCheckout === customerIdAuth &&
      status === 'approved'
    ) {
      history.push('/payment-plan/payment' + history.location.search);
      dispatch(
        updateCheckout({
          checkoutIdentifier: checkoutId,
          paymentDetails: checkout.attributes.paymentDetails,
          checkoutData: {
            type: checkout.attributes.type,
            redirectUrl: checkout.attributes.redirectUrl,
            expiresAt: checkout.attributes.expiresAt,
            step: CheckoutStep.CHECKOUT_STEP_PRE_APPROVAL_PAYMENT,
          },
        })
      );
    }
  }, [isLoggedIn, history, checkoutType, customerIdCheckout, customerIdAuth]);

  // REDIRECT TO STEP 3 PAYMENT IF CHECKOUT TYPE IS APPLY AND PAY
  // AND CHECKOUT CUSTOMER ID EQUALS LOGGED IN CUSTOMER ID
  // AND CHECKOUT STATUS IS APPROVED
  useEffect(() => {
    if (
      isLoggedIn &&
      checkout?.attributes &&
      checkoutType === 'applyAndPay' &&
      customerIdCheckout === customerIdAuth &&
      status === 'approved'
    ) {
      history.push('/payment-plan/payment' + history.location.search);
      dispatch(
        updateCheckout({
          checkoutIdentifier: checkoutId,
          paymentDetails: checkout.attributes.paymentDetails,
          checkoutData: {
            type: checkout.attributes.type,
            redirectUrl: checkout.attributes.redirectUrl,
            expiresAt: checkout.attributes.expiresAt,
            step: CheckoutStep.CHECKOUT_STEP_PAYMENT,
          },
        })
      );
    }
  }, [isLoggedIn, history, checkoutType, customerIdCheckout, customerIdAuth, status]);

  // REDIRECT TO STEP 1 FORM IF CHECKOUT STATUS IS PENDING
  useEffect(() => {
    if (isLoggedIn && status === 'pending') {
      history.push('/payment-plan/customer-details' + history.location.search);
    }
  }, [isLoggedIn, history, checkoutType, status]);

  // REDIRECT TO PLAN RESULT IF CHECKOUT STATUS IS REVIEW OR DECLINED
  useEffect(() => {
    if (isLoggedIn && (status === 'review' || status === 'declined')) {
      history.push('/payment-plan/plan-result' + history.location.search);
    }
  }, [isLoggedIn, history, checkoutType, status]);

  return (
    <SCEcommerceLogin>
      <div className="ecommerce-image" />
      <div className="login-wrapper">
        <div className="login-header">
          <div className="logo">
            <IconLogo width={180} height={74} />
          </div>
          <h4>Login</h4>
        </div>
        <FormProvider {...reactHookForm}>
          <form onSubmit={handleSubmit(formSubmitHandler)} className="loginForm">
            <div data-testid="error-message">
              {errorMessage && (
                <div className="validation-msg-box">Incorrect verification code</div>
              )}
            </div>
            <ControlledInputField
              placeholder="Enter code"
              name="code"
              type="password"
              rules={{ required: true }}
            >
              Verification code
            </ControlledInputField>
            <Button
              withShadow
              disabled={formState.isValid === false || loading}
              maxWidth="100%"
              type="submit"
              iconPosition="right"
              icon={loading ? <SCSpinner /> : undefined}
            >
              Login
            </Button>
          </form>
        </FormProvider>
      </div>
    </SCEcommerceLogin>
  );
};

export default LoginApplicant;
