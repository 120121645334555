import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// TYPES
import { CloudCheckRequest } from '../../util/byronbay-api';

// UTILS
import { useQuery } from '../../util/hooks';
import { AppDispatch } from 'util/store';

// REDUX AND SLICES
import { useSelector, useDispatch } from 'react-redux';
import { cardCardCloudPayments } from '../../reducers/payment-slice';
import { RootState } from '../../reducers';

// OTHER COMPONENTS
import Loader from '../../components/loader';

// LAYOUT COMPONENTS
import LargerHeaderWithNavLayout from '../../layouts/large-header-with-nav-layout';

// STYLES AND STYLED COMPONENTS
import SCStep3 from './css';

const CloudPayments: React.FC = (checkout: any) => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const query = useQuery();

  const globalCheckoutState = useSelector((state: RootState) => state.checkout);
  const checkoutId = checkout.match.params.checkoutId || globalCheckoutState.checkout?.id;
  const customerId = localStorage.getItem('customerId') || '';

  // TODO VALIDATE THE HMAC TO ENSURE THE DATA IS COMING THROUGH CLOUD PAYMENTS
  const cloudPaymentsDto: CloudCheckRequest = {
    payment_provider: 'Fat Zebra',
    checkoutId: checkoutId,
    token: query.get('token'),
    card_holder: query.get('card_holder'),
    country: query.get('country'),
    cardNumber: query.get('card_number'),
    card_expiry: query.get('card_expiry'),
    card_type: query.get('card_type'),
  };

  useEffect(() => {
    dispatch(cardCardCloudPayments(checkoutId, customerId, cloudPaymentsDto)).then(() => {
      history.push(`/payment-plan/payment?checkoutId=${checkoutId}`);
    });
  }, [dispatch, cloudPaymentsDto, checkoutId, history]);

  return (
    <LargerHeaderWithNavLayout>
      <SCStep3>
        <div className="loading-wrapper">
          <Loader text="" />
        </div>
      </SCStep3>
    </LargerHeaderWithNavLayout>
  );
};

export default CloudPayments;
