import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useIsNz } from 'util/hooks';

interface SummaryProps {
  isEcommerce: boolean;
}

const SummaryPageTextBlock = ({ isEcommerce }: SummaryProps) => {
  const isNz = useIsNz();
  const idVerified = useSelector((state: RootState) => state.customer.customerDetails.isIdVerified);

  return (
    <div className="summary-text-block">
      <h4>Customer Disclosure and Consent</h4>
      {isNz ? (
        <ul className="policy">
          {isEcommerce ? (
            <>
              <li>
                You have been sent an SMS containing a verfication code and a link to Payright's Ts
                and Cs and Privacy Policy.
              </li>
              <li>
                By providing the verification code as a part of this application you are agreeing
                to:
              </li>
            </>
          ) : (
            <li>By submitting your loan application:</li>
          )}
          <div>
            <ul>
              <li>
                You acknowledge you have been provided and understand the repayment schedule for
                this loan including the fees and charges and consent to this schedule;
              </li>
              <li>
                You consent to Payright conducting a credit check and electronic ID verification
                check using your personal information for the purpose of processing your
                application. This credit check will create a visible record on your credit file. For
                details of how Payright will collect, hold and use your personal information, how
                your personal information will be disclosed to third parties (including credit
                agencies), how you can access or update personal information or make a complaint or
                inquiry relating to you personal information, please refer to the Privacy Policy on
                the Payright website; and
              </li>
              <li>
                You have read and consent to the loan Terms and Conditions also available on the
                Payright website.
              </li>
              <li>
                You consent to receiving all communications, notices and other documents from
                Payright (including all disclosure statements) in electronic form including SMS and
                email. This consent includes all continuous disclosure information (such as account
                statements) which will be made available to you via your online account at
                payright.co.nz
              </li>
            </ul>
          </div>
          <li>You have also been sent the above information via email.</li>
        </ul>
      ) : (
        <>
          <p>
            You have been sent an SMS containing a <span className="bold">Verification Code</span>{' '}
            and a link to Payright’s Terms and Conditions and Privacy Policy.
          </p>
          <p>
            For details of how Payright will collect, hold and use your personal information, how
            your personal information will be disclosed to third parties (including credit
            agencies), how you can access or update personal information or make a complaint or
            enquiry relating to your personal information, please refer to the{' '}
            <a href="https://payright.com.au/privacy-policy/" target="_blank">
              Privacy Policy
            </a>
            .
          </p>
          <p>By submitting your loan application:</p>

          <ul className="policy">
            <div>
              <ul>
                <li>
                  You acknowledge you have been provided and understand the repayment schedule for
                  this loan including the fees and charges and consent to this schedule.
                </li>
                <li>
                  You acknowledge that you have read and consent to the loan Terms and Conditions
                  available on Payright's website.
                </li>
                <li>
                  You consent to Payright conducting a credit check with a credit reporting body
                  using your personal information for the purpose of processing your application.
                  This credit check will create a visible record on your credit file.
                </li>
                {!idVerified && (
                  <li>
                    You consent to Payright conducting an electronic ID verification check using
                    your personal information (being your full name, date of birth, residential
                    address and drivers' licence number or passport number) for the purpose of
                    processing your application. Your identity will be checked through third party
                    sources which may include a credit reporting body. This will not create a record
                    on your credit file. If Payright conducts an electronic ID verification check
                    with a credit reporting body, Payright may request the credit reporting body to
                    provide an assessment of whether your personal information matches personal
                    information held by the credit reporting body. The credit reporting body may
                    prepare and provide to Payright such an assessment and the credit reporting body
                    may use your personal information, and personal information it holds relating to
                    other individuals (such as their names, dates of birth, residential addresses,
                    drivers licence and passport numbers) for the purpose of preparing such an
                    assessment. If you do not consent to the electronic ID check, please cancel your
                    application now and email manualid@payright.com.au to request instructions on
                    how to complete your identity verification manually. With a manual verification
                    process, Payright will require certified copies of your identity information
                    which may cause delays in setting up your Payright account partly due to this
                    application being cancelled and you needing to re-apply once we receive all the
                    required documents.
                  </li>
                )}
              </ul>
            </div>
          </ul>
        </>
      )}
    </div>
  );
};
export default SummaryPageTextBlock;
