import React from 'react';
import { IconLogo, Card, Button, media, Alert, IconAttention } from '@payright/web-components';
import styled from 'styled-components';
import Loader from '../components/loader';
import getConstants from '../util/constants';

interface LoginOrSignupProps {
  queryParamCheckoutId: string | null;
  loginClickHandler?: React.MouseEventHandler;
  signupClickHandler?: React.MouseEventHandler;
  customerAttached: string | null;
  hasErrors: any;
  loading: boolean;
}

const SCLoginOrSignup = styled.div`
  padding-bottom: 20px;

  .card {
    margin-bottom: 35px;
  }

  .logo {
    width: 100%;
    max-width: 160px;
    margin-top: 2em;
    margin-bottom: 2em;
  }

  ${media.min.medium} {
    .logo {
      display: none;
    }
  }

  ${media.min.tablet} {
    button {
      max-width: 160px;
    }
  }
`;

// AUTO-FETCH AU (or NZ) CONTACT US DETAILS
const { CONTACT_US_PHONE_NUMBER, CARE_EMAIL } = getConstants();

const LoginOrSignup = ({
  queryParamCheckoutId,
  loginClickHandler,
  signupClickHandler,
  customerAttached,
  hasErrors,
  loading,
}: LoginOrSignupProps) => {
  return (
    <SCLoginOrSignup className="login-or-signup">
      <IconLogo height={43} className="logo" />
      {hasErrors && (
        <Alert
          title={'Invalid plan'}
          body={
            <div>
              <p>The requested plan could not be found. </p>{' '}
              <p>
                Please contact our customer support team via email at{' '}
                <a href={`mailto:${CARE_EMAIL}`}>{CARE_EMAIL}</a>, or <br />
                phone <a href={`tel:${CONTACT_US_PHONE_NUMBER}`}>{CONTACT_US_PHONE_NUMBER}</a>.
              </p>
            </div>
          }
          outcome="error"
          icon={<IconAttention />}
        />
      )}
      {loading && <Loader />}
      {!hasErrors && (
        <>
          {!customerAttached && (
            <Card title="I'm new to Payright">
              <p>I've never used Payright before and need to setup an account.</p>
              <br />
              <Button
                disabled={queryParamCheckoutId ? false : true}
                handleClick={signupClickHandler}
              >
                Sign Up
              </Button>
            </Card>
          )}
          <Card title={customerAttached ? 'Login to Payright' : 'I have a Payright account'}>
            <p>
              {customerAttached
                ? 'Login to complete payment for this checkout'
                : 'I have existing login details for Payright and would like to log in using my account'}
            </p>
            <br />
            <Button
              disabled={queryParamCheckoutId ? false : true}
              outline
              colour="blue"
              handleClick={loginClickHandler}
            >
              Login
            </Button>
          </Card>
        </>
      )}
    </SCLoginOrSignup>
  );
};

export default LoginOrSignup;
