import { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';

// UTILS
import { useQuery, useTotalSteps } from '../../util/hooks';
import getConstants from '../../util/constants';
import { PlanApprovalCheckOutcome, PlanApprovalCheckMessageCode } from '../../util/byronbay-api';
import { useConfig } from 'providers/config-provider';

// REDUX AND SLICES
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { fetchMerchantData } from '../../reducers/auth-slice';
import { fetchExistingData } from '../../reducers/customer-slice';
import {
  checkoutApproval,
  fetchCheckoutAttributes,
  checkoutValidate,
  checkoutCheckApprovalOutcome,
} from '../../reducers/checkout-slice';
import { fetchCustomerPaymentMethods } from '../../reducers/payment-methods-slice';
import { AppDispatch } from 'util/store';

// WEB COMPONENTS
import { PaymentSummary, Button, Alert, IconAttention, Loader } from '@payright/web-components';

// OTHER COMPONENTS
import { StepHeader } from '../../components/step-header';
import FeeDetails from '../../components/fee-details';
import SCSpinner from '../../components/spinner';
import VerificationCode from '../../components/verification-code';

// LAYOUT COMPONENTS
import LargerHeaderWithNavLayout from '../../layouts/large-header-with-nav-layout';

// STYLES AND STYLED COMPONENTS
import SCStep2 from './css';

// EVENT HANDLERS
import { cancellationClickHandler, approvalResultHandler } from '../../util/handlers';

import { PlanResultType } from '../../types/app';

type Step2Props = {};

type FormData = {
  verificationcode?: string;
};

const { PAYMENT_FREQUENCIES, REDIRECT_URL } = getConstants();

const Step2 = (props: Step2Props) => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();

  // URL QUERY PARAMS
  const query = useQuery();
  const queryParamCheckoutId = query.get('checkoutId');

  // GLOBAL STATE
  const globalAuthState = useSelector((state: RootState) => state.auth);
  const globalCheckoutState = useSelector((state: RootState) => state.checkout);
  const globalPaymentState = useSelector((state: RootState) => state.payment);
  const globalPaymentMethodsState = useSelector((state: RootState) => state.paymentMethods);
  const globalCustomerState = useSelector((state: RootState) => state.customer);

  // VARIABLES
  const isLoggedIn = globalAuthState.isLoggedIn;
  const config = useConfig();
  const totalSteps = useTotalSteps();
  const merchantData = globalAuthState.merchantRates;
  const { hasErrors, errorTitle, errorMessage } = globalCheckoutState;
  const checkout = globalCheckoutState.checkout;
  const checkoutId = globalCheckoutState.checkout?.id || queryParamCheckoutId || '';
  const applicationCompleteBy = globalCheckoutState.checkout?.applicationCompletedBy || '';
  const planId = globalCheckoutState.checkout?.planId;
  const customerId = globalCheckoutState.checkout?.customerId || globalAuthState.customerId;
  const redirectUrl = globalCheckoutState.checkout?.attributes?.redirectUrl || REDIRECT_URL;
  const paymentMethods = globalPaymentMethodsState.paymentMethods;
  const repeatCustomer = globalCustomerState?.accountDetails?.isReturnCustomer;

  const loading = globalAuthState.loading;
  const loadingCheckout = globalCheckoutState.loading;
  const loadingPayment = globalPaymentState.loading;
  const loadingPaymentMethods = globalPaymentMethodsState.loading;

  // REFS TO SCROLL TO
  const mainBlockRef = useRef<HTMLDivElement>(null);

  // START VERIFICATION FORM
  const reactHookForm = useForm<FormData>({
    defaultValues: {
      verificationcode: '',
    },
  });

  const { handleSubmit } = reactHookForm;

  // CHECKOUT PLAN VALIDATION
  const summarySubmitHandler = async (formData: FormData) => {
    const code = formData?.verificationcode;

    if (!planId || !customerId || !checkout) {
      return;
    }

    // CCCFA flow
    if (config.cccfaEnabled) {
      // Check the validation code for Ecommerce. Not required for customer led, they'd have
      // already entered the code in the beginning of the application
      if (applicationCompleteBy === 'Ecommerce') {
        const validationCodeResult = await dispatch(checkoutValidate(planId, checkoutId, code));
        if (validationCodeResult === false) {
          return; // Error message will be retrieved from Redux
        }
      }

      const res = await dispatch(checkoutCheckApprovalOutcome(checkoutId));

      // handle void
      if (!res) {
        return;
      }

      const { outcome, messageCode } = res;

      if (outcome === PlanApprovalCheckOutcome.APPROVED) {
        history.push('/payment-plan/income-and-expenses' + history.location.search);
        return;
      }

      // i.e. No wages or income
      if (
        outcome === PlanApprovalCheckOutcome.REVIEW &&
        messageCode === PlanApprovalCheckMessageCode.BSO_VALIDITY_REVIEW
      ) {
        history.push('/payment-plan/plan-result' + history.location.search, {
          planResultType: PlanResultType.BSO_NOT_VALID,
        });
        return;
      }

      // i.e. Credit file not found
      if (outcome === PlanApprovalCheckOutcome.REVIEW) {
        history.push('/payment-plan/plan-result' + history.location.search);
        return;
      }

      // i.e. Declined, or Ecommerce fell into Review
      if (
        outcome === PlanApprovalCheckOutcome.DECLINED ||
        outcome === PlanApprovalCheckOutcome.CANCELLED
      ) {
        history.push('/payment-plan/plan-result' + history.location.search);
        return;
      }

      // Error message will be retrieved from Redux and displayed if any
      return;
    } else {
      // Standard flow

      // Check the validation code for Ecommerce. Not required for customer led, they'd have
      // already entered the code in the beginning of the application
      if (applicationCompleteBy === 'Ecommerce') {
        const validationCodeResult = await dispatch(checkoutValidate(planId, checkoutId, code));
        if (validationCodeResult === false) {
          return; // Error message will be retrieved from Redux
        }
      }

      const approvalResult = await dispatch(checkoutApproval(planId, checkoutId));
      approvalResultHandler(approvalResult, checkout, paymentMethods, history, dispatch);
    }
  };

  // SCROLL TO TOP ON PAGE LOAD
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // DISPLAY AND SCROLL TO ALERT BOX WHEN THERE IS AN ERROR
  useEffect(() => {
    if (hasErrors && typeof mainBlockRef?.current?.scrollIntoView === 'function') {
      mainBlockRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [hasErrors, mainBlockRef]);

  // LOAD LATEST CHECKOUT FROM THE SERVER
  useEffect(() => {
    if (queryParamCheckoutId !== null) {
      dispatch(fetchCheckoutAttributes(queryParamCheckoutId));
    }
  }, [queryParamCheckoutId, checkoutId, dispatch]);

  // LOAD EXISTING CUSTOMER DETAILS, PAYMENT METHODS, AND MERCHANT DATA
  useEffect(() => {
    if (customerId && checkoutId) {
      dispatch(fetchExistingData(checkoutId, customerId));
      dispatch(fetchCustomerPaymentMethods(checkoutId, customerId));
      dispatch(fetchMerchantData(checkoutId));
    }
  }, [customerId, checkoutId, dispatch]);

  // REDIRECT TO LANDING PAGE IF NO CHECKOUT ID IN URL (DOES NOT CHECK IF VALID ID)
  useEffect(() => {
    if (!queryParamCheckoutId) {
      history.push('/');
    }
  }, [history, queryParamCheckoutId]);

  // REDIRECT TO LANDING PAGE IF CHECKOUT EXPIRED
  useEffect(() => {
    if (checkout && checkout.attributes && checkout.attributes.expiresAt !== undefined) {
      if (checkout.attributes?.expiresAt < new Date().toISOString()) {
        history.push('/');
      }
    }
  }, [history, checkout]);

  // REDIRECT TO LANDING PAGE IF NOT LOGGED IN
  useEffect(() => {
    if (!isLoggedIn) {
      history.push('/');
    }
  }, [history, isLoggedIn]);

  //TODO - useIsEcommerce
  const isEcommerce = applicationCompleteBy === 'Ecommerce';
  return (
    <LargerHeaderWithNavLayout>
      <SCStep2>
        {loading || loadingCheckout || loadingPayment || loadingPaymentMethods ? (
          <div className="loading-wrapper">
            <Loader text="" />
          </div>
        ) : (
          <div className="step-body">
            <StepHeader activeStep={2} totalSteps={totalSteps} title="" />
            <div className="payment-summary">
              <PaymentSummary
                paymentDetails={globalCheckoutState.paymentDetails}
                paymentFrequencies={PAYMENT_FREQUENCIES}
                rates={merchantData.rates}
                establishmentFees={merchantData.establishmentFees}
                otherFees={merchantData.otherFees}
                goBack={() => {
                  history.push('/payment-plan/customer-details', +history.location.search);
                }}
                isEditEnabled={false}
                repeatCustomer={repeatCustomer}
              >
                <FeeDetails
                  accountKeepingFee={merchantData.otherFees.monthlyAccountKeepingFee}
                  processingFee={merchantData.otherFees.paymentProcessingFee}
                  paymentFrequency={globalCheckoutState.paymentDetails.paymentFrequency}
                />
              </PaymentSummary>
            </div>

            <FormProvider {...reactHookForm}>
              <form onSubmit={handleSubmit(summarySubmitHandler)}>
                <VerificationCode isEcommerce={isEcommerce} customerId={customerId} />
                <Button
                  className="submit-button"
                  withShadow
                  size="big"
                  iconPosition="right"
                  disabled={loadingCheckout}
                  icon={loadingCheckout ? <SCSpinner /> : undefined}
                >
                  Submit
                </Button>
                <Button
                  className="cancel-button-secondary"
                  type="button"
                  maxWidth="100%"
                  outline
                  colour="blue"
                  handleClick={() => {
                    // Initiate plan cancellation, upon button click
                    cancellationClickHandler(checkoutId, redirectUrl, dispatch);
                  }}
                >
                  Cancel
                </Button>
                {hasErrors && (errorTitle || errorMessage) && (
                  <div className="invalid-validation">
                    <div ref={mainBlockRef} style={{ position: 'relative', top: '-2em' }}></div>
                    <Alert
                      title={errorTitle || ''}
                      body={errorMessage || ''}
                      outcome="error"
                      icon={<IconAttention />}
                    />
                  </div>
                )}
                <hr />
              </form>
            </FormProvider>
          </div>
        )}
      </SCStep2>
    </LargerHeaderWithNavLayout>
  );
};

export default Step2;
