import React from 'react';
import styled from 'styled-components';
import { media } from '@payright/web-components';
import { calculateAccountKeepingFee } from '../util/helpers';
import { PaymentFrequency } from 'types/customer';

type FeeDetailsProps = {
  accountKeepingFee: number;
  processingFee: number;
  paymentFrequency: PaymentFrequency;
};

const SCFeeDetails = styled.p`
  margin: 1.5em 0 1em;
  color: #6e6e6e;
  line-height: 1.8;
  font-size: 0.94rem;

  ${media.max.tablet} {
    font-size: 0.86em;
  }
`;

const FeeDetails = ({ accountKeepingFee, processingFee, paymentFrequency }: FeeDetailsProps) => {
  return (
    <SCFeeDetails>
      * Included in the above {paymentFrequency} repayment is an{' '}
      <span className="bold">
        account keeping fee of $
        {calculateAccountKeepingFee(accountKeepingFee, paymentFrequency).toFixed(2)}
      </span>{' '}
      and a <span className="bold">payment processing fee of ${processingFee.toFixed(2)}.</span>{' '}
      These fees are not duplicated if you have more than one plan.
    </SCFeeDetails>
  );
};

export default FeeDetails;
