import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MarketingCheckbox from './marketing-checkbox';
import { updateCustomerPromoConsent } from '../reducers/customer-slice';
import { CustomerResource } from 'types/customer';
import { RootState } from '../reducers';
import { useIsNz } from 'util/hooks';

interface marketingConsentProps {
  customerId: string | null;
  checkPromoConsent: boolean | undefined;
  isEcommerce?: boolean;
}

const MarketingConsent = ({
  customerId,
  checkPromoConsent,
  isEcommerce,
}: marketingConsentProps) => {
  const [merchantMarketingSelection, setMerchantMarketingSelection] = useState<boolean | undefined>(
    checkPromoConsent
  );
  const dispatch = useDispatch();
  const checkout = useSelector((state: RootState) => state.checkout);

  const checkoutId = checkout?.checkout?.id || '';

  const handleUpdateStatus = () => {
    setMerchantMarketingSelection(!merchantMarketingSelection);

    const promoOptIn: CustomerResource = {
      consentPromoMaterial: !merchantMarketingSelection,
      checkoutId,
    };

    if (customerId) dispatch(updateCustomerPromoConsent(checkoutId, customerId, promoOptIn));
  };

  const isNz = useIsNz();

  const text = isNz
    ? 'Check this box if you consent to receiving promotional material (including emails) from Payright.'
    : 'Tick this box to consent to receiving promotional material from Payright.';

  return (
    <MarketingCheckbox
      selected={merchantMarketingSelection || false}
      handleClick={handleUpdateStatus}
      marketingOptInText={text}
    />
  );
};

export default MarketingConsent;
