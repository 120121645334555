import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Alert, IconAttention } from '@payright/web-components';
import { useHistory } from 'react-router-dom';
import { useFeatures, FeatureFlags } from 'providers/features-provider';

interface MaintenancePageProps {
  children?: React.ReactNode;
}

const SCMaintenancePage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .content {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;
  }
`;

const MaintenancePage = (props: MaintenancePageProps) => {
  const history = useHistory();
  const { features, flagsConfig } = useFeatures();

  let heading = 'Maintenance';
  let message =
    'Payright services are currently undergoing some maintenance, we will be back online shortly.';

  let maintenanceConfig = features.getValue(FeatureFlags.APP_MAINTENANCE_MODE);
  if (flagsConfig[FeatureFlags.GLOBAL_MAINTENANCE_MODE]) {
    maintenanceConfig = features.getValue(FeatureFlags.GLOBAL_MAINTENANCE_MODE);
  }

  if (maintenanceConfig) {
    const maintenanceJson = JSON.parse(maintenanceConfig as string);

    heading = maintenanceJson?.heading || heading;
    message = maintenanceJson?.message || message;
  }

  // Direct back to '/' is maintenance mode is off
  useEffect(() => {
    if (
      !flagsConfig[FeatureFlags.GLOBAL_MAINTENANCE_MODE] &&
      !flagsConfig[FeatureFlags.APP_MAINTENANCE_MODE]
    ) {
      history.push('/');
    }
  }, [flagsConfig]);

  return (
    <SCMaintenancePage>
      <div className="content">
        <Alert
          title={heading}
          body={
            <div>
              <p>{message}</p>
            </div>
          }
          outcome="error"
          icon={<IconAttention />}
        />
      </div>
    </SCMaintenancePage>
  );
};

export default MaintenancePage;
