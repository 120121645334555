import React, { useContext } from 'react';
import { envIsTrue } from 'util/helpers';

export type Config = {
  cccfaEnabled: boolean;
  multibankEnabled: boolean;
};

type ConfigProviderProps = {
  children?: React.ReactNode;
};

const ConfigContext = React.createContext<Config | undefined>(undefined);

export const useConfig = () => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useConfig must be used within an ConfigProvider');
  }
  return context;
};

export const ConfigProvider = ({ children }: ConfigProviderProps) => {
  const config: Config = {
    cccfaEnabled: envIsTrue('REACT_APP_FEATURE_FLAG_CCCFA'),
    multibankEnabled: envIsTrue('REACT_APP_BSO_MULTI_BANK'),
  };

  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};
