import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';

// UTILS
import { useQuery } from '../../util/hooks';

// REDUX AND SLICES
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { forgotPassword } from '../../reducers/auth-slice';
import { fetchCheckoutAttributes } from '../../reducers/checkout-slice';

// WEB COMPONENTS
import { Button, IconLogo } from '@payright/web-components';

// OTHER COMPONENTS
import { ControlledInputField } from '../../components/form-fields';
import SCSpinner from '../../components/spinner';

// STYLES AND STYLED COMPONENTS
import SCEcommerceLogin from './css';

interface LoginProps {}

type FormData = {
  userName: string;
};

const ForgotPassword = (props: LoginProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  // URL QUERY PARAMS
  const query = useQuery();
  const queryParamCheckoutId = query.get('checkoutId');

  // GLOBAL STATE
  const globalAuthState = useSelector((state: RootState) => state.auth);

  // LOCAL STATE
  const [user, setUser] = useState('');

  // VARIABLES
  const { errorMessage, successMessage, loading, passwordReset } = globalAuthState;

  // START FORGOT PASSWORD FORM
  const reactHookForm = useForm<FormData>({
    defaultValues: {
      userName: '',
    },
    mode: 'onChange',
  });
  const { handleSubmit, formState } = reactHookForm;

  const formSubmitHandler = (formData: FormData) => {
    dispatch(forgotPassword(formData.userName));
    setUser(formData.userName);
  };
  // END FORGOT PASSWORD FORM

  // LOAD LATEST CHECKOUT FROM THE SERVER
  useEffect(() => {
    if (queryParamCheckoutId) {
      dispatch(fetchCheckoutAttributes(queryParamCheckoutId));
    }
  }, [queryParamCheckoutId, dispatch]);

  // REDIRECT TO CHANGE PASSWORD IF FORGOT (RESET) PASSWORD SUCCESS
  useEffect(() => {
    if (passwordReset) {
      history.push('/change-password' + history.location.search, { params: user });
    }
  }, [passwordReset, user, history]);

  return (
    <SCEcommerceLogin>
      <div className="ecommerce-image" />
      <div className="login-wrapper">
        <div className="login-header">
          <div className="logo">
            <IconLogo width={180} height={74} />
          </div>
          <h4>Reset Password</h4>
        </div>
        <FormProvider {...reactHookForm}>
          <form onSubmit={handleSubmit(formSubmitHandler)} className="loginForm">
            <div data-testid="error-message">
              {errorMessage && <div className="validation-msg-box">{errorMessage}</div>}
            </div>
            <div data-testid="success-message">
              {successMessage && <div className="validation-msg-box-success">{successMessage}</div>}
            </div>
            <ControlledInputField
              placeholder="Email address"
              name="userName"
              type="email"
              rules={{ required: true }}
            >
              Email Address
            </ControlledInputField>
            <div className="forgot-password">
              <Link to={`/login/${history.location.search}`}>
                <span className="hide-mobile">Go back to login</span>
                <span className="mobile-only">Go back to login</span>
              </Link>
            </div>
            <Button
              withShadow
              disabled={formState.isValid === false || loading}
              maxWidth="100%"
              type="submit"
              iconPosition="right"
              icon={loading ? <SCSpinner /> : undefined}
            >
              Reset Password
            </Button>
          </form>
        </FormProvider>
      </div>
    </SCEcommerceLogin>
  );
};

export default ForgotPassword;
