export enum PlanResultType {
  PRE_APPROVAL = 'pre_approval',
  DECLINED = 'decline',
  SIFT_DECLINED = 'sift_decline',
  BSO_NOT_AVAILABLE = 'bso_not_available',
  BSO_NOT_VALID = 'bso_not_valid',
  REVIEW = 'review',
  APPROVED_STATEMENT_REQUESTED = 'Approved_Statement_Requested',
  APPROVED_STORE_DEPOSIT = 'approved_store_deposit',
  APPROVED_PAID = 'approved_paid',
}
