import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';
import payment from 'payment';
import { AppDispatch } from 'util/store';
import getConstants from 'util/constants';
import { RootState } from '../../reducers';
import {
  doAddCard,
  doPayment,
  addCardFailure,
  makePaymentFailure,
  doUpdatePaymentStatus,
  clearErrors,
} from '../../reducers/payment-slice';
import { doUpdatePaymentDate } from '../../reducers/customer-slice';
import { doMakeCardActive } from '../../reducers/payment-methods-slice';
import encrypt from 'util/encrypt';
import * as Sentry from '@sentry/react';

import PaymentDetails from '../payment-details-new';
import PaymentStartDate from 'components/payment/payment-start-date';
import ConfirmationMessage from './confirmation-message';
import SaveButton from './save-button';
import { shouldUpdatePaymentStatus } from '../../pages/step-3';
import { CheckoutStep, CheckoutType } from '../../types/checkout';

type EwayFormProps = {
  handleCardChange: (cardId: string) => void;
  clickChangeCard?: boolean;
  selectedCardId?: any;
  activeCardId?: string;
  hasValidCardSelected?: boolean;
};

type FormData = {
  selectedPaymentDate: Date | null;
  cardHolderName: string | null;
  csv: string | null;
  expiry: string | null;
  selectedMonthlyDate: string | null;
};

const { REDIRECT_URL } = getConstants();

const EwayForm = ({
  handleCardChange,
  clickChangeCard,
  selectedCardId,
  activeCardId,
  hasValidCardSelected = false,
}: EwayFormProps) => {
  const dispatch: AppDispatch = useDispatch();

  const checkout = useSelector((state: RootState) => state.checkout);
  const customer = useSelector((state: RootState) => state.customer);
  const repeatCustomer = customer?.accountDetails?.isReturnCustomer;

  const { loading: loadingPayment } = useSelector((state: RootState) => state.payment);
  const { paymentMethods } = useSelector((state: RootState) => state.paymentMethods);
  const { storeConfig } = useSelector((state: RootState) => state.auth);

  const checkoutId = checkout.checkout?.id || '';
  const planId = checkout.checkout?.planId || '';
  const customerId = checkout.checkout?.customerId || '';
  // Eway only supports AU so this fallback url is ok.
  const redirectUrl = checkout.checkout?.attributes?.redirectUrl || REDIRECT_URL;
  const checkoutType = checkout.checkout?.attributes?.type || CheckoutType.UNDEFINED;
  const checkoutStep = checkout.checkout?.attributes?.step || CheckoutStep.UNDEFINED;
  const customerNumber = customer.customerNumber || '';
  const { firstName, lastName } = customer.customerDetails;

  const depositAmount = checkout.checkout?.attributes?.depositPayable || 0;
  const depositTaker = storeConfig?.depositTaker || false;

  const [expiryMonth, setExpiryMonth] = useState<string>();
  const [expiryYear, setExpiryYear] = useState<string>();
  const [cardNumber, setCardNumber] = useState<string>();
  const [mode, setMode] = useState('add');

  // SET PAYMENT MODE
  useEffect(() => {
    setMode(paymentMethods.length > 0 ? 'existing' : 'add');
  }, [paymentMethods, setMode]);

  // SUBMIT PAYMENT
  const formSubmitHandler = async (formData: any) => {
    let actionsPerformed = 0;

    try {
      dispatch(clearErrors());

      // ADD CARD +/- MAKE PAYMENT
      if (mode === 'add' && customerId && customerNumber) {
        // GET CUSTOMER DETAILS
        if (customerNumber === null) {
          dispatch(
            addCardFailure({ errorMessage: 'Unable to add card -- customer record incomplete' })
          );
          throw Error('Unable to add card -- customer record incomplete');
        }
        // CARD EXPIRY
        const expiry = `${('0' + expiryMonth).slice(-2)}/${expiryYear}`;
        // CARD DETIALS
        const cardDetails = {
          card_holder: formData.cardHolderName,
          customer_id: customerId,
          card_type: payment.fns.cardType(formData.cardNo),
          payment_provider: 'Eway',
          number: encrypt(formData.cardNo.replace(/\s/g, '')),
          card_expiry: expiry,
          cvn: encrypt(formData.csv.replace(/\s/g, '')),
          customer_number: customerNumber,
          payment_collected_by_sugar: true, // TODO: GET this from STORE - can we use depositTaker here???
          customer_first_name: firstName,
          customer_last_name: lastName,
          checkoutId: checkoutId,
        };

        //ADD New CARD ONLY - PRE-APPROVAL CHECKOUTS and NON DEPOSIT TAKER
        if (planId && checkoutId && checkoutType === CheckoutType.PRE_APPROVAL && !depositTaker) {
          await dispatch(doAddCard(checkoutId, customerId, cardDetails));
          actionsPerformed++;
        }

        // ADD CARD ONLY - STORE IS THE DEPOSIT TAKER
        if (planId && checkoutId && depositTaker) {
          await dispatch(doAddCard(checkoutId, customerId, cardDetails));
          actionsPerformed++;
        }

        // MAKE PAYMENT - PRE-APPROVAL CHECKOUT TYPE AND PRE_APPROVAL_PAYMENT STEP
        if (
          planId &&
          checkoutId &&
          checkoutType === CheckoutType.PRE_APPROVAL &&
          checkoutStep === CheckoutStep.CHECKOUT_STEP_PRE_APPROVAL_PAYMENT &&
          !depositTaker &&
          depositAmount > 0
        ) {
          await dispatch(doPayment(checkoutId, customerId, planId));
          actionsPerformed++;
        }

        // ADD CARD AND MAKE PAYMENT - STANDARD AND APPLY AND PAY CHECKOUT TYPE  AND NON DEPOSIT TAKER
        if (planId && checkoutId && checkoutType !== CheckoutType.PRE_APPROVAL && !depositTaker) {
          await dispatch(doAddCard(checkoutId, customerId, cardDetails));
          actionsPerformed++;
          if (depositAmount > 0) {
            await dispatch(doPayment(checkoutId, customerId, planId));
            actionsPerformed++;
          }
        }
      } else {
        // EXISTING CARD, MAKE CREDIT CARD ACTIVE, AND MAKE PAYMENT
        if (clickChangeCard && checkoutId) {
          await dispatch(doMakeCardActive(checkoutId, selectedCardId, customerId));
          actionsPerformed++;
          if (
            customerId &&
            planId &&
            checkoutId &&
            !depositTaker &&
            depositAmount > 0 &&
            (checkoutType !== CheckoutType.PRE_APPROVAL ||
              (checkoutType === CheckoutType.PRE_APPROVAL &&
                checkoutStep === CheckoutStep.CHECKOUT_STEP_PRE_APPROVAL_PAYMENT))
          ) {
            // MAKE PAYMENT
            await dispatch(doPayment(checkoutId, customerId, planId));
            actionsPerformed++;
          }
        }
      }

      if (actionsPerformed > 0) {
        // if shouldUpdatePaymentStatus returns true then only we will update the payment status
        if (shouldUpdatePaymentStatus(checkoutType, checkoutStep)) {
          dispatch(doUpdatePaymentStatus(planId, checkoutId, depositAmount, depositTaker));
        }

        // UPDATE PAYMENT START DATE FOR RETURNING CUSTOMERS
        if (repeatCustomer === false) {
          const formattedDate = formData.selectedPaymentDate
            ? moment(formData.selectedPaymentDate).format('YYYY-MM-DD')
            : formData.selectedMonthlyDate;

          const paymentDateData = {
            checkoutId,
            paymentDetails: {
              paymentStartDate: formattedDate,
            },
          };
          if (paymentDateData.paymentDetails.paymentStartDate && customerId && checkoutId) {
            dispatch(doUpdatePaymentDate(checkoutId, customerId, paymentDateData));
          }
        }
      } else {
        dispatch(
          makePaymentFailure({
            errorMessage: 'Unexpected error when collecting payment details',
          })
        );
        Sentry.captureException(new Error('Unexpected error when collecting payment details'));
      }
    } catch (error) {
      // ERROR MESSAGES DISPATCHED VIA REDUX
    }
  };

  const { handleSubmit } = useFormContext<FormData>();

  return (
    <form onSubmit={handleSubmit(formSubmitHandler)}>
      <PaymentDetails
        provider="eway"
        onChange={handleCardChange}
        selectedCardId={selectedCardId}
        setExpiryMonth={setExpiryMonth}
        expiryMonth={expiryMonth}
        setExpiryYear={setExpiryYear}
        expiryYear={expiryYear}
        cardNumber={cardNumber}
        setCardNumber={setCardNumber}
        mode={mode}
        setMode={setMode}
      />

      {checkoutType === CheckoutType.PRE_APPROVAL &&
      checkoutStep === CheckoutStep.CHECKOUT_STEP_PAYMENT ? null : (
        <>
          <PaymentStartDate />
          <hr />
        </>
      )}

      {checkoutType === CheckoutType.PRE_APPROVAL &&
      checkoutStep === CheckoutStep.CHECKOUT_STEP_PAYMENT ? null : (
        <ConfirmationMessage depositAmount={depositAmount} depositTaker={depositTaker} />
      )}

      <hr />
      <SaveButton
        loading={loadingPayment}
        depositAmount={depositAmount}
        depositTaker={depositTaker}
        mode={mode}
        checkoutId={checkoutId}
        redirectUrl={redirectUrl}
        checkoutType={checkoutType}
        checkoutStep={checkoutStep}
        hasValidCardSelected={hasValidCardSelected}
      />
    </form>
  );
};

export default EwayForm;
