import { configureStore, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import rootReducer, { RootState } from '../reducers';
import { initialState as authInitialState } from '../reducers/auth-slice';
import LogRocket from 'logrocket';

// Check localStorage is there are any existing auth values. Preload the redux state with if it
// existing auth values exist
export const getPersistedLoginState = (): { auth?: typeof authInitialState } => {
  const authToken = localStorage.getItem('authToken');
  const customerId = localStorage.getItem('customerId');
  if (authToken && customerId) {
    return {
      auth: {
        ...authInitialState,
        authToken,
        customerId,
        isLoggedIn: true,
      },
    };
  } else {
    return {};
  }
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: getPersistedLoginState(),
  middleware: getDefaultMiddleware =>
    // Important: type definition must be overridden here otherwise TypeScript can't infer the redux related types properly
    getDefaultMiddleware().concat(LogRocket.reduxMiddleware()) as ReturnType<
      typeof getDefaultMiddleware
    >,
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, null, Action<string>>;

export default store;
