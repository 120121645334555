import React, { useEffect, useState, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import IframeResizer from 'iframe-resizer-react';
import { debounce } from 'debounce';
import styled from 'styled-components';
import Loader from './loader';
import { media, RadioButton, Alert, IconAttention } from '@payright/web-components';
import getConstants from '../util/constants';
import { useIsAu, useIsNz } from 'util/hooks';
import { useConfig } from 'providers/config-provider';

const { APPLICATIONS_EMAIL } = getConstants();

type BankStatementProps = {
  url: string;
  customerNumber: string;
  applicationCompleteBy: string;
  loading: boolean;
  onBsoSubmitted: (bsoSubmitted: boolean) => void;
  errors: {
    hasErrors: boolean;
    errorMessage: string | null | undefined;
    setErrors: (hasErrors: boolean, errorMessage?: string) => void;
  };
};

type BsoDetailsData = {
  provideBso: string;
};

enum IframePostMessageEvents {
  BANK_SELECTED = 'bank_selected',
  LOGIN_CLICKED = 'login_clicked',
  ACCOUNTS_SELECTED = 'accounts_selected',
  SUBMISSION_COMPLETE = 'submission_complete',
  SUBMISSION_ALL = 'submit_all',
}

const SCBankStatementDetails = styled.div<{ iframeHeight: number }>`
  position: relative;
  color: #6e6e6e;
  line-height: 1.8;

  ${media.max.tablet} {
    font-size: 0.86em;
  }
  h3 {
    font-size: 1.13em;
    color: ${props => props.theme.colours.red.base};
    margin-bottom: 0.5em;
  }
  .content {
    margin: 0;
  }
  .bso-content {
    h3 {
      font-size: 1.13em;
      color: ${props => props.theme.colours.blue.base};
      margin-bottom: 0.5em;
    }
    p {
      color: #6e6e6e;
      line-height: 1.8;
      margin-bottom: 1em;
    }
    strong {
      font-weight: 600;
    }
    ul {
      list-style: disc;
      padding-left: 1.2em;
      margin-bottom: 1em;

      li {
        list-style-position: outside;
        margin-left: 1.2em;
      }
    }
  }
  .loading-overlay {
    display: none;
    z-index: 999;
    position: absolute;
    width: 260px;
    height: 300px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
  .loading-iframe-overlay {
    display: none;
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    background-color: white;
  }
  .show-loading {
    display: block;
  }
  .bso-wrapper {
    opacity: 1;
  }
  .hide-bso {
    opacity: 0.08;
  }
  iframe {
    width: 100%;
    border: 0px;
  }
  .bso-iframe {
    max-height: 1px;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
  }
  .show-bso-iframe {
    // Have to deal in explicit values for aminating max height
    max-height: ${props => props.iframeHeight}px;
    transition: max-height 0.25s ease-in;
  }
  .bso-iframe-wrapper {
    position: relative;
    min-height: 430px;
  }
  .bso-iframe-wrapper-single-bank {
    position: relative;
    min-height: 400px;
    margin-top: 2em;
  }
  .options-separator {
    margin-bottom: 0.2em;
  }
  .options-section {
    margin-top: 1em;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .options-section > div {
    flex: 1;
    margin: 0.3rem;
  }
`;

const SCBankLogosGrid = styled.div`
  .grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(5, 1fr);

    img {
      display: block;
      max-width: 100%;
      height: auto;
      background: white;
      padding: 0.3rem;
      margin-bottom: 0.2rem;
    }

    ${media.max.tablet} {
      grid-template-columns: repeat(4, 1fr);

      img:nth-child(9) {
        grid-column-start: 2;
      }
    }
  }
`;

const BankStatement = ({
  url,
  customerNumber,
  applicationCompleteBy,
  loading,
  onBsoSubmitted,
  errors,
}: BankStatementProps) => {
  const { watch } = useFormContext<{ bsoDetails: BsoDetailsData }>();
  const IFRAME_MIN_HEIGHT = 900;
  const source_url = new URL(url);
  const provideBso: string = watch('provideBso');
  const emailOption = `No. I will provide statements for the last 90 days via email to ${APPLICATIONS_EMAIL}`;
  const customerBsoUrl = source_url.href + '-' + customerNumber;
  const alertRef = useRef<HTMLInputElement>(null);
  const [showBankLogos, setShowBankLogos] = useState<boolean>(true);
  const [iframeResizing, setIframeResizing] = useState<boolean>(true);
  const [iframeHeight, setIframeHeight] = useState<number>(IFRAME_MIN_HEIGHT);

  const isAu = useIsAu();
  const isNz = useIsNz();
  const config = useConfig();

  const debouncedShowIframe = debounce(() => {
    setIframeResizing(false);
  }, 3000);

  const handleMessages = (event: any) => {
    if (event.origin !== source_url.origin) {
      return false;
    }

    if (event.data.includes('[iFrameSizer]')) {
      debouncedShowIframe();
      return;
    }

    // clear errors after any event message
    errors.setErrors(false);

    // If BSO Bank selected, hide 'bank logos grid'.
    if (JSON.parse(event.data).event === IframePostMessageEvents.BANK_SELECTED) {
      setShowBankLogos(false);
      return;
    }
    // Allow customer to continue
    if (JSON.parse(event.data).event === IframePostMessageEvents.SUBMISSION_ALL) {
      onBsoSubmitted(true);
      return;
    }
  };

  useEffect(() => {
    // Mount
    window.addEventListener('message', handleMessages, false);

    // Unmount
    return () => {
      // Remove the event listener
      window.removeEventListener('message', handleMessages, false);
    };
  }, []);

  // Scroll to alert
  // Todo: fix smooth scroll
  useEffect(() => {
    if (errors.hasErrors && alertRef.current) {
      alertRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  }, [errors.hasErrors]);

  return (
    <SCBankStatementDetails iframeHeight={iframeHeight}>
      <div className={`loading-overlay ${loading ? 'show-loading' : ''}`}>
        <Loader text="Validating bank statement data.." />
      </div>
      <div className={`bso-wrapper ${loading ? 'hide-bso' : ''}`}>
        <div className="bso-content">
          {!config.cccfaEnabled && (
            <p>
              To proceed with your application, we need to verify some of the financial information
              you have provided. To do this, Payright needs copies of your most recent bank
              statements. You can provide this information to Payright securely and quickly using
              Bank Statements Online provided by Illion. More information on this service can be
              found{' '}
              <a
                href="https://bankstatements.com.au/about"
                target="_blank"
                rel="noopener noreferrer"
              >
                here.
              </a>
            </p>
          )}
          {config.cccfaEnabled && (
            <>
              <h3>Why?</h3>
              <p>
                As part of NZ law under the Credit Contracts and Consumer Finance Act (CCCFA),
                lenders are required to verify your income and expenses to help assess your
                application for finance. To do this as easily as possible, we ask you to submit
                digital bank statements securely online.
              </p>
              <h3>How?</h3>
              <p>
                By partnering with leading credit insights provider{' '}
                <a href="https://bankstatements.com.au/about/faq" target="_blank">
                  {' '}
                  Illion
                </a>
                , we are able to do this easily, instantly and securely.
              </p>
              <p>Just follow these simple steps:</p>
              <ul>
                <li>
                  Simply start by choosing your bank, and enter your account details to login
                  (Please repeat this process for as many banks you have accounts in your name,
                  including joint accounts)
                </li>
                <li>Select your accounts from the list displayed, and click submit</li>
                <li>
                  Once all of your bank accounts are submitted, make sure to click `Finish` to
                  continue your application
                </li>
              </ul>
              <p>
                <strong>Have multiple accounts?</strong> Make sure you enter all personal bank
                accounts you have open - please do not submit any business accounts. This will help
                us review the full picture of your income and expenses, to better assess your
                application and ensure the plan is suitable for you.
              </p>
              <p>Please note: Payright will never have access to your bank accounts.</p>
              <h3>Ready to go?</h3>
              <p>Start your secure session by clicking `I Agree` below.</p>
            </>
          )}
          {!config.cccfaEnabled && (
            <>
              Are you happy to provide Payright with access to your bank statement information
              through this process?
              <div className="options-section">
                <Controller
                  name="provideBso"
                  render={({ onChange, value }) => {
                    return (
                      <>
                        <RadioButton
                          checked={value}
                          name="agree"
                          value="agree"
                          handleClick={(value: string) => {
                            errors.setErrors(false, '');
                            onChange(value);
                          }}
                        >
                          Yes I am happy to continue
                        </RadioButton>
                        {applicationCompleteBy === 'Customer' && (
                          <RadioButton
                            checked={value}
                            name="not_agree"
                            value="not_agree"
                            handleClick={(value: string) => {
                              errors.setErrors(false, '');
                              onChange(value);
                            }}
                          >
                            {emailOption}
                          </RadioButton>
                        )}
                        <RadioButton
                          checked={value}
                          name="application_cancel"
                          value="application_cancel"
                          handleClick={(value: string) => {
                            errors.setErrors(false, '');
                            onChange(value);
                          }}
                        >
                          I don't want to continue this application.
                        </RadioButton>
                      </>
                    );
                  }}
                />
              </div>
            </>
          )}
        </div>
        <hr className="options-separator" />
        <div ref={alertRef} />
        {errors.hasErrors && (
          <>
            <Alert
              title={'There was an error with your submission'}
              body={
                errors.errorMessage ||
                'There was an error with the bso submission, please try again'
              }
              outcome="error"
              icon={<IconAttention />}
            />
          </>
        )}
        <div
          className={`bso-iframe ${
            provideBso === 'agree' || config.cccfaEnabled ? 'show-bso-iframe' : ''
          }`}
        >
          <div
            className={`${
              config.multibankEnabled ? 'bso-iframe-wrapper' : 'bso-iframe-wrapper-single-bank'
            }`}
          >
            <div className={`loading-iframe-overlay ${iframeResizing ? 'show-loading' : ''}`}>
              <Loader text="Validating bank statement data.." />
            </div>
            <IframeResizer
              src={customerBsoUrl}
              id="bso_iframe"
              title="Bank Statements Online"
              checkOrigin={[source_url.origin]}
              heightCalculationMethod="lowestElement"
              tolerance={4}
              onResized={e => {
                // Let the iframe expand its height if its greater than 900px
                if (e.height > IFRAME_MIN_HEIGHT) {
                  setIframeHeight(e.height);
                }
              }}
            />

            {config.multibankEnabled && (
              <div>
                {showBankLogos && isAu && (
                  <SCBankLogosGrid>
                    <div className="grid">
                      <img src="../bank-logos/au/anz.png" alt="ANZ AU" />
                      <img src="../bank-logos/au/bankwest.png" alt="Bank West" />
                      <img src="../bank-logos/au/bendigo.png" alt="Bendigo" />
                      <img src="../bank-logos/au/boq.png" alt="BOQ" />
                      <img src="../bank-logos/au/cba.png" alt="CBA" />
                      <img src="../bank-logos/au/ing.png" alt="ING" />
                      <img src="../bank-logos/au/nab.png" alt="NAB" />
                      <img src="../bank-logos/au/stgeorge.png" alt="St. George" />
                      <img src="../bank-logos/au/suncorp.png" alt="Suncorp" />
                      <img src="../bank-logos/au/westpac.png" alt="WestPac" />
                      {/* <img src="../bank-logos/au/amex.png" alt="American Express" /> */}
                      {/* <img src="../bank-logos/au/banksa.png" alt="Bank SA" /> */}
                      {/* <img src="../bank-logos/au/bom.png" alt="BOM" /> */}
                      {/* <img src="../bank-logos/au/cua.png" alt="CUA" /> */}
                      {/* <img src="../bank-logos/au/greater.png" alt="Greater" /> */}
                      {/* <img src="../bank-logos/au/heritage.png" alt="Heritage" /> */}
                      {/* <img src="../bank-logos/au/macquarie.png" alt="Macquarie" /> */}
                      {/* <img src="../bank-logos/au/mebank.png" alt="ME Bank" /> */}
                      {/* <img src="../bank-logos/au/newcastle.png" alt="New Castle" /> */}
                      {/* <img src="../bank-logos/au/peopleschoicecu.png" alt="People's Choice CU" /> */}
                    </div>
                  </SCBankLogosGrid>
                )}
                {showBankLogos && isNz && (
                  <SCBankLogosGrid>
                    <div className="grid">
                      <img src="../bank-logos/nz/anznz.png" alt="ANZ NZ" />
                      <img src="../bank-logos/nz/asb.png" alt="ASB" />
                      <img src="../bank-logos/nz/bnz.png" alt="BNZ" />
                      <img src="../bank-logos/nz/coop.png" alt="The Cooperative Bank" />
                      <img src="../bank-logos/nz/firstcu.png" alt="First CU" />
                      <img src="../bank-logos/nz/kiwi.png" alt="Kiwi" />
                      <img src="../bank-logos/nz/nzcucentral.png" alt="NZ CU Central" />
                      <img src="../bank-logos/nz/sbs.png" alt="SBS" />
                      <img src="../bank-logos/nz/tsbnz.png" alt="TSB NZ" />
                      <img src="../bank-logos/nz/westpacnz.png" alt="WestPac NZ" />
                      {/* <img src="../bank-logos/nz/amex.png" alt="American Express" /> */}
                      {/* <img src="../bank-logos/nz/aotearoa.png" alt="Aotearoa CU" /> */}
                      {/* <img src="../bank-logos/nz/fishercu.png" alt="Fisher CU" /> */}
                      {/* <img src="../bank-logos/nz/gemvisa.png" alt="Gemvisa" /> */}
                      {/* <img src="../bank-logos/nz/heartland.png" alt="Heartland" /> */}
                      {/* <img src="../bank-logos/nz/nzcubaywide.png" alt="NZ CU Baywide" /> */}
                      {/* <img src="../bank-logos/nz/nzcusouth.png" alt="NZ CU South" /> */}
                      {/* <img src="../bank-logos/nz/nzhltransact.png" alt="NZ HL Transact" /> */}
                      {/* <img src="../bank-logos/nz/rabo.png" alt="Rabo" /> */}
                      {/* <img src="../bank-logos/nz/westforce.png" alt="Westforce" /> */}
                    </div>
                  </SCBankLogosGrid>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </SCBankStatementDetails>
  );
};

export default BankStatement;
