import { useContext, useMemo, useEffect, useState, createContext, ReactNode } from 'react';
import flagsmith from 'flagsmith';
import { IFlagsmith, IFlags, IRetrieveInfo, IState } from 'flagsmith/types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';

type FeaturesProviderProps = {
  children?: ReactNode;
};

// Subset of the initialisation options for Flagsmith
// https://docs.flagsmith.com/clients/javascript/#initialisation-options
type FlagsmithConfig = {
  environmentID: string; // your Flagsmith environment id
  api?: string; // the api you wish to use, important if self hosting
  headers?: object; // pass custom headers for flagsmith api calls
  AsyncStorage?: any; // an AsyncStorage implementation
  cacheFlags?: boolean; // whether to local storage flags, needs AsyncStorage defined
  preventFetch?: boolean; // whether to prevent fetching flags on init
  enableAnalytics?: boolean; // Enable sending flag analytics for getValue and hasFeature evaluations.
  enableLogs?: boolean; // whether to enable logs
  onChange?: (previousFlags: IFlags, params: IRetrieveInfo) => void; // triggered when the flags are retrieved
  state?: IState; // set a predefined state, useful for isomorphic applications
  onError?: (res: { message: string }) => void; // triggered if there was an api error
  defaultFlags?: IFlags; //
};

export enum FeatureFlags {
  CCCFA = 'cccfa',
  PT_1035 = 'cccfa/pt-1035-remove-leisure-and-expenses',
  DEBUG = 'interstellar/flagsmith-debug',
  GLOBAL_MAINTENANCE_MODE = 'global/maintenance-mode',
  APP_MAINTENANCE_MODE = 'interstellar/maintenance-mode',
}

type FlagsConfig = {
  [key in FeatureFlags]?: boolean;
};
type Context = {
  features: IFlagsmith;
  flagsConfig: FlagsConfig;
};

const FeaturesContext = createContext<Context | undefined>(undefined);

// useFeatures will return the initialised Flagsmith instance
export const useFeatures = () => {
  const context = useContext(FeaturesContext);
  if (context === undefined) {
    throw new Error('useFeatures must be used within a FeaturesProvider');
  }
  return context;
};

export const FeaturesProvider = ({ children }: FeaturesProviderProps) => {
  const history = useHistory();

  const [flagsConfig, setFlagsConfig] = useState<FlagsConfig>({});
  // Function to update the flagsConfig state
  const handleFlagsConfigUpdate = () => {
    let updatedFlagsConfig: FlagsConfig = {};
    for (const flagName of Object.values(FeatureFlags)) {
      updatedFlagsConfig[flagName] = flagsmith.hasFeature(flagName);
    }
    setFlagsConfig(prevFlagsConfig => ({
      ...prevFlagsConfig,
      ...updatedFlagsConfig,
    }));
  };

  const flagsmithConfig: FlagsmithConfig = useMemo(
    () => ({
      environmentID: process.env.REACT_APP_FLAGSMITH_ENV_ID ?? '',
      // This reduces the number of calls made to the api but also introduces some delay in
      // real time feature updates - ~30 seconds from initial testing.
      cacheFlags: true,
      enableAnalytics: true,
      enableLogs: false,
      onChange: (prevFlags, params) => {
        handleFlagsConfigUpdate();
        if (
          flagsmith.hasFeature(FeatureFlags.GLOBAL_MAINTENANCE_MODE) ||
          flagsmith.hasFeature(FeatureFlags.APP_MAINTENANCE_MODE)
        ) {
          history.push('/maintenance');
        }
      },
      onError: (res: { message: string }) => {
        if (flagsmith.hasFeature(FeatureFlags.DEBUG)) {
          if (res.message) console.error('Flagsmith Error: ', res.message);
        }
      },
      defaultFlags: {
        [FeatureFlags.PT_1035]: { enabled: false },
        [FeatureFlags.GLOBAL_MAINTENANCE_MODE]: { enabled: false },
        [FeatureFlags.APP_MAINTENANCE_MODE]: { enabled: false },
      },
    }),
    [history]
  );

  const globalCheckoutState = useSelector((state: RootState) => state.checkout);
  const globalAuthState = useSelector((state: RootState) => state.auth);

  const userType = 'contact';
  const contactId = globalCheckoutState.checkout?.contactId || '';
  const merchantId = globalAuthState.storeConfig?.merchantId || '';
  const storeId = globalAuthState.storeConfig?.id || '';

  const [isInitialised, setIsInitialised] = useState(false);
  const [isDebugEnabled, setIsDebugEnabled] = useState(false);

  // Initialise Flagsmith
  useEffect(() => {
    // Wait for the contactId before initialising Flagsmith
    if (contactId === '') return;
    if (isInitialised) return;

    // Init Flagsmith -- we identify as the contact who initiated the checkout
    flagsmith.init({ ...flagsmithConfig, identity: `${userType}_${contactId}` }).then(() => {
      setIsInitialised(true);

      flagsmith.startListening(5 * 60 * 1000); // poll every 5 minutes for any updates to the flags

      if (flagsmith.hasFeature(FeatureFlags.DEBUG)) {
        console.log('Flagsmith initialised');
      }

      const flagsmithState = flagsmith.getState();

      if (!flagsmithState.traits?.id || !flagsmithState.traits?.user_type) {
        flagsmith.setTraits({
          id: contactId,
          user_type: userType,
        });

        if (flagsmith.hasFeature(FeatureFlags.DEBUG)) {
          console.log('Base traits added');
        }
      }
    });
  }, [contactId, flagsmithConfig, isInitialised]);

  // Enable Flagsmith debug output via Flagsmith feature flag (after initialise)
  useEffect(() => {
    if (isInitialised && flagsmith.hasFeature(FeatureFlags.DEBUG) && !isDebugEnabled) {
      console.log('Flagsmith enabling debug');

      setIsDebugEnabled(true);

      const debugConfig = {
        ...flagsmithConfig,
        enableLogs: true,
      };

      flagsmith
        .init(debugConfig)
        .then(() => {
          console.log('Flagsmith reinitialised with debug enabled');
        })
        .catch(() => {
          console.log('Flagsmith failed to initialise with debug enabled');
        });
    }
  }, [isInitialised, flagsmithConfig, isDebugEnabled]);

  // Set Merchant_id and store_id traits if they're not correct
  useEffect(() => {
    if (isInitialised && merchantId !== '' && storeId !== '') {
      const flagsmithState = flagsmith.getState();

      if (
        !flagsmithState.traits?.merchant_id ||
        flagsmithState.traits?.merchant_id !== merchantId ||
        !flagsmithState.traits?.store_id ||
        flagsmithState.traits?.store_id !== storeId
      ) {
        flagsmith
          .setTraits({
            merchant_id: merchantId,
            store_id: storeId,
          })
          .then(() => {
            if (flagsmith.hasFeature(FeatureFlags.DEBUG)) {
              console.log('Merchant and Store traits added');
            }
          });
      }
    }
  }, [merchantId, storeId, isInitialised]);

  return (
    <FeaturesContext.Provider value={{ features: flagsmith, flagsConfig }}>
      {children}
    </FeaturesContext.Provider>
  );
};
