// Customer Resource structure as defined by Merchant API.
// The Merchant API returns Customers in this structure, and also expects this structure when creating/updating.
export type CustomerResource = {
  id?: string | null;
  checkoutId?: string | null;
  consentPromoMaterial?: boolean;
  customerDetails?: CustomerDetailsData;
  addressDetails?: AddressDetailsData;
  shippingDetails?: ShippingDetailsData;
  employmentDetails?: EmploymentDetailsData;
  identificationDetails?: IdentificationDetailsData;
  accountDetails?: AccountDetailsData;
};

export type GetCustomerResource = {
  id: string;
  customerNumber: string;
  customerDetails: CustomerDetailsData;
  addressDetails: AddressDetailsData;
  shippingDetails: ShippingDetailsData;
  employmentDetails: EmploymentDetailsData;
  identificationDetails: IdentificationDetailsData;
  accountDetails?: AccountDetailsData;
};

export type CustomerDetailsData = {
  firstName: string;
  lastName: string;
  middleName: string;
  title: string;
  email: string;
  phone: string;
  confirmEmail: string;
  dateOfBirth: string;
  password?: string;
  confirmPassword?: string;
  consentPromoMaterial?: boolean;
  bsoStatus?: BsoStatus;
  isIdVerified: boolean;
};

export type PasswordDetailsData = {
  password: string;
  confirmPassword: string;
};

export type AddressDetailsData = {
  street: string;
  suburb: string;
  state: string;
  postcode: string;
  residentialStatus: string;
  residentialTimeAtAddress: string;
  residentialTimeAtPreviousAddress: string;
};

export type Employed = {
  employmentType:
    | 'Full time'
    | 'Part Time'
    | 'Casual'
    | 'Self Funded Retiree'
    | 'Aged Pension'
    | 'Carers Pension'
    | 'Disability Pension'
    | 'Student'
    | 'Other'
    | 'Unemployed';
  employerName: string;
  employerPhone: string;
  timeInJob: string;
};

export type ShippingDetailsData = {
  street: string;
  suburb: string;
  state: string;
  postcode: string;
};

type SelfEmployed = {
  employmentType: 'Self Employed';
  abn: string;
  tradingName: string;
  tradingPhone: string;
};

export type Others = {
  employmentType:
    | 'Self Funded Retiree'
    | 'Aged Pension'
    | 'Carers Pension'
    | 'Disability Pension'
    | 'Student'
    | 'Other'
    | 'Unemployed';
};

export type EmploymentDetailsData = (Employed | SelfEmployed | Others) & {
  incomeAmount: number | null;
  incomeFrequency: string;
  otherIncomeAmount?: number;
  otherIncomeFrequency?: string;
};

export type IdentificationDetailsData =
  | IdentificationLicenseData
  | IdentificationPassportData
  | IdentificationOtherData;

export type IdentificationLicenseData = AuIdentificationLicenseData | NzIdentificationLicenseData;

export type AuIdentificationLicenseData = {
  identificationType: 'license';
  licenseNo: string;
  licenseCardNo: string;
  licenseIssuingState: string;
  licenseExpiry: string;
};

export type NzIdentificationLicenseData = {
  identificationType: 'license';
  licenseNo: string;
  licenseVersionNumber: string;
  licenseExpiry: string;
};

export type IdentificationPassportData = {
  identificationType: 'passport';
  passportNumber: string;
  passportExpiry: string;
};

export type IdentificationOtherData = {
  identificationType: 'other';
  otherIdType: string;
};

export type IdentificationType = 'license' | 'passport' | 'other';

export type PaymentFrequency = 'Weekly' | 'Fortnightly' | 'Monthly';

export type AccountDetailsData = {
  paymentFrequency: PaymentFrequency;
  accountKeepingFee: number;
  paymentProcessingFee: number;
  paymentStartDate: string;
  isReturnCustomer: boolean;
  nextRepaymentDate: string;
};

export enum BsoStatus {
  REQUESTED = 'Requested',
  OPT_MANUAL = 'Opted Manual',
  RECEIVED = 'Received',
  MANUALLY_RECEIVED = 'Manual Received',
  UNDEFINED = '',
}

export type LivingExpensesData = {
  grocery: number;
  utilities: number;
  accomadation: number;
  automotive: number;
  personal: number;
  leisure: number;
  education: number;
  other: number;
  total: number;
};
