/**
 * DEV NOTE!!!!
 *
 * There is a Region interface inside ./constants/index which contains all
 * these modules.
 * IF YOU ADD, REMOVE OR MODIFY A MODULES NAME PLEASE UPDATE THE INTERFACE!
 */

export const REGION_STATES = [
  { name: 'Northland', value: 'NTL' },
  { name: 'Auckland', value: 'AUK' },
  { name: 'Waikato', value: 'WKO' },
  { name: 'Bay of Plenty', value: 'BOP' },
  { name: 'Gisborne', value: 'GIS' },
  { name: "Hawke's Bay", value: 'HKB' },
  { name: 'Taranaki', value: 'TKI' },
  { name: 'Manawatū-Whanganui', value: 'MWT' },
  { name: 'Wellington', value: 'WGN' },
  { name: 'Tasman', value: 'TAS' },
  { name: 'Nelson', value: 'NSN' },
  { name: 'Marlborough', value: 'MBH' },
  { name: 'West Coast', value: 'WTC' },
  { name: 'Canterbury', value: 'CAN' },
  { name: 'Otago', value: 'OTA' },
  { name: 'Southland', value: 'STL' },
];

export const PAYMENT_FREQUENCIES = [
  { name: 'Weekly', value: 'Weekly' },
  { name: 'Fortnightly', value: 'Fortnightly' },
  { name: 'Monthly', value: 'Monthly' },
];

export const IDENTIFICATION_MAX_YEARS_EXPIRY_FROM_TODAY = 15;

export const BUSINESS_NUMBER = 'NZBN';
export const LABEL_STATE = 'Town / City';
export const LABEL_SUBURB = 'Suburb';
export const CUSTOMER_HUB_URL = 'https://customerhub.payright.co.nz';
export const FAQS_URL = 'https://payright.co.nz/faqs';
export const REDIRECT_URL = 'https://payright.co.nz';

// Contact
export const CONTACT_US_PHONE_NUMBER = '0800 338 496';
export const HELPDESK_EMAIL = 'admin@payright.co.nz';
export const APPLICATIONS_EMAIL = 'applications@payright.co.nz';
export const CARE_EMAIL = 'care@payright.co.nz';
export const TELL_YOUR_CUSTOMER_LINK = 'https://payright.co.nz';

// Verification
export const DEFAULT_MARKETING_CHECKBOX = false;

// Validation
export const PHONE_VALIDATION_REGEX = /^\(?(?:\+?64|0)2\)?(?:[ -]?[0-9]){6,8}[0-9]$|^([09]|[04]|[06]|[07]|[03]|[08]|[05]){2}[0-9]{7,8}$/;
export const MOBILE_VALIDATION_REGEX = /^\(?(?:\+?64|0)2\)?(?:[ -]?[0-9]){6,8}[0-9]$/;
export const LICENCE_VALIDATION_REGEX = /^[a-zA-Z]{2}[0-9]{6}$/;

// Phone no validation text
export const MOBILE_NUMBER_FORMAT_VALIDATION = 'Please enter a valid mobile number';

// Messages
export const MARKETING_CHECKBOX_TEXT =
  'The customer consents to receiving promotional emails and other electronic communications from Payright.';
export const SUCCESSFULL_NEW_PLAN_APPROVED_BODY =
  'By providing your payment information you are agreeing to the customer terms and conditions and information provided in the credit and disclosure statement sent to you via email. We will use your payment information to deduct your scheduled repayments.';
export const CUSTOMER_IDENTIFICATION =
  'The ID supplied must be a current New Zealand Passport or a New Zealand Driver’s Licence. The ID must not be expired.';

export const OTHER_INCOME_LABEL =
  'Other Income (Including Rental Income, NZ Work & Income Payments, and Pension/Super)';
