import { useEffect } from 'react';
import { logout } from '../../reducers/auth-slice';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../reducers';

const Logout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const globalAuthState = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (!globalAuthState.isLoggedIn) {
      history.push('/');
    }
  }, [history, globalAuthState.isLoggedIn]);

  return null;
};

export default Logout;
