import styled from 'styled-components';

const SCEcommerceNewPlan = styled.div`
  hr {
    border-style: solid;
    border-bottom: none;
    border-color: ${props => props.theme.colours.grey.altlight};
    margin: 2.23em 0 3em;
  }
  .cancel-button {
    margin-top: -1em;
    margin-bottom: 2em;
  }
  .cancel-button-secondary {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .next-button {
    margin-top: 0em;
  }
  .button-actiongroup-wrapper {
    padding: 30px 0px 30px 0px;
    display: flex;
    justify-content: space-between;
    margin-top: -2em;
  }
  .search-error {
    height: 1px;
    padding: 0;
    margin-top: 2em;
  }
  .anchorTop {
    height: 1px;
    padding-top: 2em;
    margin-top: -3em;
  }
  .anchor {
    height: 1px;
    padding: 0;
    margin-top: -2em;
  }
`;

export default SCEcommerceNewPlan;
