import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller, FormProvider } from 'react-hook-form';

// TYPES
import { CustomerResource } from '../../types/customer';

// UTILS
import { useQuery, useTotalSteps } from '../../util/hooks';
import { AppDispatch } from '../../util/store';
import getConstants from '../../util/constants';
import { useConfig } from 'providers/config-provider';
import { NextStep } from '../../types/checkout';
import { ApplicationCompletedBy } from '../../types/plan';
import { PlanResultType } from '../../types/app';

// REDUX AND SLICES
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { login, fetchMerchantData } from '../../reducers/auth-slice';
import {
  setCustomerDetails,
  fetchExistingData,
  customerSearch,
  saveCustomerDetails,
  setCustomerId,
  setErrors,
  setBsoSubmitted,
  validateBsoReceived,
} from '../../reducers/customer-slice';
import {
  updateCheckout,
  fetchCheckoutAttributes,
  attachCustomer,
  runPlanPreApprovalCheck,
  acceptDepositBumping,
  updateBsoOptions,
  sendVerificationCode,
  checkoutCancellation,
} from '../../reducers/checkout-slice';

// WEB COMPONENTS
import {
  Alert,
  Button,
  IconAttention,
  IconNext,
  ModalLargerDepositRequired,
  PaymentCalculator,
  PaymentCalculatorData,
  ModalGeneric,
  ModalWrapper,
  fixNameCase,
} from '@payright/web-components';

// FORM COMPONENTS
import EditDetailsBlock from '../../components/customer-data/edit-details-block';
import CustomerDetails, {
  CustomerDetailsData,
} from '../../components/customer-data/customer-details';
import AddressDetails, {
  AddressDetailsData,
  ShippingDetailsData,
} from '../../components/customer-data/address-details';

import EmploymentDetails, {
  EmploymentDetailsData,
} from '../../components/customer-data/employment-details';
import IdentificationDetails, {
  IdentificationDetailsData,
  IdentificationType,
} from '../../components/customer-data/identification-details';
import FeeDetails from '../../components/fee-details';
import BankStatement from '../../components/bank-statement';

// OTHER COMPONENTS
import StepHeader from '../../components/step-header';
import SCSpinner from '../../components/spinner';
import Loader from '../../components/loader';

// LAYOUT COMPONENTS
import LargerHeaderWithNavLayout from '../../layouts/large-header-with-nav-layout';

// STYLES AND STYLED COMPONENTS
import SCEcommerceNewPlan from './css';

// EVENT HANDLERS
import { cancellationClickHandler } from '../../util/handlers';
import { CheckoutStep } from '../../types/checkout';

const {
  PAYMENT_FREQUENCIES,
  REDIRECT_URL,
  ADDRESS_DETAIL_TEXT_NEW_CUSTOMER,
  ADDRESS_DETAIL_TEXT_EXISTING_CUSTOMER,
  CUSTOMER_IDENTIFICATION,
  CUSTOMER_DETAILS_CUSTOMER_INSTRUCTIONS,
} = getConstants();

type FormData = {
  customerDetails: CustomerDetailsData;
  addressDetails: AddressDetailsData;
  shippingDetails: ShippingDetailsData;
  employmentDetails: EmploymentDetailsData;
  identificationDetails: IdentificationDetailsData;
  paymentDetails: PaymentCalculatorData;
  customerNumber: string;
};

type SectionNames = 'Your Details' | 'Address Details' | 'Employment Details' | 'Identification';

const detailsSectionName = 'Your Details';
const addressSectionName = 'Address Details';
const employmentSectionName = 'Employment, Benefits & Income';
const identificationSectionName = 'Identification';

const Step1 = () => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();

  // URL QUERY PARAMS
  const query = useQuery();
  const queryParamCheckoutId = query.get('checkoutId');

  // GLOBAL STATE
  const globalAuthState = useSelector((state: RootState) => state.auth);
  const globalCustomerState = useSelector((state: RootState) => state.customer);
  const globalCheckoutState = useSelector((state: RootState) => state.checkout);

  // VARIABLES
  const merchantData = globalAuthState.merchantRates;
  const customerId = globalAuthState.customerId || '';
  const applicationCompleteBy = globalCheckoutState.checkout?.applicationCompletedBy || '';

  const canCustomerProceed =
    globalCustomerState.existingCustomerState?.customerSearchResponse?.canCustomerProceed;
  const customerMatch = globalCustomerState.customerMatch || false;
  const hasCustomerErrors: boolean = globalCustomerState.hasErrors;
  const hasCheckoutErrors: boolean = globalCheckoutState.hasErrors;
  const customerSearchErrors =
    globalCustomerState.existingCustomerState?.customerSearchResponse?.errors;

  const email = globalCustomerState.customerDetails?.email;
  const password = globalCustomerState.customerDetails?.password || '';
  const idType = globalCustomerState.identificationDetails?.identificationType;
  const repeatCustomer = globalCustomerState?.accountDetails?.isReturnCustomer;
  const customerNumber = globalCustomerState.customerNumber || '';
  const paymentDetails = globalCheckoutState.paymentDetails;
  const checkout = globalCheckoutState.checkout;
  const checkoutId = globalCheckoutState.checkout?.id || '';
  const checkoutCustomerId = globalCheckoutState.checkout?.customerId || '';
  const preApprovalDetails = globalCheckoutState.checkout?.preApprovalDetails;
  const redirectUrl = globalCheckoutState.checkout?.attributes?.redirectUrl || REDIRECT_URL;

  const authLoading = globalAuthState.loading;
  const checkoutLoading = globalCheckoutState.loading;
  const customerLoading = globalCustomerState.loading;

  const isExistingCustomer = globalAuthState.isLoggedIn;
  const isCustomerLed = applicationCompleteBy === 'Customer';

  const isIdVerified = globalCustomerState.customerDetails?.isIdVerified || false;

  // LOCAL STATE
  const [displayDepositBumping, setDepositBumpingDisplay] = useState(false);
  const [isDepositBumpRequired, setIsDepositBumpRequired] = useState(false);
  const [identificationType, setIdentificationType] = useState<IdentificationType>('license');
  const [depositBumpingPaymentDetails, setDepositBumpingPaymentDetails] = useState<
    PaymentCalculatorData
  >();
  const [isCustomerFormReadonly, setIsCustomerFormReadonly] = useState(false);
  const [displayBankSatement, setDisplayBankStatement] = useState<boolean>(false);
  const bsoSubmitted = globalCustomerState.bsoSubmitted;

  const [acceptChangesLoading, setAcceptChangesLoading] = useState(false);

  // Todo: Refactor these states to use a single object instead
  const [preApprovalDepositAmount, setPreApprovalDepositAmount] = useState<number>(0);
  const [preApprovalDepositPercentage, setPreApprovalDepositPercentage] = useState<number>(0.0);
  const [isShippingAddressSame, setIsShippingAddressSame] = useState<boolean>(true);

  // REFS TO SCROLL TO
  const customerSearchRef = useRef<HTMLInputElement>(null);
  const hiddenBlockRef = useRef<HTMLInputElement>(null);

  const showAllAvailableTerms = globalCheckoutState.checkout?.attributes?.showAllAvailableTerms;
  const preApprovalStatus =
    globalCheckoutState.checkout?.attributes?.planPreApprovalDetails?.status;

  const config = useConfig();

  const totalSteps = useTotalSteps();

  useEffect(() => {
    if (
      typeof globalCustomerState.shippingDetails !== 'undefined' &&
      typeof globalCustomerState.addressDetails !== 'undefined' &&
      globalCustomerState.shippingDetails.street === globalCustomerState.addressDetails.street
    ) {
      setIsShippingAddressSame(true);
    } else if (
      typeof globalCustomerState.shippingDetails !== 'undefined' &&
      globalCustomerState.shippingDetails.street === ''
    ) {
      // Customer doesn't have shipping address
      setIsShippingAddressSame(true);
    } else {
      setIsShippingAddressSame(false);
    }
  }, [
    isCustomerLed,
    isExistingCustomer,
    globalCustomerState.employmentDetails,
    globalCustomerState.addressDetails,
    globalCustomerState.identificationDetails,
  ]);

  // SCROLL TO TOP ON PAGE LOAD
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Set requiredDepositBump on load, this is to prevent customers from updating employment if a deposit bump is needed
  useEffect(() => {
    setIsDepositBumpRequired(preApprovalStatus === 'NEED_TO_INCREASE_DEPOSIT');
  }, [preApprovalStatus]);

  // THE FORM IS BROKEN DOWN INTO 2 COMPONENTS
  // 1. The payment summary and customer block
  // 2. The customer details (address, employment, identification) block

  //////////  START OF THE PAYMENT AND CUSTOMER FORM BLOCK //////////
  const reactHookFormCustomerIdentification = useForm<FormData>({
    defaultValues: {
      paymentDetails: {
        ...globalCheckoutState.paymentDetails,
        paymentPeriod: globalAuthState.storeConfig?.defaultTerm,
      },
      customerDetails: { ...globalCustomerState.customerDetails },
    },
    shouldFocusError: false,
  });

  const {
    handleSubmit: handleSubmitCustomer,
    setValue: setValueCustomer,
    errors: errorsCustomer,
    watch,
  } = reactHookFormCustomerIdentification;

  const selectedPaymentFrequency = watch('paymentDetails').paymentFrequency;

  // CUSTOMER FORM HANDLER
  const formSubmitCustomerHandler = (formData: FormData) => {
    setIsCustomerFormReadonly(true);
    dispatch(
      updateCheckout({
        checkoutIdentifier: queryParamCheckoutId || '',
        paymentDetails: formData.paymentDetails,
        checkoutData: {
          type: checkout?.attributes?.type,
          redirectUrl: checkout?.attributes?.redirectUrl,
          expiresAt: checkout?.attributes?.expiresAt,
          step: CheckoutStep.CHECKOUT_STEP_IN_PROGRESS,
        },
      })
    )
      .then(() => {
        dispatch(
          setCustomerDetails({
            isExistingCustomer,
            ...formData,
          })
        );
        const saleAmount = formData.paymentDetails?.saleAmount || 0;
        const depositAmount = formData.paymentDetails?.depositAmount || 0;
        const loanAmount = saleAmount - depositAmount;
        dispatch(
          customerSearch(
            queryParamCheckoutId || '',
            formData.customerDetails,
            isExistingCustomer ? 'existingCustomer' : 'newCustomer',
            loanAmount
          )
        );
      })
      .catch(e => {
        setIsCustomerFormReadonly(false);
        throw e;
      });
  };

  // UPDATE FORM VALUES WHEN REDUX STATE CHANGES
  useEffect(() => {
    if (globalCheckoutState.paymentDetails) {
      setValueCustomer('paymentDetails', globalCheckoutState.paymentDetails);
    }
  }, [globalCheckoutState.paymentDetails, checkoutLoading, setValueCustomer]);

  useEffect(() => {
    if (globalCustomerState.customerDetails) {
      setValueCustomer('customerDetails', globalCustomerState.customerDetails);
    }
  }, [globalCustomerState.customerDetails, setValueCustomer]);
  //////////  END OF THE PAYMENT AND CUSTOMER FORM BLOCK //////////

  //////////  START OF THE CUSTOMER DETAILS FORM BLOCK //////////
  const reactHookFormDetails = useForm<FormData>({
    defaultValues: {
      addressDetails: { ...globalCustomerState.addressDetails },
      shippingDetails: { ...globalCustomerState.shippingDetails },
      employmentDetails: { ...globalCustomerState.employmentDetails },
      identificationDetails: { ...globalCustomerState.identificationDetails },
    },
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const { handleSubmit, setValue, errors, clearErrors, reset, getValues } = reactHookFormDetails;

  const ref = useRef(true);

  useEffect(() => {
    // TODO DatePicker using onChange causing isDirty = true. Temp fix below.
    // TODO Load once, if customer-details component loaded, but upon forms init, isDirty init is true
    // console.log('form', reactHookFormDetails.formState.dirtyFields); // TODO DatePicker using onChange causing isDirty = true

    // Only for new customer, as they have zero identification details initialised
    if (globalCustomerState.identificationDetails) {
      // Proceed with initialising temporary hotfix
      const formsUsingDatePickerBug =
        globalCustomerState.identificationDetails.identificationType === 'license' ||
        globalCustomerState.identificationDetails.identificationType === 'passport';

      if (reactHookFormDetails.formState.isDirty && formsUsingDatePickerBug && ref.current) {
        reset(
          {
            ...getValues(),
          },
          { isDirty: false }
        );
        ref.current = false;
      } else {
        console.log('form updated', reactHookFormDetails.formState.isDirty); // DO NOT REMOVE!!!!
      }
    }
  }, [reactHookFormDetails.formState.isDirty]);

  const planPreApproval = () => {
    if (queryParamCheckoutId) {
      dispatch(
        runPlanPreApprovalCheck({
          checkoutId: queryParamCheckoutId,
          isCheckoutUpdated: reactHookFormDetails.formState.isDirty,
          isExistingCustomer: isExistingCustomer,
          onSuccessHandleRedirection: (params: {
            nextSteps: Array<NextStep>;
            paymentDetails?: PaymentCalculatorData;
            approvedDeposit: {
              percentage: any;
              amount: any;
            };
          }) => {
            if (params) {
              if (params.nextSteps[0] === NextStep.DEPOSIT_BUMP && paymentDetails !== undefined) {
                const depositAmount = params.approvedDeposit.amount;
                const depositPercentage = params.approvedDeposit.percentage;

                setDepositBumpingPaymentDetails({
                  ...paymentDetails,
                  depositPercent: depositPercentage,
                  depositAmount: depositAmount,
                });
                setPreApprovalDepositAmount(depositAmount);
                setPreApprovalDepositPercentage(depositPercentage);
              }

              handleNextSteps(params);
            }
          },
        })
      );
    }
  };

  // CUSTOMER DETAILS FORM HANDLER
  const formSubmitDetailsHandler = (formData: FormData) => {
    const fixedName = fixNameCase(
      globalCustomerState.customerDetails.firstName,
      globalCustomerState.customerDetails.middleName,
      globalCustomerState.customerDetails.lastName
    );

    if (isShippingAddressSame) {
      formData.shippingDetails = formData.addressDetails;
    }

    dispatch(
      setCustomerDetails({
        isExistingCustomer,
        ...formData,
      })
    );

    const customer: CustomerResource = {
      ...formData,
      id: globalAuthState.customerId || null,
      customerDetails: {
        ...(globalCustomerState.customerDetails || undefined),
        firstName: fixedName.firstName,
        middleName: fixedName.middleName,
        lastName: fixedName.lastName,
      },
    };

    // TODO: Error handling, and check if customer is already linked
    if (queryParamCheckoutId) {
      dispatch(saveCustomerDetails(customer, queryParamCheckoutId)).then(
        (customerSaveResult: any) => {
          dispatch(
            attachCustomer(
              queryParamCheckoutId,
              customerSaveResult.id as string,
              ApplicationCompletedBy.ECOMMERCE
            )
          )
            .then(async () => {
              //TODO - move `localStorage.setItem` to customer reducer
              localStorage.setItem('customerId', customerSaveResult.id);
              dispatch(setCustomerId({ customerId: customerSaveResult.id }));

              if (!isExistingCustomer) {
                // dispatch(setAuthCustomerId({ customerId: customerSaveResult.id }));
                await dispatch(login(email, password));
              }

              planPreApproval();
            })
            .catch(err => {
              // attachCustomer error - Main error displayed to user
              console.log(err);
            });
        }
      );
    }
  };

  // UPDATE FORM VALUES WHEN REDUX STATE CHANGES
  useEffect(() => {
    if (globalCustomerState.addressDetails) {
      Object.keys(globalCustomerState.addressDetails).forEach(key => {
        clearErrors(`addressDetails.${key}`);
        setValue(`addressDetails.${key}`, Reflect.get(globalCustomerState.addressDetails, key));
      });
    }

    if (globalCustomerState.shippingDetails) {
      Object.keys(globalCustomerState.shippingDetails).forEach(key => {
        clearErrors(`shippingDetails.${key}`);
        setValue(`shippingDetails.${key}`, Reflect.get(globalCustomerState.shippingDetails, key));
      });
    }

    if (idType !== null) {
      setIdentificationType(idType);
    }

    if (globalCustomerState.employmentDetails) {
      Object.keys(globalCustomerState.employmentDetails).forEach(key => {
        setValue(
          `employmentDetails.${key}`,
          Reflect.get(globalCustomerState.employmentDetails, key)
        );
      });
    }

    if (globalCustomerState.identificationDetails) {
      Object.keys(globalCustomerState.identificationDetails).forEach(key => {
        clearErrors(`identificationDetails.${key}`);

        setValue(
          `identificationDetails.${key}`,
          Reflect.get(globalCustomerState.identificationDetails, key)
        );
      });
    }
  }, [
    globalCustomerState.addressDetails,
    globalCustomerState.shippingDetails,
    globalCustomerState.employmentDetails,
    globalCustomerState.identificationDetails,
    idType,
    setValue,
    setIdentificationType,
    clearErrors,
  ]);
  ////////// END OF THE CUSTOMER DETAILS FORM BLOCK //////////

  ////////// START OF THE DEPOSIT BUMP FORM BLOCK //////////

  // HANDLE DEPOSIT BUMP CANCEL
  const handleCancelDepositBumping = () => {
    setDepositBumpingDisplay(false);
    setIsDepositBumpRequired(true);
  };

  // HANDLE DEPOSIT BUMP APPROVAL
  const handleDepositBumpingApproval = () => {
    setAcceptChangesLoading(true);
    if (queryParamCheckoutId) {
      dispatch(
        acceptDepositBumping({
          checkoutId,
          depositAmount: preApprovalDepositAmount,
          depositPercentage: preApprovalDepositPercentage,
          onSuccessHandleRedirection: (params: {
            nextSteps: Array<NextStep>;
            paymentDetails?: PaymentCalculatorData;
          }) => {
            if (params) {
              setDepositBumpingDisplay(false);
              setAcceptChangesLoading(false);
              handleNextSteps(params);
            }
          },
        })
      ).then(() => {
        setAcceptChangesLoading(false);
      });
    }
  };
  ////////// END OF THE DEPOSIT BUMP FORM BLOCK //////////

  ////////// START OF THE BSO FORM BLOCK //////////
  const reactHookFormBsoData = useForm<{ provideBso: string }>({
    defaultValues: {
      provideBso: '',
    },
  });
  const { handleSubmit: handleUpdateBsoPageOptions, watch: watchBsoForm } = reactHookFormBsoData;
  const provideBsoSelection = watchBsoForm('provideBso');

  /**
   * If the customer fills out the BSO iframe and submits it, the Customer bso_status
   * will be updated by the Sugar endpoint that handles the bankstatements.com.au webhook
   *
   * If the customer "agrees" to the bso request, but they haven't submitted the bso
   * iframe, then we "fail" our validateBsoOptionSeletion() so the customer can't progress
   */
  const formSubmitHandlerBsoOptions = async (formData: { provideBso: string }) => {
    if (checkoutId && customerId && displayBankSatement) {
      if (formData.provideBso === 'agree' || config.cccfaEnabled) {
        // Todo: Remove
        // allows backward compatability from multi-bank to
        // single-bank during cut over - remove after cut-over (5/12/21)
        if (!config.multibankEnabled) {
          const bsoReceived = await dispatch(validateBsoReceived(checkoutId, customerId));

          // 'bsoReceived' is true if the Sugar "bso_status" === "Received"
          if (!bsoReceived) {
            dispatch(
              setErrors({
                hasErrors: true,
                errorMessage: 'Please submit the Bank Statements Online form to continue.',
              })
            );
            return;
          }

          setDisplayBankStatement(false);
          handleNextSteps({ nextSteps: [NextStep.SUMMARY], paymentDetails });
          return;
        }

        // bsoSubmitted is true if the iframe postMessage "submit_all" was received
        if (bsoSubmitted) {
          // Only check if we've received BSO for CCCFA, just continue otherwise as
          // we'll confirm we've recieved it during approval
          if (config.cccfaEnabled) {
            const bsoReceived = await dispatch(validateBsoReceived(checkoutId, customerId));

            // 'bsoReceived' is true if the Sugar "bso_status" === "Received"
            if (!bsoReceived) {
              dispatch(checkoutCancellation(checkoutId));
              history.push('/payment-plan/plan-result' + history.location.search, {
                planResultType: PlanResultType.BSO_NOT_AVAILABLE,
              });
              return;
            }
          }

          setDisplayBankStatement(false);
          handleNextSteps({ nextSteps: [NextStep.SUMMARY], paymentDetails });
        } else {
          dispatch(
            setErrors({
              hasErrors: true,
              errorMessage:
                'Please submit the Bank Statements Online form to continue - add at least one Bank and click "Finish" to submit.',
            })
          );
        }
      } else {
        dispatch(
          updateBsoOptions({
            checkoutId,
            provideBso: formData.provideBso,
            onSuccessHandleRedirection: (params: {
              nextSteps: Array<NextStep>;
              paymentDetails?: PaymentCalculatorData;
            }) => {
              if (params) {
                handleNextSteps(params);
              }
            },
          })
        );
      }
    }
  };

  ////////// END OF THE BSO FORM BLOCK //////////

  // PAGE REDIRECTION
  const handleNextSteps = (params: {
    nextSteps: Array<NextStep>;
    paymentDetails?: PaymentCalculatorData;
  }) => {
    const firstStep = params.nextSteps[0];

    switch (firstStep) {
      case NextStep.SUMMARY:
        if (checkoutId !== '' && applicationCompleteBy === 'Ecommerce') {
          dispatch(sendVerificationCode(checkoutId));
        }

        if (checkout?.attributes && params.paymentDetails) {
          const { type, redirectUrl, expiresAt } = checkout.attributes;

          // TODO - can we move this back to the preapproval function??
          dispatch(
            updateCheckout({
              checkoutIdentifier: queryParamCheckoutId || '',
              // don't think we want to update this here???
              paymentDetails: params.paymentDetails,
              checkoutData: {
                type: type,
                redirectUrl: redirectUrl,
                expiresAt: expiresAt,
                step: CheckoutStep.CHECKOUT_STEP_PAYMENT,
              },
            })
          );
        }

        history.push('/payment-plan/summary' + history.location.search);
        break;
      case NextStep.DEPOSIT_BUMP:
        setDepositBumpingDisplay(true);
        break;
      case NextStep.BANK_STATEMENT:
        setDisplayBankStatement(true);
        break;
      case NextStep.RUN_PRE_APPROVAL:
        planPreApproval();
        break;
      case NextStep.CANCEL:
        // Clear all local storage upon plan cancellation
        localStorage.removeItem('authToken');
        localStorage.removeItem('customerId');

        const seperator = redirectUrl.indexOf('?') !== -1 ? '&' : '?';
        // Redirect customer back to where they came from
        window.location.replace(redirectUrl + seperator + `checkoutId=${checkoutId}&status=CANCEL`);
        break;
    }
  };

  // LOAD LATEST CHECKOUT FROM THE SERVER
  useEffect(() => {
    if (queryParamCheckoutId !== null && queryParamCheckoutId !== checkoutId) {
      dispatch(fetchMerchantData(queryParamCheckoutId)).then(() => {
        dispatch(fetchCheckoutAttributes(queryParamCheckoutId));
      });
    }
  }, [queryParamCheckoutId, checkoutId, dispatch]);

  // REDIRECT TO LANDING PAGE IF NO CHECKOUT ID IN URL (DOES NOT CHECK IF VALID ID)
  useEffect(() => {
    if (!queryParamCheckoutId) {
      history.push('/');
    }
  }, [history, queryParamCheckoutId]);

  // REDIRECT TO LANDING PAGE IF CHECKOUT EXPIRED
  useEffect(() => {
    if (checkout && checkout.attributes && checkout.attributes.expiresAt !== undefined) {
      if (checkout.attributes?.expiresAt < new Date().toISOString()) {
        history.push('/');
      }
    }
  }, [history, checkout]);

  // REDIRECT TO LANDING PAGE IF CUSTOMER LED AND CUSTOMER ID IS NOT EQUAL TO LOGGED IN CUSTOMER ID
  useEffect(() => {
    if (applicationCompleteBy === 'Customer' && customerId !== checkoutCustomerId) {
      history.push('/');
    }
  }, [history, applicationCompleteBy, customerId, checkoutCustomerId]);

  // REDIRECT TO PLAN RESULT IF DECLINED APPLICATION
  useEffect(() => {
    if (preApprovalDetails && preApprovalDetails.status === 'DECLINED_APPLICATION') {
      history.push('/payment-plan/plan-result' + history.location.search, {
        planResultType: PlanResultType.DECLINED,
      });
    }
  }, [preApprovalDetails, history]);

  // LOAD EXISTING DATA FROM REDUX FOR LOGGED IN USER
  useEffect(() => {
    if (
      globalAuthState.isLoggedIn === true &&
      globalAuthState.customerId !== null &&
      queryParamCheckoutId !== null
    ) {
      dispatch(fetchExistingData(queryParamCheckoutId, globalAuthState.customerId));
    }
  }, [dispatch, globalAuthState.isLoggedIn, globalAuthState.customerId, queryParamCheckoutId]);

  // DISPLAY AND SCROLL TO ALERT BOX IF ERRORS
  useEffect(() => {
    if (hasCustomerErrors && customerSearchRef.current) {
      customerSearchRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [hasCustomerErrors]);

  return (
    <LargerHeaderWithNavLayout>
      <SCEcommerceNewPlan>
        <ModalWrapper
          open={displayDepositBumping}
          handleClose={handleCancelDepositBumping}
          background="dark"
        >
          <ModalLargerDepositRequired
            paymentDetails={depositBumpingPaymentDetails}
            establishmentFees={merchantData.establishmentFees}
            otherFees={merchantData.otherFees}
            handleCancel={handleCancelDepositBumping}
            handleSubmit={handleDepositBumpingApproval}
            isLoading={acceptChangesLoading}
            iconImport={acceptChangesLoading ? <SCSpinner /> : undefined}
          />
        </ModalWrapper>

        <ModalWrapper
          open={displayBankSatement}
          // handleClose={() => {
          //   dispatch(setErrors({ hasErrors: false }));
          //   setDisplayBankStatement(false);
          // }}
          background="dark"
        >
          <FormProvider {...reactHookFormBsoData}>
            <form onSubmit={handleUpdateBsoPageOptions(formSubmitHandlerBsoOptions)}>
              <ModalGeneric
                title="Securely submit your bank statements"
                text={''}
                content={
                  // TODO - better fallback bso url
                  <BankStatement
                    url={
                      process.env.REACT_APP_BSO_URL ||
                      'https://www.bankstatements.com.au/iframe/start/PRAU'
                    }
                    customerNumber={customerNumber}
                    applicationCompleteBy={applicationCompleteBy}
                    loading={customerLoading}
                    onBsoSubmitted={(bsoSubmitted: boolean) => {
                      dispatch(setBsoSubmitted({ bsoSubmitted }));
                    }}
                    errors={{
                      hasErrors: hasCustomerErrors,
                      errorMessage: globalCustomerState.errorMessage,
                      setErrors: (hasErrors: boolean, errorMessage?: string) => {
                        dispatch(setErrors({ hasErrors, errorMessage }));
                      },
                    }}
                  />
                }
                primaryButton={
                  <Button
                    disabled={
                      (provideBsoSelection === '' && !config.cccfaEnabled) || checkoutLoading
                    }
                    icon={
                      provideBsoSelection !== 'application_cancel' && checkoutLoading ? (
                        <SCSpinner />
                      ) : (
                        undefined
                      )
                    }
                  >
                    Continue
                  </Button>
                }
                secondaryButton={
                  <Button
                    outline
                    colour="blue"
                    type="button"
                    handleClick={() => setDisplayBankStatement(false)}
                  >
                    Cancel
                  </Button>
                }
              ></ModalGeneric>
            </form>
          </FormProvider>
        </ModalWrapper>

        <div className="step-body">
          <StepHeader activeStep={1} totalSteps={totalSteps} title="" />

          <FormProvider {...reactHookFormCustomerIdentification}>
            <form onSubmit={handleSubmitCustomer(formSubmitCustomerHandler)}>
              {authLoading || checkoutLoading ? (
                <Loader />
              ) : (
                <Controller
                  name="paymentDetails"
                  rules={{
                    validate: paymentDetails =>
                      paymentDetails.saleAmount !== 0 &&
                      paymentDetails.depositPercent !== 'N/A' &&
                      paymentDetails.paymentTerm !== 'N/A',
                  }}
                  render={({ onChange, onBlur, value, name, ref }) => (
                    <PaymentCalculator
                      type="ecommerce"
                      readOnly={isCustomerFormReadonly}
                      rates={merchantData.rates}
                      paymentFrequencies={PAYMENT_FREQUENCIES}
                      establishmentFees={merchantData.establishmentFees}
                      otherFees={merchantData.otherFees}
                      updatePaymentDetails={onChange}
                      paymentDetails={value}
                      repeatCustomer={repeatCustomer}
                      shouldShowAllTerms={showAllAvailableTerms}
                    />
                  )}
                />
              )}
              <div className="fee-details">
                <FeeDetails
                  accountKeepingFee={merchantData.otherFees.monthlyAccountKeepingFee}
                  processingFee={merchantData.otherFees.paymentProcessingFee}
                  paymentFrequency={selectedPaymentFrequency}
                />
              </div>
              <hr id="end-payment" />

              <div ref={customerSearchRef} style={{ position: 'relative', top: '-2em' }} />

              <EditDetailsBlock
                title={detailsSectionName}
                showEditToggleButton={false}
                toggleEditing={() => {}}
                editing
                text={CUSTOMER_DETAILS_CUSTOMER_INSTRUCTIONS}
              >
                {/**
                 * Customer details (Title, First Name, Middle Name, Last Name, Email, and DOB)
                 * can be edited by customers whose IDs have not yet been verified.
                 *
                 * If the customer is not in SugarCRM yet (i.e not an existing customer) ,
                 * the user will be prompted to enter their password as well.
                 */}
                <CustomerDetails existingCustomer={isExistingCustomer} readOnly={isIdVerified} />
              </EditDetailsBlock>
              <hr id="end-customer-details" />

              {!customerMatch && (
                <>
                  <div className="button-actiongroup-wrapper">
                    <Button
                      colour="blue"
                      maxWidth="100%"
                      iconPosition="right"
                      disabled={customerLoading || checkoutLoading}
                      icon={customerLoading || checkoutLoading ? <SCSpinner /> : undefined}
                    >
                      {canCustomerProceed ? 'Update' : 'Continue'}
                    </Button>
                  </div>

                  <Button
                    className="cancel-button"
                    type="button"
                    maxWidth="100%"
                    outline
                    colour="blue"
                    handleClick={() => {
                      // Initiate plan cancellation, upon button click
                      cancellationClickHandler(checkoutId, redirectUrl, dispatch);
                    }}
                  >
                    Cancel
                  </Button>
                </>
              )}

              {hasCustomerErrors && !hasCheckoutErrors && (
                <Alert
                  title={'There was an error with your submission'}
                  body={globalCustomerState.errorMessage || ''}
                  outcome="error"
                  icon={<IconAttention />}
                />
              )}

              {hasCheckoutErrors && (
                <Alert
                  title="There was an error"
                  body={globalCheckoutState.errorMessage || ''}
                  outcome="error"
                  icon={<IconAttention />}
                />
              )}

              {/* Existing customer search errors - when errors is an array */}
              {Array.isArray(customerSearchErrors) &&
                customerSearchErrors.map(error => {
                  return (
                    <Alert
                      key={error.code}
                      title={'There was an error with your submission'}
                      body={error.message}
                      outcome="error"
                      icon={<IconAttention />}
                    />
                  );
                })}

              {/*
                Existing customer search errors - when errors is an object
                Refer to case where 'Allow Further Business' is set to No on SugarCRM
              */}
              {!Array.isArray(customerSearchErrors) && customerSearchErrors && (
                <Alert
                  title={'There was an error with your submission'}
                  body={
                    'Thank you for submitting this application. Unfortunately we are unable to offer you a further purchase at this time.'
                  }
                  outcome="error"
                  icon={<IconAttention />}
                />
              )}
            </form>
          </FormProvider>

          <FormProvider {...reactHookFormDetails}>
            <div ref={hiddenBlockRef} style={{ position: 'relative', top: '-2em' }} />
            <form onSubmit={handleSubmit(formSubmitDetailsHandler)}>
              {customerMatch && (
                <>
                  <EditDetailsBlock
                    title={addressSectionName}
                    showEditToggleButton={false}
                    toggleEditing={() => {}}
                    editing
                    text={
                      isExistingCustomer
                        ? ADDRESS_DETAIL_TEXT_EXISTING_CUSTOMER
                        : ADDRESS_DETAIL_TEXT_NEW_CUSTOMER
                    }
                  >
                    <AddressDetails
                      existingCustomer={isExistingCustomer}
                      editing
                      formName="addressDetails"
                    />
                  </EditDetailsBlock>
                  <hr id="end-address-details" />

                  <EditDetailsBlock
                    title={employmentSectionName}
                    showEditToggleButton={false}
                    toggleEditing={() => {}}
                    editing
                  >
                    <EmploymentDetails
                      existingCustomer={isExistingCustomer}
                      editing
                      allowIncomeEditing={!isDepositBumpRequired}
                    />
                  </EditDetailsBlock>
                  <hr id="end-employment" />

                  <EditDetailsBlock
                    title={identificationSectionName}
                    showEditToggleButton={false}
                    toggleEditing={() => {}}
                    editing
                    text={CUSTOMER_IDENTIFICATION}
                  >
                    <IdentificationDetails
                      handleIdentificationType={(type: IdentificationType) => {
                        clearErrors('identificationDetails');
                        setIdentificationType(type);
                      }}
                      identificationType={identificationType}
                      existingCustomer={isExistingCustomer}
                      editing
                    />
                  </EditDetailsBlock>
                  <hr id="end-identification" />

                  <Button
                    withShadow
                    className="next-button"
                    maxWidth="100%"
                    iconPosition="right"
                    disabled={customerLoading || checkoutLoading}
                    icon={customerLoading || checkoutLoading ? <SCSpinner /> : <IconNext />}
                  >
                    Next Step
                  </Button>
                  <Button
                    className="cancel-button-secondary"
                    maxWidth="100%"
                    type="button"
                    outline
                    colour="blue"
                    handleClick={() => {
                      // Initiate plan cancellation, upon button click
                      cancellationClickHandler(checkoutId, redirectUrl, dispatch);
                    }}
                  >
                    Cancel
                  </Button>
                  <hr />
                </>
              )}
            </form>
          </FormProvider>
        </div>
      </SCEcommerceNewPlan>
    </LargerHeaderWithNavLayout>
  );
};

export default Step1;
