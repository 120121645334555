import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import LogRocket from 'logrocket';
import { AppThunk } from '../util/store';
import { CustomerDetailsData } from '../components/customer-data/customer-details';
import { AddressDetailsData } from '../components/customer-data/address-details';
import { ShippingDetailsData } from '../components/customer-data/address-details';
import { EmploymentDetailsData } from '../components/customer-data/employment-details';
import { IdentificationDetailsData } from '../components/customer-data/identification-details';
import ByronBayApi, {
  CustomerSearchResponse,
  CustomerSearchParams,
  UpdateCustomerData,
  CustomerIncomeExpenseData,
  CustomerLivingExpeseIncomeData,
  CustomerCreditAccountsData,
  CustomerDeclaredIncomeExpenseData,
} from '../util/byronbay-api';
import {
  CustomerResource,
  GetCustomerResource,
  AccountDetailsData,
  BsoStatus,
} from 'types/customer';

type InitialState = {
  loading: boolean;
  hasErrors: boolean;
  errorMessage?: string | null;
  bsoSubmitted: boolean;
  /** The Sugar entity id for customers. Null for new customers. */
  customerId: string | null;
  customerNumber: string | null;
  customerDetails: CustomerDetailsData;
  addressDetails: AddressDetailsData;
  shippingDetails: ShippingDetailsData;
  employmentDetails: EmploymentDetailsData;
  identificationDetails: IdentificationDetailsData;
  existingCustomerState?: {
    customerSearchResponse: CustomerSearchResponse | null;
  };
  customerMatch: boolean;
  accountDetails: AccountDetailsData;
  livingExpenses?: CustomerLivingExpeseIncomeData;
  creditAccounts?: CustomerCreditAccountsData;
};

type FormData = {
  customerDetails: CustomerDetailsData;
  addressDetails: AddressDetailsData;
  shippingDetails: ShippingDetailsData;
  employmentDetails: EmploymentDetailsData;
  identificationDetails: IdentificationDetailsData;
  customerNumber: string;
};

export const initialState: InitialState = {
  loading: false,
  hasErrors: false,
  errorMessage: null,
  bsoSubmitted: false,
  customerId: null,
  customerNumber: null,
  customerDetails: {
    firstName: '',
    lastName: '',
    middleName: '',
    title: '',
    email: '',
    phone: '',
    confirmEmail: '',
    dateOfBirth: '',
    password: '',
    confirmPassword: '',
    consentPromoMaterial: false,
    bsoStatus: BsoStatus.UNDEFINED,
    isIdVerified: false,
  },
  employmentDetails: {
    employmentType: 'Full time',
    employerPhone: '',
    employerName: '',
    timeInJob: '',
    incomeFrequency: 'weekly',
    incomeAmount: null,
    otherIncomeFrequency: 'weekly',
    otherIncomeAmount: 0,
  },
  addressDetails: {
    street: '',
    suburb: '',
    state: '',
    postcode: '',
    residentialStatus: '',
    residentialTimeAtAddress: '',
    residentialTimeAtPreviousAddress: '',
  },
  shippingDetails: {
    street: '',
    suburb: '',
    state: '',
    postcode: '',
  },
  identificationDetails: {
    identificationType: 'license',
    licenseNo: '',
    licenseCardNo: '',
    licenseExpiry: '',
    licenseIssuingState: '',
    licenseVersionNumber: '',
  },
  existingCustomerState: {
    customerSearchResponse: null,
  },
  customerMatch: false,
  accountDetails: {
    paymentFrequency: 'Fortnightly',
    accountKeepingFee: 1.62,
    paymentProcessingFee: 2.95,
    paymentStartDate: '',
    isReturnCustomer: false,
    nextRepaymentDate: '',
  },
  livingExpenses: {
    id: '',
    originalTotalMonthlyIncome: 0,
    appliedLivingExpensePercentage: 50,
    appliedFinancialExpensePercentage: 50,
    groceriesMonthly: 0,
    utility: 0,
    accommodation: 0,
    transportMonthlyCost: 0,
    personalExpense: 0,
    leisureEntertainmentMonthly: 0,
    education: 0,
    otherExpense: 0,
    otherIncome: 0,
    noIfJointAccount: 0,
    wages: [],
    creditCardRepayment: 0,
    totalCreditLimit: 0,
  },
  creditAccounts: [],
};

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    resetCustomer: () => initialState,
    setCustomerDetails(state, action: PayloadAction<{ isExistingCustomer: boolean } & FormData>) {
      return { ...state, ...action.payload };
    },
    setAddressDetails(state, action: PayloadAction<AddressDetailsData>) {
      return { ...state, addressDetails: action.payload };
    },
    setShippingDetails(state, action: PayloadAction<ShippingDetailsData>) {
      return { ...state, shippingDetails: action.payload };
    },
    setEmploymentDetails(state, action: PayloadAction<EmploymentDetailsData>) {
      return { ...state, employmentDetails: action.payload };
    },
    setIdentificationDetails(state, action: PayloadAction<IdentificationDetailsData>) {
      return { ...state, identificationDetails: action.payload };
    },
    setCustomerId: (state, action: PayloadAction<{ customerId: string }>) => {
      return { ...state, customerId: action.payload.customerId };
    },
    setErrors: (state, action: PayloadAction<{ hasErrors: boolean; errorMessage?: string }>) => {
      return {
        ...state,
        hasErrors: action.payload.hasErrors,
        errorMessage: action.payload.errorMessage || null,
      };
    },
    setBsoSubmitted: (state, action: PayloadAction<{ bsoSubmitted: boolean }>) => {
      return {
        ...state,
        bsoSubmitted: action.payload.bsoSubmitted,
        hasErrors: false,
        errorMessage: null,
      };
    },
    fetchExistingDataBegin(state) {
      return { ...state, loading: true };
    },
    fetchExistingDataSuccess(state, action: PayloadAction<any>) {
      return { ...state, loading: false, hasErrors: false, errorMessage: null, ...action.payload };
    },
    fetchExistingDataFailed(state) {
      return { ...state, loading: false, hasErrors: true, errorMessage: 'An error occurred' };
    },
    getCustomerByCheckoutIdBegin: state => {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        hasErrors: false,
      };
    },
    getCustomerByCheckoutIdSuccess: (state, action: PayloadAction<CustomerResource>) => {
      return {
        ...state,
        loading: false,
        customerId: action.payload.id || null,
        customerDetails: action.payload.customerDetails || initialState.customerDetails,
        customerAddressDetails: action.payload.addressDetails || initialState.addressDetails,
        customerShippingDetails: action.payload.shippingDetails || initialState.shippingDetails,
        identificationDetails:
          action.payload.identificationDetails || initialState.identificationDetails,
        employmentDetails: action.payload.employmentDetails || initialState.employmentDetails,
        customerMatch: true,
      };
    },
    getCustomerByCheckoutIdFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    customerSearchBegin: state => {
      return {
        ...state,
        loading: true,
      };
    },
    customerSearchSuccess: (
      state,
      action: PayloadAction<{
        customerId: string | null;
        errorMessage: string | null;
        customerMatch: boolean;
        customerDetails: CustomerDetailsData;
      }>
    ) => {
      const { customerId, errorMessage, customerMatch, customerDetails } = action.payload;

      return {
        ...state,
        loading: false,
        hasErrors: errorMessage ? true : false,
        customerId: customerId,
        errorMessage: errorMessage,
        customerMatch: customerMatch,
        customerDetails: customerDetails,
      };
    },
    customerSearchSuccessExisting: (state, action: PayloadAction<CustomerSearchResponse>) => {
      const { canCustomerProceed } = action.payload;

      return {
        ...state,
        loading: false,
        hasErrors: false,
        customerMatch: canCustomerProceed ? true : false,
        errorMessage: null,
        existingCustomerState: {
          ...state.existingCustomerState,
          customerSearchResponse: action.payload,
          verification: {
            requestId: null,
            confirmed: false,
          },
        },
      };
    },
    customerSearchFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    updateCustomerBegin: state => {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        hasErrors: false,
      };
    },
    updateCustomerSuccess: state => {
      return {
        ...state,
        loading: false,
        hasErrors: false,
      };
    },
    updateCustomerFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    validateBsoReceivedBegin: state => {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        hasErrors: false,
      };
    },
    validateBsoReceivedSuccess: state => {
      return {
        ...state,
        loading: false,
        hasErrors: false,
      };
    },
    validateBsoReceivedFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
    fetchCustomerIncomeExpenseBegin: state => {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        hasErrors: false,
      };
    },
    fetchCustomerIncomeExpenseFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
        hasErrors: true,
      };
    },
    fetchCustomerIncomeExpenseSuccess: (
      state,
      action: PayloadAction<CustomerIncomeExpenseData>
    ) => {
      const { approval_data, credit_accounts } = action.payload;

      const newState = {
        ...state,
        loading: false,
        errorMessage: null,
        hasErrors: false,
      };

      if (approval_data !== undefined) {
        newState.livingExpenses = {
          ...newState.livingExpenses,
          ...approval_data,
        };
      }

      if (credit_accounts !== undefined) {
        newState.creditAccounts = credit_accounts;
      }

      return newState;
    },

    updateDeclaredCustomerIncomeExpenseBegin: state => {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        hasErrors: false,
      };
    },

    updateDeclaredCustomerIncomeExpenseSuccess: state => {
      return {
        ...state,
        loading: false,
        hasErrors: false,
      };
    },

    updateDeclaredCustomerIncomeExpenseFailure: (
      state,
      action: PayloadAction<{ errorMessage: string }>
    ) => {
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload.errorMessage,
      };
    },
  },
});

export const {
  resetCustomer,
  setCustomerDetails,
  setAddressDetails,
  setEmploymentDetails,
  setIdentificationDetails,
  setShippingDetails,
  setCustomerId,
  setErrors,
  setBsoSubmitted,
} = customerSlice.actions;

const {
  fetchExistingDataBegin,
  fetchExistingDataFailed,
  fetchExistingDataSuccess,
  getCustomerByCheckoutIdBegin,
  getCustomerByCheckoutIdFailure,
  getCustomerByCheckoutIdSuccess,
  customerSearchBegin,
  customerSearchSuccess,
  customerSearchSuccessExisting,
  customerSearchFailure,
  updateCustomerBegin,
  updateCustomerSuccess,
  updateCustomerFailure,
  validateBsoReceivedBegin,
  validateBsoReceivedSuccess,
  validateBsoReceivedFailure,
  fetchCustomerIncomeExpenseBegin,
  fetchCustomerIncomeExpenseSuccess,
  fetchCustomerIncomeExpenseFailure,
  updateDeclaredCustomerIncomeExpenseBegin,
  updateDeclaredCustomerIncomeExpenseSuccess,
  updateDeclaredCustomerIncomeExpenseFailure,
} = customerSlice.actions;

// -------------------------
// - Thunks - FETCH EXISTING DATA
// -------------------------
export const fetchExistingData = (
  checkoutId: string,
  customerId: string
): AppThunk => async dispatch => {
  const byronBayApi = new ByronBayApi();

  dispatch(fetchExistingDataBegin());

  try {
    if (process.env.REACT_APP_DEMO_LOGIN === 'true') {
      let employmentType = 'Full time' as const;
      let identificationType = 'license' as const;

      const response = {
        customerDetails: {
          title: 'Mr',
          firstName: 'John',
          lastName: 'Smith',
          middleName: '',
          email: 'johnsmith@email.com',
          confirmEmail: 'johnsmith@email.com',
          phone: '0403 826 384',
          dateOfBirth: '1980-01-01',
        },
        addressDetails: {
          street: '12 Main Street',
          suburb: 'Richmond',
          state: 'VIC',
          postcode: '3021',
          residentialStatus: 'Own with Mortgage',
          residentialTimeAtAddress: '6 - 12 months',
        },
        shippingDetails: {
          street: '12 Main Street',
          suburb: 'Richmond',
          state: 'VIC',
          postcode: '3021',
        },
        employmentDetails: {
          employmentType,
          employerName: 'ANZ Bank',
          employerPhone: '03 9876 6543',
          timeInJob: '5+ Years',
          incomeFrequency: 'Weekly',
          incomeAmount: 1231,
          otherIncomeAmount: 0,
          otherIncomeFrequency: 'Weekly',
        },
        identificationDetails: {
          identificationType,
          licenseNo: '234234',
          licenseIssuingState: 'VIC',
          licenseExpiry: '2015-01-01',
        },
        customerNumber: 'C008924',
      };
      dispatch(fetchExistingDataSuccess(response));
    }
    const response: GetCustomerResource = await byronBayApi.getCustomerById(checkoutId, customerId);

    const formData: GetCustomerResource = {
      ...response,
    };

    dispatch(fetchExistingDataSuccess(formData));
  } catch (e) {
    dispatch(fetchExistingDataFailed());
  }
};

// -------------------------
// - Thunks - HANDLING THE CUSTOMER SEARCH
// -------------------------
export const customerSearch = (
  checkoutId: string,
  customerDetails: CustomerDetailsData | CustomerSearchParams,
  customerTypeSelection: 'existingCustomer' | 'newCustomer',
  loanAmount: number
): AppThunk => async dispatch => {
  const byronBayApi = new ByronBayApi();
  try {
    dispatch(customerSearchBegin());

    const response: CustomerSearchResponse = await byronBayApi.customerSearch(
      checkoutId,
      customerDetails,
      loanAmount
    );
    const { isExistingCustomer, canCustomerProceed, customerPartial } = response;

    let customerMatch = false;
    let errors = response.errors.length > 0 ? response.errors[0].message : null;

    if (customerTypeSelection === 'newCustomer') {
      // Is not an existing customer, and has passed fraud checks, return true
      customerMatch = !isExistingCustomer && canCustomerProceed;

      // If existing customer, append meaningful error message prepend to original errors received.
      if (isExistingCustomer === true) {
        // Override error message with existing customer error text, if there are no errors found.
        errors = 'The details entered belongs to an existing customer. Please login to continue.';
        customerMatch = false;
      }

      dispatch(
        customerSearchSuccess({
          customerId: customerPartial?.id ? customerPartial.id : null,
          errorMessage: errors,
          customerMatch,
          customerDetails: customerDetails as CustomerDetailsData,
        })
      );
    }

    if (customerTypeSelection === 'existingCustomer') {
      customerMatch = isExistingCustomer && canCustomerProceed;

      // If any errors are found, deny further progress for existing customer
      if (errors?.length) {
        dispatch(
          customerSearchFailure({
            errorMessage: errors,
          })
        );
      } else {
        // Else continue to process existing customer
        dispatch(customerSearchSuccessExisting(response));
      }
    }
  } catch (err) {
    dispatch(
      customerSearchFailure({
        errorMessage: 'An unexpected error occurred while trying to verify the customer details',
      })
    );
  }
};

// -------------------------
// - Thunks - ATTACH CUSTOMER TO CHECKOUT
// -------------------------
export const saveCustomerDetails = (
  customer: CustomerResource,
  checkoutId: string
): AppThunk<Promise<CustomerResource>> => async (dispatch: any) => {
  dispatch(updateCustomerBegin());

  const byronBayApi = new ByronBayApi();
  let result = null;

  const saveCustomerObj = {
    ...customer,
    // checkoutId is required so we can retrieve the payment frequency. This however should be refactored send the value straight from redux
    // checkoutId is also required because the backend uses this to retrieve the sale amount to calculate BSO
    checkoutId: checkoutId,
  };

  try {
    if (customer.id === null || typeof customer.id === 'undefined') {
      // Create
      // ('insert');
      result = await byronBayApi.customerCreation(checkoutId, saveCustomerObj);
    } else {
      result = await byronBayApi.updateCustomer(checkoutId, customer.id, saveCustomerObj);
    }
    dispatch(updateCustomerSuccess());
    return result;
  } catch (err) {
    dispatch(
      updateCustomerFailure({
        errorMessage:
          'An unexpected error has occurred while attempting to save the customer details',
      })
    );
    throw err;
  }
};

// -------------------------
// - Thunks - GET CUSTOMER BY CHECKOUT ID
// -------------------------
export const getCustomerByCheckoutId = (checkoutId: string): AppThunk => async dispatch => {
  try {
    dispatch(getCustomerByCheckoutIdBegin());
    const byronBayApi = new ByronBayApi();
    const currentCheckout = await byronBayApi.getCheckoutCustomer(checkoutId);
    dispatch(getCustomerByCheckoutIdSuccess(currentCheckout));
  } catch (error) {
    dispatch(getCustomerByCheckoutIdFailure(error.message));
  }
};

// -------------------------
// - Thunks - UPDATE CUSTOMER PAYMENT DATE
// -------------------------
export const doUpdatePaymentDate = (
  checkoutId: string,
  customerId: string,
  formData: UpdateCustomerData
): AppThunk => async dispatch => {
  const byronBayApi = new ByronBayApi();

  try {
    await byronBayApi.updateCustomerPaymentDetails(checkoutId, customerId, formData);
    dispatch(updateCustomerSuccess());
  } catch (err) {
    const errorMessage =
      typeof err.response !== 'undefined' && typeof err.response.data !== 'undefined'
        ? err.response.data.message
        : 'Something went wrong when paying the deposit';
    dispatch(
      updateCustomerFailure({
        errorMessage: errorMessage,
      })
    );
  }
};

export const updateCustomerPromoConsent = (
  checkoutId: string,
  customerId: string,
  consentPromoMaterial: CustomerResource
): AppThunk => async dispatch => {
  try {
    dispatch(updateCustomerBegin());
    const byronBayApi = new ByronBayApi();
    await byronBayApi.updateCustomer(checkoutId, customerId, consentPromoMaterial);
    dispatch(updateCustomerSuccess());
  } catch (error) {
    dispatch(
      updateCustomerFailure({
        errorMessage: 'Failed to update marketing concent',
      })
    );
    throw error;
  }
};

/**
 * If the customer fills out the BSO iframe and submits it, the Customer bso_status
 * Should be updated by the Sugar code that handles the BSO processed webhook
 *
 * If the customer "agrees" to the bso request, but they haven't submitted the bso
 * iframe, then we "fail" our validateBsoReceived() so the customer can't progress
 */
export const validateBsoReceived = (
  checkoutId: string,
  customerId: string
): AppThunk<Promise<boolean>> => async dispatch => {
  try {
    dispatch(validateBsoReceivedBegin());

    const byronBayApi = new ByronBayApi();
    const getCustomer = () => byronBayApi.getCustomerById(checkoutId, customerId);

    // Returns the remaining attempts count
    const multiRetryValidateBsoReceived = (
      getCustomer: (remainingAttempts?: number) => Promise<GetCustomerResource>,
      remainingAttempts = 30,
      delay = 10000
    ): Promise<number> => {
      return new Promise<number>((resolve, reject) => {
        getCustomer(remainingAttempts)
          .then(response => {
            const {
              customerDetails: { bsoStatus },
            } = response;

            if (bsoStatus !== BsoStatus.RECEIVED) {
              throw new Error('BSO status was never updated by Bank Statements system');
            } else {
              // for logging
              resolve(remainingAttempts);
              return;
            }
          })
          .catch(error => {
            setTimeout(() => {
              if (remainingAttempts === 0) {
                reject(error);
                return;
              }

              multiRetryValidateBsoReceived(getCustomer, remainingAttempts - 1, delay).then(
                resolve,
                reject
              );
            }, delay);
          });
      });
    };

    const attempts = 30;

    return multiRetryValidateBsoReceived(getCustomer, attempts)
      .then(remainingAttempts => {
        LogRocket.track(`BSO received after ${attempts - remainingAttempts} attempts`);
        dispatch(validateBsoReceivedSuccess());
        return true;
      })
      .catch(() => {
        LogRocket.track(`BSO failed after ${attempts} attempts`);
        dispatch(
          validateBsoReceivedFailure({
            errorMessage: `We encountered an issue while receiving your Bank Statements`,
          })
        );
        return false;
      });
  } catch (error) {
    dispatch(
      validateBsoReceivedFailure({
        errorMessage: 'Failed to validate bso status',
      })
    );
    throw error;
  }
};

export const fetchCustomerIncomeExpense = (
  checkoutId: string,
  customerId: string
): AppThunk => async dispatch => {
  try {
    dispatch(fetchCustomerIncomeExpenseBegin());

    const byronBayApi = new ByronBayApi();
    const response: CustomerIncomeExpenseData = await byronBayApi.getCustomerIncomeExpense(
      checkoutId,
      customerId
    );

    dispatch(fetchCustomerIncomeExpenseSuccess(response));
  } catch (error) {
    dispatch(
      fetchCustomerIncomeExpenseFailure({
        errorMessage: 'Failed to fetch customer income and expense data',
      })
    );
    throw error;
  }
};

export const updateCustomerDeclaredIncomeExpenses = (
  checkoutId: string,
  customerId: string,
  customerDeclaredIncomeExpense: CustomerDeclaredIncomeExpenseData
): AppThunk<Promise<boolean>> => async dispatch => {
  try {
    dispatch(updateDeclaredCustomerIncomeExpenseBegin());
    const byronBayApi = new ByronBayApi();
    await byronBayApi.updateCustomerIncomeExpenses(
      checkoutId,
      customerId,
      customerDeclaredIncomeExpense
    );
    const approvalId = dispatch(updateDeclaredCustomerIncomeExpenseSuccess());
    if (approvalId) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    dispatch(
      updateDeclaredCustomerIncomeExpenseFailure({
        errorMessage: 'Failed to update customer income expense data',
      })
    );
    throw error;
  }
};

export default customerSlice.reducer;
