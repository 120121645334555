import styled from 'styled-components';
import { media } from '@payright/web-components';

const SCMonthlyLivingExpensesSection = styled.div`
  .form-row {
    display: flex;
    flex-direction: column;

    margin-bottom: 2.46em;

    .form-label {
      margin-bottom: 0.75em;
      font-weight: 600;
    }

    // Responsive styles
    ${media.min.medium} {
      flex-direction: row;
      align-items: center;
      justify-items: space-between;
      margin-bottom: 1em;

      .form-label {
        margin-bottom: 0;
      }

      .form-label,
      .form-input {
        width: 50%;
      }
    }
  }

  .slider-row {
    margin-top: 1.5em;

    .range-label p:last-child {
      margin-bottom: 0;
    }
  }

  .credit-accounts-section {
    margin: 2em 0;

    .no-financial-obligations {
      text-align: center;
      font-size: 1.4em;
    }

    .credit-accounts-table {
      width: 100%;
      margin-bottom: 1.5em;

      th {
        text-align: left;
        background-color: ${props => props.theme.colours.grey.light};
      }

      th,
      td {
        width: 50%;
        padding: 1em 0.5em 1em 0.5em;
        border: 1px solid gray;
        font-weight: 600;
        // font-weight: 600;
      }
    }

    .table-block {
      display: inline-block;
      padding-left: 0.5em;
      padding-top: 1em;
      padding-bottom: 1em;
      width: 100%;

      .table-col-data {
        padding: 1em 0.5em 1em 0.5em;
        border: 1px solid gray;
        font-weight: 600;
      }
    }
  }

  hr {
    border: 1px solid gray;
  }
  .check-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2em;
  }

  div.confirmation-text {
    font-size: 1em;
    color: ${props => props.theme.colours.grey};
  }
  .check-wrapper > div.confirmation-text {
    line-height: 2.5;
  }
`;

export default SCMonthlyLivingExpensesSection;
