import React from 'react';
import styled from 'styled-components';
import { Alert, IconInfo } from '@payright/web-components';

type ConfirmationMessageProps = {
  /** Deposit Taker flag - If true, the merchant collects the deposit manually without using Payright */
  depositTaker: boolean;
  depositAmount: number;
};

const SCMessage = styled.div`
  span {
    font-weight: bold;
  }
  background-color: ${props => props.theme.colours.secondary3};
  border-radius: 10px;
  padding: 1.5em 1em;
  text-align: center;
  font-size: 1em;
`;

const ConfirmationMessage = ({ depositTaker, depositAmount }: ConfirmationMessageProps) => {
  // TODO:

  // application completer Y
  // ** deposit-taker Y -- by confirming the deposit arrangements
  // ** deposit-taker N -- the plan deposit of $X.XX will be charged
  // application completer N
  // ** deposit taker Y -- by confirming the deposit arrangements + we have just sent a link
  // ** deposit-taker N -- we have just sent a link
  return (
    <>
      {depositTaker ? (
        <Alert
          title={`Pay Deposit To Merchant - PLEASE NOTE`}
          body={
            <>
              <p>
                By confirming the deposit arrangements you acknowledge and confirm your agreement to
                the Credit and Disclosure Statement and Terms & Conditions for your Payright loan
                that have been emailed to you.
              </p>
              <p>
                Payright will not activate this loan until it receives confirmation from the
                Merchant {depositAmount > 0 && `that the deposit of $${depositAmount} is paid`} and
                you have agreed to receive the goods or services from the Merchant in accordance
                with the Merchant’s contract terms.
              </p>
            </>
          }
          outcome="error"
          icon={<IconInfo />}
        />
      ) : depositAmount > 0 ? (
        <SCMessage>
          The plan deposit of <span>${depositAmount}</span> will be charged to your nominated card
          as soon as you click save
        </SCMessage>
      ) : (
        ''
      )}
    </>
  );
};

export default ConfirmationMessage;
