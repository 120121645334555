import { PaymentFrequency } from 'types/customer';
import { DateTime } from 'luxon';

export const calculateAccountKeepingFee = (
  monthlyAccountKeepingFee: number,
  paymentFrequency: PaymentFrequency
): number => {
  let accountKeepingFees;
  if (paymentFrequency === 'Weekly') {
    accountKeepingFees = (monthlyAccountKeepingFee * 12) / 52;
  } else if (paymentFrequency === 'Fortnightly') {
    accountKeepingFees = (monthlyAccountKeepingFee * 12) / 26;
  } else {
    accountKeepingFees = monthlyAccountKeepingFee;
  }
  return accountKeepingFees;
};

export const calculateAgeLuxon = (date: string) => {
  return DateTime.fromFormat(date, 'yyyy-MM-dd').diffNow('years').years < -18;
};

export const envIsTrue = (envVar: string): boolean => {
  return (process.env[envVar] && process.env[envVar] === 'true') as boolean;
};

// Return array of stings from comma separated string in .env file
export const getEnvArray = (envVar: string): Array<string> => {
  const string = process.env[envVar];
  if (!string) {
    return [];
  }
  const array = string.split(',');
  return array.map(str => str.trim());
};

export const clearNumberInputs = (input: string) => {
  return Number(input.toString().replace(',', ''));
};

/**
 *
 * @param date string (expected format: "MM/YY")
 * @returns boolean
 */
export const isCardExpired = (date: string) => {
  const today = new Date();
  const dateParsed = date.split('/');

  const expiryMonth = parseInt(dateParsed[0]) - 1;
  const expiryYear = parseInt('20' + dateParsed[1]);

  const expiryDate = new Date(expiryYear, expiryMonth, 1);

  return expiryDate <= today;
};
