import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Navigation } from '@payright/web-components';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers';
import getConstants from 'util/constants';

const { CUSTOMER_HUB_URL } = getConstants();

interface CenterContentLayoutProps {
  children?: React.ReactNode;
}

const SCCenterContentLayout = styled.div`
  .content {
    max-width: 800px;
    margin: 0 auto;

    padding: 0 20px;
  }
`;

const CenterContentLayout = ({ children }: CenterContentLayoutProps) => {
  const history = useHistory();

  const globalAuthState = useSelector((state: RootState) => state.auth);
  const globalCustomerState = useSelector((state: RootState) => state.customer);

  const isLoggedIn = globalAuthState.isLoggedIn;

  const userAccount = {
    firstName: globalCustomerState.customerDetails.firstName,
  };

  useEffect(() => {
    if (!globalAuthState) {
      history.push('/');
    }
  }, [globalAuthState, history]);

  return (
    <SCCenterContentLayout>
      <Navigation
        portalType="ecommerce"
        userAccount={isLoggedIn ? userAccount : false}
        accountUrl={CUSTOMER_HUB_URL}
        loginUrl={'/login' + history.location.search}
      />
      <div className="content">{children}</div>
    </SCCenterContentLayout>
  );
};

export default CenterContentLayout;
