import { ControlledInputField, ControlledRange } from 'components/form-fields';
import SCMonthlyLivingExpensesSection from './monthly-financial-expenses-css';
import getConstants from 'util/constants';
import { CustomerCreditAccountsData } from 'util/byronbay-api';
import { useFormContext } from 'react-hook-form';

const {
  MONTHLY_EXPENSE_MAX_VALIDATION,
  MONTHLY_TOTAL_REPAYMENT_VALIDATION,
  DECIMAL_NUMBERS_VALIDATION,
} = getConstants();

type MonthlyFinancialExpensesProps = {
  creditAccounts?: CustomerCreditAccountsData;
  sectionNo: number;
  hasJointAccounts: boolean;
};

const MonthlyFinancialExpenses = ({
  creditAccounts,
  sectionNo,
  hasJointAccounts,
}: MonthlyFinancialExpensesProps) => {
  const { errors, getValues } = useFormContext();

  return (
    <SCMonthlyLivingExpensesSection>
      <h1 className="form-section--header">{sectionNo}. Financial expenses</h1>
      <div className="form-block">
        <p>
          We have captured your financial obligations from your credit file and listed these below.
          We have also identified whether these obligations are 'single' (yours only) or 'joint'
          with someone else.
        </p>

        <div className="credit-accounts-section">
          {creditAccounts && creditAccounts?.length > 0 ? (
            <>
              <table className="credit-accounts-table">
                <tr>
                  <th>Credit Provider</th>
                  <th>Product</th>
                </tr>

                {creditAccounts.map((item, i) => (
                  <tr key={i}>
                    <td>{item.creditor}</td>
                    <td>{item.productType}</td>
                  </tr>
                ))}
              </table>
            </>
          ) : (
            <div className="no-financial-obligations">No financial obligations found</div>
          )}
        </div>

        <div className="form-row">
          <div className="form-label">
            <label htmlFor="credit-limit">Total credit card limit (all credit cards)</label>
          </div>
          <div className="form-input">
            <ControlledInputField
              name="livingExpenses.totalCreditLimit"
              type="text"
              prefix="$"
              id="credit-limit"
              readOnly={true}
              rules={{ maxLength: { value: 6, message: MONTHLY_EXPENSE_MAX_VALIDATION } }}
              error={errors.livingExpenses?.totalCreditLimit?.message}
            ></ControlledInputField>
          </div>
        </div>
        <div>
          <h5> Total financial expenses</h5>
          <br />
          <p>
            The bank statement information you have provided has identified your total monthly
            financial expenses.
          </p>
          <p>
            This amount should include all repayments associated with all non-credit card financial
            obligations in the table above. If this number is not correct, please adjust.
          </p>
        </div>

        <div className="form-row">
          <div className="form-label">
            <label htmlFor="loan-repayment">
              Total financial expenses (excluding credit cards)
            </label>
          </div>
          <div className="form-input">
            <ControlledInputField
              name="livingExpenses.creditCardRepayment"
              prefix="$"
              type="text"
              placeholder="0"
              id="loan-repayment"
              thousandSeparator={true}
              rules={{
                maxLength: { value: 6, message: MONTHLY_EXPENSE_MAX_VALIDATION },
                validate: () => {
                  if (
                    (parseFloat(getValues('livingExpenses.creditCardRepayment')) === 0 ||
                      getValues('livingExpenses.creditCardRepayment') === '') &&
                    creditAccounts &&
                    creditAccounts?.length > 0
                  ) {
                    return MONTHLY_TOTAL_REPAYMENT_VALIDATION;
                  }
                },
                pattern: {
                  value: /^[0-9,\b]+$/,
                  message: DECIMAL_NUMBERS_VALIDATION,
                },
              }}
              error={errors.livingExpenses?.creditCardRepayment?.message as string}
            ></ControlledInputField>
          </div>
        </div>

        {hasJointAccounts && (
          <div className="slider-row">
            <ControlledRange
              name="livingExpenses.appliedFinancialExpensePercentage"
              min={0}
              max={100}
              step={1}
            >
              <p>
                Because you have supplied a joint bank account we need to understand what % of these
                loan repayments <u>you</u> contribute to.
              </p>
              <p>What % of these financial expenses do you contribute to?</p>
            </ControlledRange>
          </div>
        )}

        <hr />
      </div>
    </SCMonthlyLivingExpensesSection>
  );
};

export default MonthlyFinancialExpenses;
