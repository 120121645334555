import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { HeaderEcommerce, Navigation, media } from '@payright/web-components';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers';
import getConstants, { HeaderType } from 'util/constants';
import { CheckoutType } from '../types/checkout';

const { CUSTOMER_HUB_URL, HEADER } = getConstants();

interface LargeHeaderWithNavProps {
  children: React.ReactNode;
}

const SCLargeHeaderWithNav = styled.div`
  background-color: ${props => props.theme.colours.white};

  .step-body {
    max-width: 800px;
    margin: 0 auto;
    ${media.max.large} {
      padding: 0 1.6em;
    }
  }

  padding-bottom: 10em;
`;

const LargeHeaderWithNav = ({ children }: LargeHeaderWithNavProps) => {
  const history = useHistory();
  const pathName = history.location.pathname;

  const globalAuthState = useSelector((state: RootState) => state.auth);
  const globalCheckoutState = useSelector((state: RootState) => state.checkout);
  const globalCustomerState = useSelector((state: RootState) => state.customer);
  const globalPaymentState = useSelector((state: RootState) => state.payment);
  const globalPaymentMethodsState = useSelector((state: RootState) => state.paymentMethods);

  const isLoggedIn = globalAuthState.isLoggedIn;
  const checkoutType = globalCheckoutState.checkout?.attributes?.type || CheckoutType.UNDEFINED;

  const loadingAuth = globalAuthState.loading;
  const loadingCheckout = globalCheckoutState.loading;
  const loadingPayment = globalPaymentState.loading;
  const loadingPaymentMethods = globalPaymentMethodsState.loading;

  const userAccount = {
    firstName: globalCustomerState.customerDetails.firstName,
  };

  useEffect(() => {
    if (!globalAuthState) {
      history.push('/');
    }
  }, [globalAuthState, history]);

  return (
    <SCLargeHeaderWithNav>
      <div data-testid="nav">
        <Navigation
          portalType="ecommerce"
          userAccount={isLoggedIn ? userAccount : false}
          accountUrl={CUSTOMER_HUB_URL}
          loginUrl={'/login' + history.location.search}
        />
      </div>

      {loadingAuth || loadingCheckout || loadingPaymentMethods || loadingPayment
        ? null
        : renderHeader(pathName, HEADER, checkoutType)}
      {children}
    </SCLargeHeaderWithNav>
  );
};

// Render the header based upon the path
const renderHeader = (pathName: string, HEADER: HeaderType, checkoutType: String) => {
  const { CUSTOMER_DETAILS, SUMMARY, INCOME_AND_EXPENSES, PAYMENT } = HEADER;

  switch (pathName) {
    case CUSTOMER_DETAILS.PAGE_NAME:
      return (
        <HeaderEcommerce title={CUSTOMER_DETAILS.TITLE} subtitle={CUSTOMER_DETAILS.SUBTITLE} />
      );

    case INCOME_AND_EXPENSES.PAGE_NAME:
      return (
        <HeaderEcommerce
          title={INCOME_AND_EXPENSES.TITLE}
          subtitle={INCOME_AND_EXPENSES.SUBTITLE}
        />
      );

    case SUMMARY.PAGE_NAME:
      if (
        (checkoutType === CheckoutType.APPLY_AND_PAY || CheckoutType.STANDARD) &&
        checkoutType !== CheckoutType.PRE_APPROVAL
      ) {
        return <HeaderEcommerce title={SUMMARY.TITLE} subtitle={SUMMARY.SUBTITLE_STANDARD} />;
      } else {
        return <HeaderEcommerce title={SUMMARY.TITLE} subtitle={SUMMARY.SUBTITLE_PRE_APPROVAL} />;
      }

    case PAYMENT.PAGE_NAME:
      if (
        (checkoutType === CheckoutType.APPLY_AND_PAY || CheckoutType.STANDARD) &&
        checkoutType !== CheckoutType.PRE_APPROVAL
      ) {
        return (
          <HeaderEcommerce title={PAYMENT.TITLE_STANDARD} subtitle={PAYMENT.SUBTITLE_STANDARD} />
        );
      } else {
        return (
          <HeaderEcommerce
            title={PAYMENT.TITLE_PRE_APPROVAL}
            subtitle={PAYMENT.SUBTITLE_PRE_APPROVAL}
          />
        );
      }

    default:
      return <HeaderEcommerce title="" subtitle="" />;
  }
};

export default LargeHeaderWithNav;
