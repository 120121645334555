import styled from 'styled-components';
import { Button } from '@payright/web-components';

import SCSpinner from '../../components/spinner';

type SaveButtonProps = {
  loading: boolean;
  depositTaker: boolean;
  depositAmount: number;
  mode: string;
  checkoutId: string;
  redirectUrl: string;
  checkoutType: string;
  checkoutStep: string;
  hasValidCardSelected?: boolean;
};

const SCSaveButton = styled.div`
  margin-top: 10px;
  .cancel-button-secondary {
    margin-top: 2em;
  }
`;

const SaveButton = ({
  loading,
  depositTaker,
  depositAmount,
  mode,
  checkoutId,
  redirectUrl,
  checkoutType,
  checkoutStep,
  hasValidCardSelected,
}: SaveButtonProps) => {
  const saveButtonText = () => {
    if (depositTaker && depositAmount > 0) return 'Save and confirm deposit payment to merchant';
    if (depositAmount === 0) return 'Save and continue';
    if (checkoutType === 'pre-approval' && checkoutStep !== 'pre-approval-payment') return 'Save';
    return 'Save details and pay deposit';
  };

  return (
    <SCSaveButton>
      <Button
        className="submit-button"
        size="big"
        disabled={loading || (mode === 'existing' && !hasValidCardSelected)}
        iconPosition="right"
        icon={loading ? <SCSpinner /> : undefined}
        type="submit"
        maxWidth={'100%'}
        colour="primary"
      >
        {saveButtonText()}
      </Button>
    </SCSaveButton>
  );
};

export default SaveButton;
