import styled from 'styled-components';

const SCIncomeAndExpenseVerification = styled.div`
  // background: green;
  /* box-shadow: 10px 10px 5px 0px rgba(212,212,212,0.10);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(212,212,212,0.10);
  -moz-box-shadow: 10px 10px 5px 0px rgba(212,212,212,0.10); */

  .loader-body {
    margin-top: 2em;
  }

  hr {
    border-style: solid;
    border-bottom: none;
    border-color: ${props => props.theme.colours.grey.altlight};
    margin: 2.23em 0 3em;
  }
  .cancel-button {
    margin-top: -1em;
    margin-bottom: 2em;
  }
  .cancel-button-secondary {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .next-button {
    margin-top: 0em;
  }
  .hidden {
    display: none;
  }
  .button-actiongroup-wrapper {
    padding: 30px 0px 30px 0px;
    display: flex;
    justify-content: space-between;
    margin-top: -2em;
  }
  .search-error {
    height: 1px;
    padding: 0;
    margin-top: 2em;
  }
  .anchorTop {
    height: 1px;
    padding-top: 2em;
    margin-top: -3em;
  }
  .anchor {
    height: 1px;
    padding: 0;
    margin-top: -2em;
  }

  .example-word-break {
    padding: 1em 0 1em 0;
    word-break: break-word;
  }

  .section-container {
    display: flex;
    flex-direction: column;

    .section-intro,
    .form-section {
      &--header {
        font-size: 1.13em;
        color: ${props => props.theme.colours.blue.base};
        padding: 0 0 0.5em 0;
        font-weight: bold;
        vertical-align: baseline;
      }

      &--subheader {
        padding: 0.5em 0 0 0;
      }

      p {
        margin-bottom: 1.5em;
      }
    }

    .section-content {
      .form-section {
        &--check-wrapper {
          margin: 2.5em 0;
          .checkbox-component {
            height: auto;
            line-height: 1.4;
          }
        }

        div.label-text {
          font-size: 1em;
          color: ${props => props.theme.colours.grey};
        }
        &--.check-wrapper > div.label-text {
          line-height: 2.5;
        }
        .errorMessage {
          text-align: left;
          margin: 0em 0.8em 1em 0.8em;
          color: red;
          font-size: 1.2em;
        }
      }

      hr {
        margin: 2.5em 0;
      }
    }
  }
`;

export default SCIncomeAndExpenseVerification;
