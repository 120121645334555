export enum NextStep {
  SUMMARY = 'summary',
  DEPOSIT_BUMP = 'deposit_bumping',
  BANK_STATEMENT = 'bank_statement',
  RUN_PRE_APPROVAL = 'run_pre_approval',
  DECLINED_MESSAGE = 'declined_message',
  CANCEL = 'cancel',
}

export enum CheckoutType {
  PRE_APPROVAL = 'pre-approval',
  STANDARD = 'standard',
  APPLY_AND_PAY = 'applyAndPay',
  UNDEFINED = '',
}

export enum CheckoutStep {
  CHECKOUT_STEP_PAYMENT = 'payment',
  CHECKOUT_STEP_PRE_APPROVAL_PAYMENT = 'pre-approval-payment',
  CHECKOUT_STEP_PENDING = 'pending',
  CHECKOUT_STEP_IN_PROGRESS = 'in_progress',
  CHECKOUT_STEP_PLAN_RESULT = 'plan_result',
  UNDEFINED = '',
}
