import React from 'react';
import { Button, IconLogo, IconNext, media } from '@payright/web-components';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import { RootState } from '../reducers';
import getConstants from '../util/constants';

const { FAQS_URL } = getConstants();

const SCWhatIsPayright = styled.div`
  .logo {
    display: none;
    width: 100%;
    max-width: 160px;
    margin-bottom: 2em;
  }

  h1 {
    color: ${props => props.theme.colours.blue.base}
    margin-bottom: 15px;

    ${media.max.wide} {
      font-size: 1.93em;
    }
  }

  p {
    margin-bottom: 2em;
    line-height: 2em;
  }

  ${media.min.medium} {
    .logo {
      display: block;
    }
  }
  .button {
    margin-bottom: 2em;
  }
`;

interface WhatIsPayrightProps {}

const WhatIsPayright = (props: WhatIsPayrightProps) => {
  const globalCheckoutState = useSelector((state: RootState) => state.checkout);
  const checkoutType = globalCheckoutState.checkout?.attributes?.type;

  return (
    <SCWhatIsPayright className="what-is-payright">
      <IconLogo height={43} className="logo" />
      <h1>
        {checkoutType === 'pre-approval'
          ? 'Get approved for your zero interest payment plan ahead of time.'
          : 'Zero interest payment plans right when you need it.'}
      </h1>
      <p>
        Our payment plans give you the ability to get more from your budget. That way, you really
        can have your cake and eat it too, in easy, bite-sized instalments.
      </p>
      <Button
        className="button"
        iconPosition="right"
        icon={<IconNext />}
        handleClick={e => {
          e.preventDefault();
          window.open(FAQS_URL, '_blank');
        }}
      >
        Find out more about Payright
      </Button>
    </SCWhatIsPayright>
  );
};

export default WhatIsPayright;
