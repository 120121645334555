import styled from 'styled-components';
import { media } from '@payright/web-components';

export const SCLivingExpensesSection = styled.div`
  strong {
    font-weight: 600;
  }

  .form-row {
    display: flex;
    flex-direction: column;

    margin-bottom: 2.46em;

    .form-label {
      margin-bottom: 0.75em;
      .label {
        display: flex;
        justify-content: left;
        align-items: center;
        flex-direction: row;
        /* padding-top: 1em; */
      }
    }

    .py-tooltip {
      display: inline-block;
      padding-left: 0.4em;
      vertical-align: text-top;

      svg {
        height: 15px;
        width: 15px;
        margin: 0.4em;
      }
    }

    // Responsive styles
    ${media.min.medium} {
      flex-direction: row;
      align-items: center;
      justify-items: space-between;
      margin-bottom: 1em;

      .form-label {
        margin-bottom: 0;
      }

      .form-label,
      .form-input {
        width: 50%;
      }
    }
  }

  .slider-row {
    margin-top: 1.5em;

    .range-label p:last-child {
      margin-bottom: 0;
    }
  }
`;
