import { ControlledInputField } from '../form-fields';
import { useFormContext } from 'react-hook-form';
import SCVerificationCode from './css';
import SummaryPageTextBlock from './summary-page-text-block';
import MarketingConsent from '../marketing-consent';
import { RootState } from '../../reducers';
import { useSelector } from 'react-redux';
import { useIsNz } from 'util/hooks';

const VerificationCode = ({
  isEcommerce,
  customerId,
}: {
  isEcommerce: boolean;
  customerId: string | null;
}) => {
  const { errors } = useFormContext();

  const globalFormState = useSelector((state: RootState) => state.customer);
  const idVerified = useSelector((state: RootState) => state.customer.customerDetails.isIdVerified);
  const isNz = useIsNz();

  return (
    <SCVerificationCode>
      <SummaryPageTextBlock isEcommerce={isEcommerce} />
      {isNz ? (
        <MarketingConsent
          customerId={customerId}
          checkPromoConsent={globalFormState.customerDetails.consentPromoMaterial}
        />
      ) : !idVerified ? (
        <MarketingConsent
          customerId={customerId}
          checkPromoConsent={false}
          isEcommerce={isEcommerce}
        />
      ) : (
        <MarketingConsent
          customerId={customerId}
          checkPromoConsent={globalFormState.customerDetails.consentPromoMaterial}
          isEcommerce={isEcommerce}
        />
      )}
      {isEcommerce ? (
        <>
          <p>
            You're seconds away from an instant decision – just enter your{' '}
            <span className="bold">Verification Code</span> below and hit Submit.
          </p>
        </>
      ) : (
        <p>You're seconds away from an instant decision – just hit Submit.</p>
      )}
      {isEcommerce && (
        <div className="section-action-loansubmit">
          <ControlledInputField
            id="verificationcode"
            name="verificationcode"
            rules={{ required: 'Required' }}
            error={errors.verificationcode && 'Verification Code is required'}
          >
            Verification Code
          </ControlledInputField>
        </div>
      )}
    </SCVerificationCode>
  );
};

export default VerificationCode;
